import * as React from 'react';
import { Button } from 'reakit';
import { Trans } from '@lingui/react';

import Modal from '@common/Modal';
import CloseIcon from '@common/Icons/CloseIcon';

import s from './InfoModal.module.css';

export default function InfoModal({ isOpen, onClose }) {
  return (
    <Modal
      className={s.Modal}
      backdropClassName={s.Backdrop}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={s.Header}>
        <div>
          <Trans id="Styles.Info.header" />
        </div>
        <Button onClick={onClose}>
          <CloseIcon />
        </Button>
      </div>

      <div className={s.Body}>
        <Trans id="Styles.Info.body" components={[<br />, <ul />, <li />]} />
      </div>
    </Modal>
  );
}
