import React from 'react';
import { Trans } from '@lingui/react';

import s from './DareModal.module.css';

export default function DareModal({ examples }) {
  return (
    <div className={s.DareModalContainer}>
      <Trans id="DareModal.intro" components={{ 0: <p />, 1: <strong /> }} />

      {examples?.map((eg, idx) => {
        let keys = Object.keys(eg.configuration);

        return (
          <div key={idx} className={s.ConfigurationContainer}>
            <div className={s.ExampleLabel}>
              <Trans id="ExampleModal.Example.label" /> {idx + 1}
            </div>

            <div className={s.GoalWrapper}>
              <div className={s.GoalLabel}>
                <Trans id="ExampleModal.Label.goal" />
              </div>

              <div className={s.Goal}>{eg.goal}</div>
            </div>

            {keys?.map(key => (
              <>
                <section key={key} className={s.ConfigSection}>
                  <div className={s.ConfigTitle}>{key}</div>

                  <div className={s.ConfigCard}>
                    {eg.configuration[key]?.map(config => (
                      <div key={config.name} className={s.ConfigCardItem}>
                        {config.name}
                      </div>
                    ))}
                  </div>
                </section>
              </>
            ))}
          </div>
        );
      })}
    </div>
  );
}
