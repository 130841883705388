import * as React from 'react';

function InfoIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={'1em'}
      height={'1em'}
      viewBox="0 0 50 50"
      {...props}
    >
      <defs>
        <filter
          id="info-icon"
          x={0}
          y={0}
          width={50}
          height={50}
          filterUnits="userSpaceOnUse"
        >
          <feOffset />
          <feGaussianBlur stdDeviation={3} result="blur" />
          <feFlood floodOpacity={0.161} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Group 974">
        <g filter="url(#info-icon)">
          <circle
            data-name="Ellipse 66"
            cx={16}
            cy={16}
            r={16}
            transform="translate(9 9)"
            fill="#d5d5d5"
          />
        </g>
        <g data-name="157933 (1)" fill="#fff">
          <path
            data-name="Path 266"
            d="M24.842 11.447a13.71 13.71 0 1013.71 13.711 13.726 13.726 0 00-13.71-13.711zm0 24.928A11.218 11.218 0 1136.06 25.158a11.23 11.23 0 01-11.218 11.217z"
          />
          <path
            data-name="Path 267"
            d="M24.843 17.264a1.662 1.662 0 101.662 1.663 1.664 1.664 0 00-1.662-1.663z"
          />
          <path
            data-name="Path 268"
            d="M24.842 23.08a1.246 1.246 0 00-1.246 1.246v7.478a1.246 1.246 0 102.493 0v-7.478a1.246 1.246 0 00-1.247-1.246z"
          />
        </g>
      </g>
    </svg>
  );
}

export default InfoIcon;
