import React from 'react';

function Home(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={23.358}
      height={26.544}
      viewBox="0 0 23.358 26.544"
      {...props}
    >
      <path
        data-name="Path 273"
        d="M22.816 10.471L11.988.546a.9.9 0 00-1.238.018L.524 10.489a.9.9 0 00-.274.648v14.255a.9.9 0 00.9.9h7.519a.9.9 0 00.9-.9v-6.616h4.211v6.617a.9.9 0 00.9.9h7.526a.9.9 0 00.9-.9V11.136a.9.9 0 00-.29-.665zM21.303 24.49h-5.714v-6.617a.9.9 0 00-.9-.9H8.671a.9.9 0 00-.9.9v6.617H2.055V11.518l9.341-9.067 9.907 9.082V24.49z"
        fill="currentColor"
        // fill="#b7b7b7"
        stroke="#fff"
        strokeWidth={0.5}
      />
    </svg>
  );
}

export default Home;
