import React from 'react';

import Modal from '@common/Modal';
import TrainingCompleteInfo from './TrainingCompleteInfo';
import TrainingCompleteFeedback from './TrainingCompleteFeedback';
import TrainingCompleteLastStep from './TrainingCompleteLastStep';

import s from './TrainingCompleteModal.module.css';

const completeSteps = ['COMPLETED_MESSAGE', 'FEEDBACK_FORM', 'LAST_STEP'];

export default function TrainingCompleteModal(props) {
  const { training, openModal, onToggleModal } = props;

  const [currentStep, setCurrentStep] = React.useState(0);

  function nextStepHandler() {
    setCurrentStep(step => step + 1);
  }

  function openCalendarHandler() {
    onToggleModal('trainingCompleteModal', 'close');
    onToggleModal('dateModal', 'open');
  }

  return (
    <Modal
      isOpen={openModal}
      className={s.TrainingCompleteModal}
      backdropClassName={s.TrainingCompleteModalBackdrop}
      hideOnClickOutside={false}
    >
      {completeSteps[currentStep] === 'COMPLETED_MESSAGE' && (
        <TrainingCompleteInfo
          trainingId={training?.id}
          trainingName={training?.name}
          onOpenCalendar={openCalendarHandler}
          onNextStep={nextStepHandler}
        />
      )}

      {completeSteps[currentStep] === 'FEEDBACK_FORM' && (
        <TrainingCompleteFeedback
          trainingId={training?.id}
          onNextStep={nextStepHandler}
        />
      )}

      {completeSteps[currentStep] === 'LAST_STEP' && (
        <TrainingCompleteLastStep
          clusterId={training?.cluster.id}
          skillId={training?.skill.id}
          onToggleModal={onToggleModal}
        />
      )}
    </Modal>
  );
}
