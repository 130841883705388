export default {
  it: [
    {
      section: 'posizionamento',
      body: [
        {
          question: 'Che cosa è il posizionamento?',
          answer:
            'Il Posizionamento su My Focus è una sezione che ti permette di autoposizionarti sui 4 cluster di Human Focus (Pensiero, Azione, Relazione e Persona) e sulle singole capacità che afferiscono a ogni cluster. È uno strumento che puoi utilizzare per monitorare i tuoi progressi sulle diverse capacità su cui deciderai di allenarti.'
        },
        {
          question: 'Come faccio a fare il posizionamento?',
          answer:
            'Nella sezione Posizionamento potrai fare due tipologie di azioni differenti. La prima è un primo auto-posizionamento sui 4 cluster di Human focus che potrai fare manualmente, tramite lo spostamento degli slider, o in modo guidato, rispondendo alle 4 domande del quiz proposto. Nella seconda invece potrai fare un auto-posizionamento più approfondito. Scegliendo un cluster e una specifica capacità ti verrà mostrato un set variabile di domande a cui rispondere per posizionarti.'
        },
        {
          question:
            'Perché non riesco più a muovere gli slider nella sezione Il tuo stile?',
          answer:
            "La sezione Il tuo stile si divide in 2 parti: Visione d'insieme e Approfondimento. La prima si compone di 4 slider, uno per ogni cluster di Human Focus, e di un quiz. Questi possono essere fatti e modificati fino a quando non si inizia il posizionamento di approfondimento. Quando inizi a posizionarti sulle singole capacità gli slider e il quiz si disabilitano."
        }
      ]
    },
    {
      section: 'profilo',
      body: [
        {
          question: 'Come faccio a cambiare l’immagine di profilo?',
          answer:
            'Per cambiare la tua immagine di profilo o caricarne una nuova vai nella sezione Profilo e clicca sull’immagine già presente o sull’avatar preimpostato. '
        },
        {
          question:
            'Come faccio a cambiare le informazioni che vedo nel mio profilo?',
          answer:
            'Se è cambiato qualcosa e/o vuoi semplicemente rifare il quiz di Profilazione devi andare nella sezione Profilo, scorrere la pagina e cliccare sul bottone Rifai la profilazione. In questo modo potrai nuovamente rispondere alle domande del quiz.'
        }
      ]
    },
    {
      section: 'feedback',
      body: [
        {
          question: 'Come faccio a richiedere un feedback?',
          answer:
            'Per poter richiedere un feedback dovrai andare nella sezione Allenamenti e selezionare uno tra gli allenamenti attivi. Dalla pagina di dettaglio dell’allenamento, clicca sul bottone "Richiedi feedback" e inserisci l\'email della persona di Eni a cui vuoi chiedere un feedback.'
        },
        {
          question: 'Come controllo le mie richieste di feedback?',
          answer:
            'Puoi controllare i tuoi feedback (richiesti e inviati) dalla sezione dedicata. Per raggiungerla puoi cliccare su Home  e poi sul box Feedback. Una volta sulla pagina, utilizza lo switch in alto per vedere i feedback che hai richiesto tu e quelli che ti sono stati richiesti da altri.'
        },
        {
          question: 'A chi posso chiedere un feedback?',
          answer:
            'Puoi decidere liberamente a chi chiedere un feedback, l’importante è che sia un collega Eni. Puoi chiedere un feedback al tuo responsabile, a un collega con cui lavori abitualmente oppure a uno con cui stai collaborando per un progetto temporaneo.'
        }
      ]
    },
    {
      section: 'allenamenti',
      body: [
        {
          question: 'Come faccio ad attivare un allenamento?',
          answer:
            'Per attivare un allenamento è sufficiente andare nella sezione Allenamenti, cliccare sul bottone in alto «+» e seguire il percorso guidato per la creazione dell’allenamento.'
        },
        {
          question: 'Come faccio a cambiare la data di fine allenamento?',
          answer:
            'Per cambiare la data di fine allenamento o qualsiasi altra impostazione/contenuto di un allenamento attivo clicca l’allenamento per aprire la pagina di dettaglio. Ecco cosa puoi fare: <ul><li>modificare le azioni inserite in precedenza</li><li>aggiungere nuove azioni</li> <li>modificare la data di fine allenamento</li><li>inviare una richiesta di feedback</li><li>eliminare l’intero allenamento</li></ul> '
        },
        {
          question: 'Come faccio a concludere un allenamento?',
          answer:
            "Dipende dalla tipologia di allenamento che hai scelto. </br></br>Hai selezionato il metodo D.A.R.E.? L'allenamento è completato quando hai indicato come fatte almeno una volta tutte le azioni che hai inserito. </br></br>Hai scelto il metodo Back Planning? </br>È completato quando indichi come fatte tutte le azioni inserite. </br></br>Hai scelto Lasciati ispirare, troverai un bottone Concludi allenamento direttamente nella pagina di dettaglio. Ricorda che in ogni caso, alla data di conclusione del tuo allenamento ti verrà inviata una notifica di reminder e potrai decidere se concludere il tuo allenamento o prolungarlo."
        }
      ]
    },
    {
      section: 'generale',
      body: [
        {
          question: 'Come faccio a cambiare lingua?',
          answer:
            'La web app è disponibile in 3 lingue, per poter cambiare la lingua di fruizione è sufficiente andare in homepage e cliccare sull’icona del mondo che si trova in alto a sinistra della schermata.'
        },
        {
          question:
            'A cosa servono le icone grigie i che sono inserite in alcune pagine della web app?',
          answer:
            'Le icone grigie i indicano la presenza di un approfondimento. Al click sull’icona infatti si aprirà un pop up in cui è possibile trovare una serie di informazioni aggiuntive e spiegazioni relative alla pagina in cui ci si trova. Questi approfondimenti potranno aiutarti e guidarti nel tuo percorso di allenamento.'
        }
      ]
    }
  ],
  en: [
    {
      section: 'positioning',
      body: [
        {
          question: 'What is the positioning?',
          answer:
            "Positioning on My Focus is a section that allows you to self-position yourself on the 4 Human Focus clusters (Thought, Action, Relationship and Person) and on the individual skills that refer to each cluster. It's a tool you can use to check your training on the different skills you decide to train on. "
        },
        {
          question: 'How do I position myself?',
          answer:
            'In the Positioning section you can do two different types of actions. The first is self-positioning on the 4 Human Focus clusters that you can do manually, by moving the sliders, or in a guided way, by answering the 4 questions of the proposed quiz. In the second, however, you can do a more specific self-positioning. By choosing a cluster and a specific competencies you will find a set of questions to answer in order to position yourself.'
        },
        {
          question:
            'Why am I no longer able to move the sliders in the Your Style section?',
          answer:
            'The section "Your Style" is divided in two parts: Overview and Insight. In the Overview you find 4 sliders, each one related to one of the 4 clusters of Human Focus, and a quiz. You can move the sliders until you start the Insight section. When you evaluate your skills, the slides  and quiz are disabled.'
        }
      ]
    },
    {
      section: 'profile',
      body: [
        {
          question: 'How do I change the profile pic?',
          answer:
            'You can change your profile pic or upload a new one by going on the Profile section and clicking on the image or avatar.'
        },
        {
          question: 'How can I change the information I see in my profile?',
          answer:
            'If something has changed and / or you just want to do again the Profiling quiz you have to go to the Profile section and click on the "Take the test again" button. This way you can answer the quiz questions.'
        }
      ]
    },
    {
      section: 'feedback',
      body: [
        {
          question: 'How can I ask feedback?',
          answer:
            'In order to ask for a feedback, go to the Training section and select one of the training. On the details page click on the button "Ask for feedback" and enter the email of the Eni person you want to ask for feedback.'
        },
        {
          question: 'How do I check my feedback requests?',
          answer:
            'You can check your feedback (requested and sent) from the dedicated section. You reach it by clicking on the homepage and then on the Feedback box. Once on the page, use the switch to view the feedback you have requested and those that have been requested by others.'
        },
        {
          question: 'Who can I ask for feedback?',
          answer:
            'You can ask for a feedback from whoever you want, but must be a person who work in Eni. For example you can ask to your manager, to a colleague with whom you usually work or to one is working with you on a temporay project. '
        }
      ]
    },
    {
      section: 'trainings',
      body: [
        {
          question: 'How do I start a training?',
          answer:
            'In order to start a training, simply go to the "Training" area, and click on the "+" button at the top. Follow the instructions to create a new training.'
        },
        {
          question: 'How can I change the training end date? ',
          answer:
            "In order to change the training end date or any other setting / content of an active training, click on the Training and open the detail page. Here's what you can do: <ul><li>change the actions entered </li><li>add new actions</li><li>change the training end date</li><li>send a request for feedback</li><li>eliminate the entire training</li></ul>"
        },
        {
          question: 'How do I complete a training? ',
          answer:
            'It depends on the type of training you have chosen.</br></br>Have you selected the D.A.R.E.method? The training is completed when you indicate as done at least once all the actions you have entered.</br></br>Have you chosen the Back Planning method?</br>It is completed when you indicate that all the actions entered are done.</br></br>If you have chosen Let yourself be inspired, you will find a Conclude workout button directly on the detail page. Remember that in any case, a reminder notification will be sent to you on the end date of your training and you can decide whether to end your training or extend it. '
        }
      ]
    },
    {
      section: 'general',
      body: [
        {
          question: 'How can I change the language?',
          answer:
            'The web app is available in 3 languages. If you want to switch the language you simply click on the world icon, in the upper left on the homepage.'
        },
        {
          question:
            'On some pages of the web app there are grey icons: what do they stand for?',
          answer:
            'The gray icon indicates a deepening. By clicking on the icon, a pop up will show additional information and instructions relating the page you are on. These insights will help and guide you on your training journey.'
        }
      ]
    }
  ],
  fr: [
    {
      section: 'positionnemen',
      body: [
        {
          question: "Qu'est-ce que c'est le positionnement?",
          answer:
            "Le Positionnement su My Focus est une section qui te permet de positionnes-toi entre 4 cluster des Ressources Humaines (Pensée, Action, Relation et Personne) et entre les capacités individuelles propres à chaque cluster. C'est un outil que tu peux utiliser pour suivre tes progrès et les différentes compétences sur lesquelles tu décideras de te entraîner."
        },
        {
          question: 'Comment puis-je faire le positionnement?',
          answer:
            "Dans la section Positionnement tu peux effectuer deux types d'actions différents. Le premier est un auto-positionnement sur les 4 clusters de focus Humain que tu peux faire manuellement, en déplaçant les curseurs, ou de manière guidée, en répondant aux 4 questions du quiz proposé. Dans le second, tu peux faire un auto-positionnement plus approfondi. En choisissant un cluster et une capacité spécifique, il te sera proposé un ensemble variable de questions auxquelles répondre pour te positionner."
        },
        {
          question:
            'Je ne peux plus déplacer le curseur dans la section "ton style", pourquoi?',
          answer:
            "La section Ton style est divisée en 2 tables: Vision d'ensemble et Approfondissement. Le premier se compose de 4 curseurs, relatifs aux 4 clusters Human Focus, et d'un quiz. Ceux-ci peuvent être effectués et modifiés jusqu'à ce que tu commences le Approfondissement. En effet, lorsque tu commences à positionner-toi sur les compétences individuelles, tu verras que les curseurs et le quiz seront désactivés."
        }
      ]
    },
    {
      section: 'profil',
      body: [
        {
          question:
            'Comment est-ce que je peux changer o télécharger une photo de profil?',
          answer:
            "Pour modifier ta photo de profil ou en télécharger une nouvelle, tu dois rendre-toi dans la section Profil et cliquer sur l'image déjà présente ou sur l'avatar prédéfini."
        },
        {
          question:
            'Comment puis-je changer les informations que je vois dans mon profil ?',
          answer:
            'Si quelque chose a changé et/ou si tu veux simplement refaire le quiz de Profilage, tu dois rendre-toi dans la section Profil, faire défiler la page et cliquer sur le bouton Refaire le profilage. Cela permettra-toi de répondre de nouveau aux questions du quiz.'
        }
      ]
    },
    {
      section: 'feedback',
      body: [
        {
          question: 'Comment puis-je demander un feedback?',
          answer:
            "Pour pouvoir demander des feedback, tu dois accéder à la section Entraînements (dans le menu du bas) et sélectionner l'un des entraînements actifs. À partir de la page de détail de la formation, cliques sur le bouton Demander des feedback situé au bas de la page. Une pop up s'ouvrira"
        },
        {
          question: 'Comment puis-je contrôler mes demandes de feedback?',
          answer:
            "Tu peux consulter ton feedback (demandés et envoyés) depuis la section dédiée. Pour y accéder, tu peux cliquer sur Accueil dans le menu du bas puis sur la case Feedback. Une fois sur la page, utilises le commutateur en haut pour voir les commentaires que tu as demandés et ceux qui ont été demandés par d'autres."
        },
        {
          question: "A' qui puis-je demander un feedback?",
          answer:
            "Tu peux librement décider à qui demander, l'important est qu'il soit un collègue d'Eni. Tu peux demander l'avis de votre manager, d'un collègue avec qui tu travailles habituellement ou avec qui tu collabores sur un projet particulier et temporaire."
        }
      ]
    },
    {
      section: 'Entraînements',
      body: [
        {
          question: 'Comment puis-je activer un entraînement?',
          answer:
            "Pour activer un entraînement, il suffit d'aller dans la section Entraînement, cliquer sur le bouton '+' en haut et de suivre le cheminement guidé pour créer l'entraînement."
        },
        {
          question:
            "Comment puis-je changer la date de la fin de l'entraînement?",
          answer:
            "Pour modifier la date de la fin de l'entraînement ou tout autre paramètre/contenu d'un entraînement actif, tu peux accéder à la section Entraînements et cliquer sur l'entraînement souhaité pour ouvrir la page de détails. A ce stade, il est possible modifier les actions précédemment saisies, d'en ajouter de nouvelles, de modifier la date de la fin de la formation et d'envoyer une demande de retour d'expérience. Si, pour une raison quelconque, tu souhaites supprimer l'intégralité de l'entraînement, n'oublies pas que tu peux toujours le faire à partir de la page de détails."
        },
        {
          question: 'Comment puis-je terminer un entraînement?',
          answer:
            "Cela dépend du type de formation que tu as choisi. Si tu as sélectionné le D.A.R.E. puis il sera terminé lorsque tu auras indiqué comme fait, au moins une fois, toutes les actions que tu as saisies. Si par contre tu as choisi la méthode Back Planning, elle sera complétée lorsque tu indiqueres toutes les actions saisies effectuées. Enfin, si tu as choisi Laisser-te inspirer, tu trouveres un bouton Terminer l'entraînement directement sur la page de détail. N'oublies pas que dans tous les cas, une notification de rappel te sera envoyée à la date de fin de ta formation et tu pourres décider de mettre fin à ta formation ou de la prolonger."
        }
      ]
    },
    {
      section: 'général',
      body: [
        {
          question: 'Comment puis-je changer la langue ?',
          answer:
            "La web app est disponible en 3 langues. Tu peux changer la langue d'utilisation, tu dois y aller sur la page d'accueil et tu dois cliquer sur l'icône monde située en haut à gauche de l'écran."
        },
        {
          question:
            "À quoi servent les icônes grises qui sont insérées dans quelques pages de l'application web ?",
          answer:
            "Les icônes grises indiquent la présence d'un approfondissement. En effet, un clic sur l'icône ouvrira une fenêtre contextuelle où tu pourras trouver une série d'informations et d'explications supplémentaires relatives à la page sur laquelle tu te trouves. Ces informations peuvent te aider et te guider dans tes parcours de formation."
        }
      ]
    }
  ]
};
