import * as React from 'react';
import { useHistory } from 'react-router';
import { Trans } from '@lingui/react';
import { Button } from 'reakit';
import merge from 'classnames';

import CloseIcon from '@common/Icons/CloseIcon';
import MainButton from '@common/MainButton/MainButton';
import SecondaryButton from '@common/SecondaryButton';
import Modal from '@common/Modal';

import s from './QuestionnaireHeader.module.css';

export default function QuestionnaireHeader({
  currentQuestion,
  cardsLength,
  title,
  color,
  isSkillMode = false,
  goBackLink,
  containerClassName
}) {
  const [isCloseModalOpen, setIsCloseModalOpen] = React.useState(false);

  const history = useHistory();

  return (
    <>
      <div
        className={merge(
          s.QuestionHeader,
          isSkillMode ? s.SkillMode : null,
          containerClassName
        )}
        style={{ '--quest-header-color': color }}
      >
        <h1>{title}</h1>
        <div>
          <Trans id="Questionnaire.Header.question" />{' '}
          <span className={s.CurrentQuestion}>{currentQuestion + 1}</span>/
          {cardsLength}
        </div>

        <progress
          className={s.Progress}
          value={currentQuestion + 1}
          max={cardsLength}
        />

        <Button
          className={s.Close}
          onClick={() => setIsCloseModalOpen(true)}
          data-esc="true"
        >
          <span>
            <Trans id="Questionnaire.Header.esc" />
          </span>
          <CloseIcon />
        </Button>
      </div>

      {isCloseModalOpen && (
        <Modal
          isOpen={true}
          backdropClassName={s.Backdrop}
          className={s.Modal}
          onClose={() => setIsCloseModalOpen(false)}
          hideOnClickOutside={false}
        >
          <div className={s.ModalContainer}>
            <div className={s.Box}>
              <div className={s.BoxHeader}>
                <Trans id="QuestionnaireHeader.Modal.title" />
              </div>
              <div className={s.BoxMain}>
                <div className={s.BoxMainWrapper}>
                  <Trans id="QuestionnaireHeader.Modal.subtitle" />
                </div>
              </div>
            </div>

            <div className={s.Buttons}>
              <MainButton onClick={() => setIsCloseModalOpen(false)}>
                <Trans id="QuestionnaireHeader.Modal.continue"></Trans>
              </MainButton>
              <SecondaryButton onClick={() => history.push(goBackLink || '/')}>
                <Trans id="QuestionnaireHeader.Modal.exit"></Trans>
              </SecondaryButton>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
