import * as React from 'react';
import { use100vh } from 'react-div-100vh';

import HalfContainer from '@common/HalfContainer';
import Header from '@common/Header';

import s from './PageLayout.module.css';

export default function PageLayout({ children, color }) {
  const div100vh = use100vh();

  return (
    <div style={{ overflow: 'hidden' }}>
      <Header />
      <div
        className={s.ContentContainer}
        style={{ height: `${div100vh - 73}px` }}
      >
        <div className={s.ContentPanel}>
          <HalfContainer
            className={s.Container}
            height="auto"
            style={{
              minHeight: `${div100vh - 73}px`,
              '--half-color': color
            }}
          >
            {children}
          </HalfContainer>
        </div>
      </div>
    </div>
  );
}
