import * as React from 'react';
import { Button } from 'reakit';
import merge from 'classnames';

import ArrowRightIcon from '@common/Icons/ArrowRight';

import Card from '@common/Card';
import Avatar from '@common/Icons/Avatar';

import s from './CardItem.module.css';

export default function CardItem({
  isUserCard,
  title,
  subtitle,
  pictureHref,
  rightNumber,
  onClick,
  selected,
  alt,
  className,
  titleClassName,
  subtitleClassName
}) {
  return (
    <Card
      className={merge(s.Card, selected && s.CardSelected, className)}
      onClick={onClick}
    >
      <div className={s.ImageTitle}>
        {pictureHref ? (
          <div className={s.Image}>
            <img src={pictureHref} alt={alt} />
          </div>
        ) : isUserCard ? (
          <Avatar className={s.IconAvatar} />
        ) : null}

        <div className={s.Titles}>
          <div className={merge(s.Title, titleClassName)}>{title}</div>
          <div
            className={merge(s.SubTitle, 'font-size-small', subtitleClassName)}
          >
            {subtitle}
          </div>
        </div>
      </div>

      <div className={s.NumberContainer}>
        {rightNumber > 0 && <div className={s.RightNumber}>{rightNumber}</div>}

        <div className={s.IconContainer}>
          <ArrowRightIcon />
        </div>
      </div>
    </Card>
  );
}
