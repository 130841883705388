import React from 'react';
import { Trans } from '@lingui/react';

import s from './GetInspiredModal.module.css';

export default function GetInspiredModal({ examples }) {
  return (
    <div className={s.GetInspiredModalContainer}>
      <Trans
        id="GetInsipredModal.content"
        components={{ 0: <h2 />, 1: <p />, 2: <br /> }}
      />
    </div>
  );
}
