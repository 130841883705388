import * as React from 'react';
import merge from 'classnames';

import Arrow from '@common/Icons/Arrow';

import s from './TrainingProgress.module.css';

const numPoints = 5;

export default function TrainingProgress({ indexActive }) {
  return (
    <div className={s.Training}>
      {new Array(numPoints).fill(null).map((_, index) => (
        <div
          key={index}
          className={merge(indexActive >= index ? s.TrainingSelected : '')}
        ></div>
      ))}
      <div>
        <Arrow />
      </div>
    </div>
  );
}
