import * as React from 'react';
import {
  SliderInput,
  SliderTrack,
  SliderRange,
  SliderHandle,
  SliderMarker
} from '@reach/slider';
import { mutate } from 'swr';
import { useDebouncedCallback } from 'use-debounce';

import Card from '@common/Card';

import useSelectors from '../useSelector';
import { getAxios } from '@common/utilities';

import '@reach/slider/styles.css';
import s from './SlidersCard.module.css';

export default function SlidersCard({ clusters }) {
  const [key, setKey] = React.useState(0);

  const debouncedOnChange = useDebouncedCallback(
    (cluster, value) => onChange(cluster, value),
    1000
  );

  const { onChange, selectors } = useSelectors({
    initialClusters: clusters,
    onChangeCallback: afterChange
  });

  React.useEffect(() => {
    if (selectors) {
      setKey(key => key + 1);
    }
  }, [selectors]);

  function afterChange(newSelectors) {
    const axios = getAxios();
    axios
      .post('/clusters/progress', {
        clusters: newSelectors
          .filter(selector => selector.enabled === true)
          .map(selector => ({
            id: selector.id,
            value: selector.value
          }))
      })
      .then(() => mutate('/clusters/skills'));

    mutate('/clusters', newSelectors, false);
  }

  return (
    <Card className={s.SliderCard}>
      {selectors.map(cluster => {
        return (
          <div key={cluster.id} className={s.Slider}>
            <div className={s.SliderName}>{cluster.name}</div>
            <SliderInput
              key={key}
              min={0}
              max={100}
              step={10}
              defaultValue={cluster.value}
              disabled={!cluster.enabled}
              onChange={value => debouncedOnChange(cluster, value)}
            >
              <SliderTrack className={s.SliderTrack}>
                <SliderRange
                  className={s.SliderRange}
                  style={{ '--slider-color': cluster.color }}
                />
                <SliderMarker className={s.Marker} value={0} />
                <SliderMarker className={s.Marker} value={20} />
                <SliderMarker className={s.Marker} value={40} />
                <SliderMarker className={s.Marker} value={60} />
                <SliderMarker className={s.Marker} value={80} />
                <SliderMarker className={s.Marker} value={100} />
                <SliderHandle
                  className={s.SliderHandle}
                  style={{ '--slider-color': cluster.color }}
                />
              </SliderTrack>
            </SliderInput>
          </div>
        );
      })}
    </Card>
  );
}
