import React from 'react';

function Plus(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20.497}
      height={20.497}
      viewBox="0 0 20.497 20.497"
      {...props}
    >
      <path
        data-name="plus (2)"
        d="M19.472 9.224h-8.2v-8.2a1.025 1.025 0 00-2.05 0v8.2h-8.2a1.025 1.025 0 000 2.05h8.2v8.2a1.025 1.025 0 102.05 0v-8.2h8.2a1.025 1.025 0 100-2.05zm0 0"
        fill="#1c1c1c"
      />
    </svg>
  );
}

export default Plus;
