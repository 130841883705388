import * as React from 'react';
import { useForm } from 'react-hook-form';
import ContentLoader from 'react-content-loader';
import { gsap } from 'gsap';
import { i18n } from '@lingui/core';

import QuestionnaireRecap from './QuestionnaireRecap';
import QuestionnaireHeader from '@common/QuestionnaireHeader';
import Deck from '@common/Deck';

import useResource from '@common/useResource';
import useMediaQuery from '@common/useMediaQuery';

import s from './Questionnaire.module.css';

export default function Questionnaire({ isComingFromProfile }) {
  const cardRef = React.useRef(null);

  const { data: cards } = useResource('/profiling');

  const [questionnaireState, setQuestionnaireState] = React.useState({
    answers: [],
    currentQuestion: 0
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset
  } = useForm({ mode: 'onChange' });

  React.useEffect(() => {
    if (isDirty) {
      //wait till input gets color
      //not very clean but no other way
      setTimeout(animateCardOut, 300);
    }
  }, [isDirty]);

  function restart() {
    setQuestionnaireState({ answers: [], currentQuestion: 0 });
  }

  function animateCardOut() {
    cardRef.current.classList.add(s.Moving);
    gsap.to(cardRef.current, {
      rotation: -30,
      x: -1500,
      y: -100,
      onComplete: () => handleSubmit(onSubmit)()
    });
  }

  function onSubmit(data) {
    const currQuestion = cards[questionnaireState.currentQuestion];
    const currentQuestionId = currQuestion.id;
    const currentQuestionSource = currQuestion.source;

    const submittedAnswerValue = data[`question--${currentQuestionId}`];
    const submittedAnswer = currQuestion.answers.find(
      answ => answ.text === submittedAnswerValue
    );

    setQuestionnaireState(quest => ({
      ...quest,
      currentQuestion: quest.currentQuestion + 1,
      answers: [
        ...quest.answers,
        {
          answer: submittedAnswer.id,
          id: currentQuestionId,
          source: currentQuestionSource,
          recap: submittedAnswer.recap
        }
      ]
    }));

    reset();
  }

  const { currentQuestion } = questionnaireState;

  if (!cards) {
    return <QuestLoader />;
  }

  if (currentQuestion === cards.length) {
    return (
      <QuestionnaireRecap
        restart={restart}
        answers={questionnaireState.answers}
        isComingFromProfile={isComingFromProfile}
      />
    );
  }

  return (
    <div className={s.Wrapper}>
      <QuestionnaireHeader
        containerClassName={s.Header}
        currentQuestion={currentQuestion}
        cardsLength={cards.length}
        title={/*i18n*/ i18n._('Questionnaire.profile')}
      />

      <Deck
        cards={cards}
        currentQuestion={currentQuestion}
        onSubmit={onSubmit}
      />
    </div>
  );
}

function QuestLoader(props) {
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <ContentLoader
      speed={2}
      width={400}
      height={600}
      viewBox="0 0 400 600"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="48" y="48" rx="3" ry="3" width="200" height="25" />
      <rect x="48" y="88" rx="3" ry="3" width="120" height="15" />
      <rect
        x="48"
        y="116"
        rx="3"
        ry="3"
        width={isMobile ? '300' : '350'}
        height="442"
      />
    </ContentLoader>
  );
}
