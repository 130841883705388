import * as React from 'react';
import {
  Tab as BaseTab,
  useTabState,
  TabList as BaseTabList,
  TabPanel as BaseTabPanel
} from 'reakit';
import merge from 'classnames';

import s from './Tab.module.css';

const TabsContext = React.createContext();

export function Tabs({ children, ...initialState }) {
  const tab = useTabState(initialState);
  const value = React.useMemo(() => tab, Object.values(tab));
  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
}

export function Tab({ className, children, ...props }) {
  const tab = React.useContext(TabsContext);

  const isSelected = tab.selectedId === props.id;

  return (
    <BaseTab
      {...tab}
      {...props}
      className={merge(s.Tab, className, isSelected ? s.SelectedTab : '')}
    >
      {children}
    </BaseTab>
  );
}

export function TabList(props) {
  const tab = React.useContext(TabsContext);
  return <BaseTabList {...tab} {...props} />;
}

export function TabPanel(props) {
  const tab = React.useContext(TabsContext);
  return <BaseTabPanel {...tab} {...props} />;
}
