import * as React from 'react';
import { Button } from 'reakit';
import merge from 'classnames';

import s from './MainButton.module.css';

export default function MainButton({ children, className, ...props }) {
  return (
    <Button {...props} className={merge(s.MainButton, className)}>
      {children}
    </Button>
  );
}
