import React from 'react';

import Header from '@common/Header';
import HalfContainer from '@common/HalfContainer';

import s from './TrainingLayout.module.css';

export default function TrainingLayout({ children, bgHeight, color }) {
  return (
    <>
      <Header />

      <div
        className={s.Container}
        style={{ '--cluster-color': color || '#B4DDDB' }}
      >
        <HalfContainer
          height="auto"
          className={s.HalfContainer}
          // style={{
          //   background: `linear-gradient(0deg, #fff ${bgHeight || '60%'}, ${
          //     color || '#B4DDDB'
          //   } 40%)`
          // }}
        >
          {children}
        </HalfContainer>
      </div>
    </>
  );
}
