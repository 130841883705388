import * as React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { use100vh } from 'react-div-100vh';
import merge from 'classnames';

import useAuth from '@auth/useAuth';
import useResource from '@common/useResource';
import useI18n from '@common/useI18n';
import usePrevious from '@common/usePrevious';
import { getAxios } from '@common/utilities';
import useLocalStorage from '@common/useLocalStorage';

import HalfContainer from '@common/HalfContainer/HalfContainer';
import Welcome from './Welcome';
import ChangePicture from './ChangePicture';
import StartQuestionnaire from './StartQuestionnaire';
import Questionnaire from './Questionnaire';
import Header from '@common/Header';

import s from './Introduction.module.css';

const INTRODUCTION_LOCATIONS = {
  WELCOME: 'WELCOME',
  CHANGE_PIC: 'CHANGE_PIC',
  START_QUEST_MODAL: 'START_QUEST_MODAL',
  QUESTIONNAIRE: 'QUESTIONNAIRE'
};

export default function Introduction() {
  const [hasSetLang, setHasSetLang] = useLocalStorage('has-set-default-lang');

  const { user } = useAuth();
  const { i18n, changeLang } = useI18n();
  const prevLang = usePrevious(i18n.locale);
  const div100vh = use100vh();

  const routerLocation = useLocation();
  const { goToQuest } = queryString.parse(routerLocation.search);

  const { data: languages } = useResource('/languages');

  const [location, setLocation] = React.useState(
    goToQuest
      ? INTRODUCTION_LOCATIONS.QUESTIONNAIRE
      : INTRODUCTION_LOCATIONS.WELCOME
  );

  React.useEffect(() => {
    //if first time in the app
    if (languages && !goToQuest && !hasSetLang) {
      const axios = getAxios();
      const userBrowserLang = navigator.language;
      const [userLangPrefix] = userBrowserLang.split('-');

      const foundLang = languages.find(lang => {
        return lang.name.includes(userLangPrefix);
      });

      if (i18n.locale === userLangPrefix) {
        if (!hasSetLang) {
          setHasSetLang(true);
        }
        return;
      }

      if (foundLang) {
        axios
          .patch('/users/me/language', { language: foundLang.name })
          .then(res => {
            if (res.status === 200 || res.status === 201) {
              changeLang(userLangPrefix);
            }
          });
      } else {
        axios.patch('/users/me/language', { language: 'en-US' }).then(res => {
          if (res.status === 200 || res.status === 201) {
            changeLang('en');
          }
        });
      }
    }
  }, [languages, goToQuest, hasSetLang]);

  React.useEffect(() => {
    //lang may be already set
    //the way to know I'm done setting the default lang
    //and the lang updated
    if (!hasSetLang && !goToQuest && prevLang && i18n.locale !== prevLang) {
      setHasSetLang(true);
    }
  }, [hasSetLang, i18n.locale, prevLang, goToQuest]);

  const isQuestionnaire = location === INTRODUCTION_LOCATIONS.QUESTIONNAIRE;
  const hasAlreadyDoneProfiling = !user.steps.some(
    step => step.id === 'profiling'
  );

  if (!goToQuest && hasAlreadyDoneProfiling) {
    return <Redirect to="/" />;
  }

  if (!hasSetLang && !goToQuest) {
    return (
      <div
        style={{
          height: div100vh
        }}
      >
        <Header />
        <HalfContainer
          className={merge(s.MainContainer, isQuestionnaire ? s.Quest : '')}
          height={isQuestionnaire ? 'auto' : ''}
        ></HalfContainer>
      </div>
    );
  }

  return (
    <div
      style={{
        height: div100vh
      }}
    >
      <Header />
      <HalfContainer
        className={merge(s.MainContainer, isQuestionnaire ? s.Quest : '')}
        height={isQuestionnaire ? 'auto' : ''}
      >
        <Switch location={{ pathname: location }}>
          <Route path={INTRODUCTION_LOCATIONS.WELCOME}>
            <Welcome
              user={user}
              onClick={() => setLocation(INTRODUCTION_LOCATIONS.CHANGE_PIC)}
            />
          </Route>

          <Route path={INTRODUCTION_LOCATIONS.CHANGE_PIC}>
            <ChangePicture
              user={user}
              onClick={() =>
                setLocation(INTRODUCTION_LOCATIONS.START_QUEST_MODAL)
              }
            />
          </Route>

          <Route path={INTRODUCTION_LOCATIONS.START_QUEST_MODAL}>
            <StartQuestionnaire
              onClick={() => setLocation(INTRODUCTION_LOCATIONS.QUESTIONNAIRE)}
            />
          </Route>

          <Route path={INTRODUCTION_LOCATIONS.QUESTIONNAIRE}>
            <Questionnaire isComingFromProfile={Boolean(goToQuest)} />
          </Route>
        </Switch>
      </HalfContainer>
    </div>
  );
}
