import * as React from 'react';
import { Trans } from '@lingui/react';

import WelcomeIcon from '@common/Icons/WelcomeIcon';
import MainButton from '@common/MainButton';
import Card from '@common/Card';
import Dots from '@common/Dots';

import s from './Welcome.module.css';

export default function Welcome({ user, onClick }) {
  return (
    <div>
      <div className={s.CardContainer}>
        <Card className={s.Card}>
          <h1>
            <Trans
              id="Welcome.hello"
              values={{ name: user?.firstName || '' }}
            />
            , <br />
          </h1>

          <p>
            <Trans id="Welcome.before" />
          </p>

          <div className={s.Complete}>
            <mark className="highlight">
              <Trans id="Welcome.profile" />
            </mark>
          </div>
        </Card>
        <WelcomeIcon className={s.WelcomeIcon} />
      </div>

      <div className={s.DotsContainer}>
        <Dots length={3} activeIndex={0} />
      </div>

      <div className={s.ButtonContainer}>
        <MainButton onClick={onClick}>
          <Trans id="Welcome.continue" />
        </MainButton>
      </div>
    </div>
  );
}
