import React from 'react';
import ContentLoader from 'react-content-loader';

export default function SkeletonConfig(props) {
  return (
    <ContentLoader
      width={'100%'}
      height={150}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="30" />
      <rect x="0" y="50" rx="3" ry="3" width="100%" height="30" />
      <rect x="0" y="100" rx="3" ry="3" width="100%" height="30" />
    </ContentLoader>
  );
}
