import * as React from 'react';

export default function useSelectors({ initialClusters, onChangeCallback }) {
  const [selectors, setSelectors] = React.useState(initialClusters);

  React.useEffect(() => {
    if (initialClusters) {
      setSelectors(initialClusters);
    }
  }, [initialClusters]);

  function onChange(cluster, value) {
    const newSelectors = selectors.map(selector =>
      selector.id === cluster.id ? { ...selector, value } : selector
    );
    setSelectors(newSelectors);

    if (onChangeCallback && typeof onChangeCallback === 'function') {
      onChangeCallback(newSelectors);
    }
  }

  return { selectors, onChange };
}
