import React from 'react';
import { Button } from 'reakit';
import { Trans } from '@lingui/react';
import { NavLink } from 'react-router-dom';

import HomeIcon from '@common/Icons/Home';
import ProfileIcon from '@common/Icons/Profile';
import StyleIcon from '@common/Icons/Style';
import TrainingIcon from '@common/Icons/Training';
import NotificationIcon from '@common/Icons/NotificationIcon';
import NotificationModal from '@dashboard/NotificationModal';

import useMediaQuery from '@common/useMediaQuery';
import useNotifications from '@common/useNotifications';

import merge from 'classnames';
import s from './Navbar.module.css';

export default function Navbar(props) {
  const { className } = props;

  const isMobile = useMediaQuery('(max-width: 600px)');
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] =
    React.useState(false);

  const {
    loadMore,
    allNotifications,
    unreadNotificationsCount,
    setRead,
    isValidating,
    hasMore
  } = useNotifications();

  return (
    <>
      <div className={merge(s.NavbarContainer, className)}>
        <nav className={s.Navigation}>
          {!isMobile && (
            <div>
              <NotificationButton
                newNotificationNum={unreadNotificationsCount}
                onClick={() => setIsNotificationMenuOpen(true)}
              />
            </div>
          )}

          <NavLink exact to="/" activeClassName={s.Active} className={s.Link}>
            <HomeIcon className={s.MenuIcon} />
            <div>
              <Trans id="Menu.home" />
            </div>
          </NavLink>

          <NavLink to="/profile" activeClassName={s.Active} className={s.Link}>
            <ProfileIcon className={s.MenuIcon} />
            <div>
              <Trans id="Menu.profiling" />
            </div>
          </NavLink>

          <NavLink to="/styles" className={s.Link} activeClassName={s.Active}>
            <StyleIcon className={s.MenuIcon} />
            <div>
              <Trans id="Menu.Styles" />
            </div>
          </NavLink>

          <NavLink
            to="/trainings"
            className={s.Link}
            activeClassName={s.Active}
          >
            <TrainingIcon className={s.MenuIcon} />
            <div>
              <Trans id="Menu.trainings" />
            </div>
          </NavLink>
        </nav>
      </div>

      {!isMobile && (
        <NotificationModal
          isOpen={isNotificationMenuOpen}
          onClose={() => setIsNotificationMenuOpen(false)}
          className={s.Notification}
          backdropClassName={s.Backdrop}
          notifications={allNotifications}
          loadMore={loadMore}
          isRevalidating={isValidating}
          hasMore={hasMore}
          onClick={setRead}
        />
      )}
    </>
  );
}

function NotificationButton({ newNotificationNum, onClick }) {
  return (
    <Button className={s.NotificationButton} onClick={onClick}>
      <NotificationIcon />
      {newNotificationNum > 0 && (
        <div className={s.NotNum}>{newNotificationNum}</div>
      )}
    </Button>
  );
}
