import React from 'react';
import { Button } from 'reakit';
import { useDropzone } from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import { toast } from 'react-toastify';

import useAsync from '@common/useAsync';
import { getAxios } from '@common/utilities';

import Avatar from '@common/Icons/Avatar';
import MainButton from '@common/MainButton';
import Spinner from '@common/Spinner';

import s from './ChangeAvatar.module.css';

import { i18n } from '@lingui/core';
import { Trans } from '@lingui/react';

export default function ChangeAvatar({ onChangeComplete, onCloseModal }) {
  const editorRef = React.useRef(null);
  const saveImageAsync = useAsync();

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    accept: 'image/*',
    maxSize: 2000000,
    onDrop: onDropHandler
  });

  // React.useEffect(() => {
  //   open();
  // }, []);

  function onDropHandler(_, rejectedFile) {
    let isLargeSize = false;

    rejectedFile.forEach(file => {
      file.errors.forEach(err => {
        if (err.code === 'file-too-large') {
          isLargeSize = true;
        }
      });
    });

    if (isLargeSize) {
      toast.error('File size cannot be more than 2MB');
    }
  }

  function onSavePhoto() {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      // const canvas = editorRef.current.getImage();

      if (!canvas) return;

      canvas.toBlob(blob => {
        const axios = getAxios();

        const formData = new FormData();
        formData.append('photo', blob);

        saveImageAsync.run(
          axios
            .post('/users/me/photo', formData, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            })
            .then(() => {
              onChangeComplete();
              onCloseModal();
            })
        );
      });
    }
  }

  return (
    <div className={s.ChangeAvatarContainer} {...getRootProps()}>
      <h2>
        <Trans id="Avatar.Change.select" />
      </h2>

      <p className={s.Info}>
        <Trans id="Avatar.max_file_size" />
      </p>

      <div className={s.AvatarContainer}>
        {Array.isArray(acceptedFiles) && acceptedFiles.length > 0 ? (
          <AvatarEditor
            ref={editorRef}
            image={acceptedFiles[0]}
            color={[1, 1, 1, 0.5]}
            width={150}
            height={150}
            border={0}
            borderRadius={70}
            scale={1.2}
            rotate={0}
          />
        ) : (
          <Avatar />
        )}
      </div>

      <Button className={s.DropButton} onClick={open}>
        {Array.isArray(acceptedFiles) && acceptedFiles.length > 0
          ? /*i18n*/ i18n._('Avatar.Change.edit')
          : /*i18n*/ i18n._('Avatar.Change.add')}
      </Button>
      <input {...getInputProps()} />

      <MainButton className={s.BtnSave} onClick={onSavePhoto}>
        {saveImageAsync.isLoading ? (
          <Spinner />
        ) : (
          /*i18n*/ i18n._('Avatar.Change.save')
        )}
      </MainButton>
    </div>
  );
}
