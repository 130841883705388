import * as React from 'react';

function NotificationIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26.443}
      height={31.652}
      {...props}
    >
      <g data-name={3602145}>
        <g data-name="Group 224">
          <path
            data-name="Path 294"
            d="M15.175 5.643a.651.651 0 01-.651-.651V2.8a1.3 1.3 0 00-2.6 0v2.192a.651.651 0 11-1.3 0V2.8a2.604 2.604 0 115.209 0v2.192a.65.65 0 01-.658.651z"
            stroke="#000"
            strokeWidth={0.4}
          />
        </g>
        <g data-name="Group 225">
          <path
            data-name="Path 295"
            d="M13.222 31.452a4.563 4.563 0 01-4.558-4.558.651.651 0 011.3 0 3.256 3.256 0 106.511 0 .651.651 0 011.3 0 4.563 4.563 0 01-4.553 4.558z"
            stroke="#000"
            strokeWidth={0.4}
          />
        </g>
        <g data-name="Group 226">
          <path
            data-name="Path 296"
            d="M24.29 27.545H2.153a1.953 1.953 0 01-1.269-3.439 9.053 9.053 0 003.223-6.93v-3.955a9.115 9.115 0 0118.23 0v3.956a9.043 9.043 0 003.212 6.921 1.954 1.954 0 01-1.259 3.447zM13.222 5.406a7.821 7.821 0 00-7.813 7.813v3.956a10.345 10.345 0 01-3.673 7.916.651.651 0 00.418 1.15H24.29a.651.651 0 00.423-1.146 10.353 10.353 0 01-3.679-7.92v-3.954a7.821 7.821 0 00-7.812-7.815z"
            stroke="#000"
            strokeWidth={0.4}
          />
        </g>
      </g>
    </svg>
  );
}

export default NotificationIcon;
