import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Trans } from '@lingui/react';
import { i18n } from '@lingui/core';
import { useForm } from 'react-hook-form';

import useResource from '@common/useResource';
import useSkills from '@common/useSkills';

import TrainingLayout from '@new-training/TrainingLayout';
import TrainingProgress from '@new-training/TrainingProgress';
import SelectedBehaviorsBar from '@new-training/SelectedBehaviorsBar';
import MainButton from '@common/MainButton';
import Modal from '@common/Modal';
import ArrowRightIcon from '@common/Icons/ArrowRight';
import CloseIcon from '@common/Icons/CloseIcon';

import s from './TrainingGetInspierdSetupForm.module.css';

export default function TrainingGetInspierdSetupForm(props) {
  const { clusters, savedBehaviors, info, onSetInfo } = props;

  const history = useHistory();
  const { clusterId, skillId, methodType } = useParams();

  const { data: behaviors } = useResource(`/skills/${skillId}/behaviors`);

  const { data: hints } = useResource(`/clusters/${clusterId}/hints`);

  const { skills } = useSkills({ clusters });

  const { setValue } = useForm();
  const [openDetailsModal, setOpenDetailsModal] = React.useState(false);
  const [selectedHint, setSelectedHint] = React.useState({
    name: '',
    description: ''
  });

  const selectedCluster = clusters?.find(cluster => cluster.id == clusterId);
  const selectedSkill = skills?.[clusterId]?.find(skill => skill.id == skillId);
  const selectedBehaviors = behaviors?.filter(behavior =>
    savedBehaviors.includes(behavior.id)
  );

  React.useEffect(() => {
    const fields = Object.keys(info);

    if (fields.length < 1) return;

    fields.forEach(field => setValue(field, info[field]));
  }, []);

  React.useEffect(() => {
    if (!savedBehaviors || savedBehaviors.length < 1) {
      history.push(`/t/new/c/${clusterId}/s/${skillId}/behaviors`);
    }
  }, [savedBehaviors]);

  function openModalHandler(selection) {
    console.log(selection);
    setSelectedHint(selection);
    setOpenDetailsModal(true);
  }

  function closeModalHandler() {
    setOpenDetailsModal(false);
  }

  function onConfirm() {
    onSetInfo(selectedHint);
    history.push(
      `/t/new/c/${clusterId}/s/${skillId}/m/get_inspired/configuration`
    );
  }

  return (
    <>
      <Modal
        isOpen={openDetailsModal}
        onClose={closeModalHandler}
        hideOnClickOutside={false}
        backdropClassName={s.ModalBackdrop}
        className={s.GoalsModal}
      >
        <div className={s.ModalHeader}>
          <div className={s.ModalTitle}>{selectedHint.name}</div>
          <CloseIcon
            style={{ cursor: 'pointer' }}
            onClick={closeModalHandler}
          />
        </div>

        <div className={s.ModalBody}>
          {selectedHint && (
            <div>
              <div
                dangerouslySetInnerHTML={{ __html: selectedHint.description }}
              ></div>
            </div>
          )}

          <MainButton className={s.BtnModalConfirm} onClick={onConfirm}>
            <Trans id="Trainings.Setup.confirm" />
          </MainButton>
        </div>
      </Modal>

      <TrainingLayout color={selectedCluster?.color}>
        <div className={s.HeaderWrapper}>
          <div className={s.Header}>
            <Link to={`/t/new/c/${clusterId}/s/${skillId}/method`}>
              <ArrowRightIcon style={{ transform: 'rotate(180deg)' }} />
            </Link>

            <h1 className={s.Label}>{selectedSkill?.name}</h1>
          </div>
        </div>

        <div className={s.ProgressContainer}>
          <TrainingProgress indexActive={4} />
        </div>

        {selectedBehaviors?.length > 0 && (
          <SelectedBehaviorsBar behaviors={selectedBehaviors} />
        )}

        <div className={s.FormContainer}>
          {hints?.map((sb, idx) => (
            <div
              key={`${sb}-${idx}`}
              className={s.Item}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                openModalHandler(sb);
              }}
            >
              <div>{sb.name}</div>
              <div>
                <ArrowRightIcon />
              </div>
            </div>
          ))}
        </div>
      </TrainingLayout>
    </>
  );
}

function GoalsList(props) {
  const { getInputProps, goals, color } = props;

  return (
    <>
      {goals?.map((goal, idx) => (
        <div
          key={goal}
          className={s.GoalItem}
          style={{ '--bullet-color': color }}
        >
          <input
            name="goal"
            type="radio"
            value={goal}
            id={`${goal}-${idx}`}
            {...getInputProps()}
          />

          <label htmlFor={`${goal}-${idx}`}>{goal}</label>
        </div>
      ))}
    </>
  );
}
