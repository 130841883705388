import React from 'react';
import { useHistory } from 'react-router-dom';
import { Trans } from '@lingui/react';

import MainButton from '@common/MainButton';

import merge from 'classnames';
import s from './TrainingCompleteLastStep.module.css';

export default function TrainingCompleteLastStep(props) {
  const { clusterId, skillId, onToggleModal } = props;

  const history = useHistory();

  function gotoHandler(here) {
    if (here === 'position' && (!clusterId || !skillId)) return;

    onToggleModal('trainingCompleteModal', 'close');

    const gotoUrl =
      here === 'position' ? `/styles/c/${clusterId}/s/${skillId}` : '/';

    history.push(gotoUrl);
  }

  return (
    <>
      <div className={s.TrainingCompleteLastStep}>
        <div className={s.ModalHeader}>
          <div className={s.Title}>
            <Trans id="LastStep.title">Ti senti migliorato?</Trans>
          </div>
        </div>

        <div className={s.ModalBody}>
          <Trans id="LastStep.body">
            Se pensi di essere migliorato, posizionati nei cluster per tenere
            traccia dei tuoi miglioramenti.
          </Trans>
        </div>
      </div>

      <div className={s.ModalFooter}>
        <div className={s.Text}>
          <Trans id="LastStep.footer">Hai raggiunto il tuo obiettivo?</Trans>
        </div>

        <div className={s.ButtonWrapper}>
          <MainButton
            className={s.Button}
            onClick={() => gotoHandler('position')}
          >
            <Trans id="LastStep.position">Posizionati</Trans>
          </MainButton>
          <MainButton
            className={merge(s.Button, s.Outlined)}
            onClick={() => gotoHandler('home')}
          >
            <Trans id="LastStep.back_home">Torna alla home</Trans>
          </MainButton>
        </div>
      </div>
    </>
  );
}
