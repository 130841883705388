import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import faq from '../faq-data';
import useMediaQuery from '@common/useMediaQuery';

import ArrowRightIcon from '@common/Icons/ArrowRight';
import { Trans } from '@lingui/react';
import s from './FaqContent.module.css';

export default function FaqContent({ lang }) {
  const { section } = useParams();
  const history = useHistory();
  const isMobile = useMediaQuery('(max-width: 600px)');

  const sectionData = faq[lang]?.find(f => f.section === section);
  const content = sectionData?.body;

  return (
    <>
      <div className={s.FaqContentContainer}>
        {isMobile && (
          <div className={s.FaqContentTopPanel}>
            <div className={s.TitleWrapper}>
              <ArrowRightIcon
                className={s.IconBack}
                onClick={() => history.goBack()}
              />
              <div className={s.Title}>
                <span>
                  <Trans id="Faq.title" />
                </span>{' '}
                &#124;{' '}
                <span className="text-capitalize">{sectionData?.section}</span>
              </div>
            </div>

            <p>
              <Trans id="Faq.extended" />
            </p>
          </div>
        )}

        {content?.map(c => (
          <div className={s.FaqWrapper} key={c.question}>
            <div className={s.Question}>{c.question}</div>
            <div
              className={s.Answer}
              dangerouslySetInnerHTML={{ __html: c.answer }}
            ></div>
          </div>
        ))}
      </div>
    </>
  );
}
