import * as React from 'react';
import { useHistory } from 'react-router';
import useSWR from 'swr';
import { toast } from 'react-toastify';

import config from './config';
import useAuth from '@auth/useAuth';
import { getAxios } from './utilities';

async function fetcher(key) {
  const axios = getAxios();

  try {
    const response = await axios.get(key);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }

    throw new Error(`${response.status}`);
  } catch (err) {
    //if coming from throw error in try (201 < err < 500)
    if (Number(err.message)) {
      const status = Number(err.message);
      if (Number.isInteger(status)) {
        throw new Error(status);
      }
    }

    //if axios throws ---> 500
    throw new Error(err?.response.status);
  }
}

export default function useResource(
  key,
  options,
  { redirect = true, fireOnMount = true } = {}
) {
  const { mutate: userMutate } = useAuth({
    fireOnMount: fireOnMount,
    redirect: redirect
  });
  const { data, error, ...swrReturnData } = useSWR(key, fetcher, options);

  const history = useHistory();

  async function updateServerData({
    endpoint,
    method,
    body,
    onFinish,
    onError,
    errorMessage
  }) {
    const axios = getAxios();
    try {
      const response = await axios({ method, url: endpoint, data: body });
      if ([401, 403].includes(response.status) && redirect) {
        if (config.ssoURL) {
          window.location.href = config.ssoURL;
        } else {
          history.push('/login');
        }

        return;
      }

      if (onFinish && typeof onFinish === 'function') {
        onFinish(response);
      }
    } catch (err) {
      toast.error(
        errorMessage || "Ops c'è stato un errore nel salvatggio dei dati"
      );
      if (onError && typeof onError === 'function') {
        onError(err);
      }
    }
  }

  React.useEffect(() => {
    if (error) {
      if (error.message == 401 || error.message == 403) {
        if (redirect) {
          //delete cached objects and redirect
          userMutate(null, false);
          swrReturnData.mutate(null, false);

          if (config.ssoURL) {
            window.location.href = config.ssoURL;
          } else {
            history.push('/login');
          }

          return;
        }
      }

      if (Number(error.message) > 403) {
        toast.error("Oops c'è stato un errore");
      }
    }
  }, [error]);

  return { ...swrReturnData, error, data, updateServerData };
}
