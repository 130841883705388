import React from 'react';
import { Trans } from '@lingui/react';

import MainButton from '@common/MainButton';

import s from './GetInspiredDetailsConfig.module.css';

export default function GetInspiredDetailsConfig(props) {
  const { configuration, onCompleteTraining } = props;

  return (
    <div className={s.GetInspiredDetailsContainer}>
      <div className={s.GetInspiredDetailsCard}>
        <div
          dangerouslySetInnerHTML={{ __html: configuration.description }}
        ></div>
      </div>
      <MainButton
        className={s.MainBtn}
        onClick={() => onCompleteTraining('trainingCompleteModal', 'open')}
      >
        <Trans id="Details.complete-training" />
      </MainButton>
    </div>
  );
}
