import React from 'react';
import { Link } from 'react-router-dom';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/react';

import useResource from '@common/useResource';

import Card from '@common/Card';
import Modal from '@common/Modal';
import DetailsInfo from '../DetailsInfo';
import DareDetailsConfig from '../DareDetailsConfig';
import BackPlanningDetailsConfig from '../BackPlanningDetailsConfig';
import GetInspiredDetailsConfig from '../GetInspiredDetailsConfig';
import DetailsDuration from '../DetailsDuration';
import DetailsFeedbackMobile from '../DetailsFeedbackMobile';
import PlusIcon from '@common/Icons/Plus';
import ArrowRightIcon from '@common/Icons/ArrowRight';

import SkeletonConfig from '../SkeletonConfig';

import s from './TrainingDetailsMobile.module.css';

export default function TrainingDetailsMobile(props) {
  const {
    subSectionTitleClass,
    behaviors,
    goal,
    training,
    trainingType,
    isCompleted,
    configuration,
    startDate,
    endDate,
    isProcessing,
    onConfigChange,
    onConfigCounterUpdate,
    onSort,
    onConfigStatusUpdate,
    onSave,
    onOpenCalendar,
    onAlertModal,
    toggleModalHandler,
    onDeleteRow
  } = props;

  const {
    data: feedbacks,
    // error: feedbacksError,
    mutate: mutateFeedback
  } = useResource(training ? `/trainings/${training?.id}/feedbacks` : null);

  const [openFeedbackModal, setOpenFeedbackModal] = React.useState(false);

  return (
    <>
      <Modal
        isOpen={openFeedbackModal}
        className={s.FeedbackModal}
        backdropClassName={s.FeedbackModalBackdrop}
        onClose={() => setOpenFeedbackModal(false)}
        hideOnClickOutside={false}
      >
        <DetailsFeedbackMobile
          trainingId={training?.id}
          onToggleModal={val => setOpenFeedbackModal(val)}
        />
      </Modal>

      <div className={s.TrainingDetailsMobileContainer}>
        <DetailsInfo
          subSectionTitleClass={subSectionTitleClass}
          behaviors={behaviors}
          goal={goal}
        />

        <section className={s.ConfigurationSection}>
          {trainingType !== 'get_inspired' && (
            <div className={s.SubSectionTitle}>
              {trainingType === 'dare' ? (
                <Trans id="TrainingDetails.dare">
                  Quando porti a termine un’azione, premi sul numero per
                  portarti avanti
                </Trans>
              ) : trainingType === 'back-planning' ? (
                <Trans id="TrainingDetails.back-planning">
                  Spunta le azioni che hai portato a termine per monitorare i
                  tuoi progressi
                </Trans>
              ) : trainingType === 'get_inspired' ? (
                <Trans id="TrainingDetails.get-inspired">Allenamento</Trans>
              ) : (
                <Trans id="TrainingDetails.wait">'Please wait...'</Trans>
              )}
            </div>
          )}

          {!training && (
            <Card style={{ padding: '2rem 1rem' }}>
              <SkeletonConfig />
            </Card>
          )}

          {trainingType === 'dare' ? (
            <DareDetailsConfig
              isCompleted={isCompleted}
              configuration={configuration}
              onConfigChange={onConfigChange}
              onConfigCounterUpdate={onConfigCounterUpdate}
              onSave={onSave}
              isProcessing={isProcessing}
              onDeleteRow={onDeleteRow}
            />
          ) : trainingType === 'back-planning' ? (
            <BackPlanningDetailsConfig
              isCompleted={isCompleted}
              configuration={configuration}
              onSort={onSort}
              // onSort={sortingHandler}
              onConfigChange={onConfigChange}
              onConfigStatusUpdate={onConfigStatusUpdate}
              onSave={onSave}
              isProcessing={isProcessing}
              onDeleteRow={onDeleteRow}
            />
          ) : trainingType === 'get_inspired' ? (
            <GetInspiredDetailsConfig
              isCompleted={isCompleted}
              configuration={configuration}
              onCompleteTraining={toggleModalHandler}
            />
          ) : null}
        </section>

        <DetailsDuration
          isCompleted={isCompleted}
          subSectionTitleClass={subSectionTitleClass}
          startDate={startDate}
          endDate={endDate}
          onOpenCalendar={() => onOpenCalendar()}
        />

        <section className={s.FeedbackSection}>
          <div className={s.FeedbackHeader}>
            <Trans id="DetailsFeedback.add_evaluators">
              Vuoi richiedere il feedback a qualcuno? <br />
              Aggiungi i tuoi valutatori.
            </Trans>
          </div>

          <div className={s.FeedbackContainer}>
            <div className={s.FeedbackTitleWrapper}>
              <div className={s.Title}>
                <Trans id="DetailsFeedback.request_feedback_title">
                  Richiedi feedback
                </Trans>
              </div>

              <div
                className={s.PlusIconWrapper}
                role="button"
                onClick={() => setOpenFeedbackModal(true)}
              >
                <PlusIcon />
              </div>
            </div>

            {feedbacks && (
              <div className={s.FeedbackList}>
                {feedbacks.map(fb => (
                  <Card
                    key={`${fb.feedback}-${fb.user.id}`}
                    className={s.UserCard}
                  >
                    <div className={s.UserName}>{fb.user.name}</div>

                    <Link
                      to={`/feedbacks/r/${fb.user?.id}/feedback/${fb.feedback}`}
                    >
                      <ArrowRightIcon />
                    </Link>
                  </Card>
                ))}
              </div>
            )}
          </div>
        </section>

        <section className={s.DeleteButtonWrapper}>
          <button className={s.BtnDeleteTraining} onClick={onAlertModal}>
            {/*i18n*/ i18n._('TrainingDetails.Button.delete')}
          </button>
        </section>
      </div>
    </>
  );
}
