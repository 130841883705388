import * as React from 'react';
import { toast } from 'react-toastify';

import useAsync from './useAsync';

//Same useAsync but it also handles errors by using toasts
export default function useAsyncAction() {
  const asyncAction = useAsync();
  const { data: response, error, run, status, reset } = asyncAction;

  React.useEffect(() => {
    if (status === 'resolved') {
      switch (response.status) {
        case 403:
        case 401:
          toast.error("Oops c'è stato un errore");
          break;
        default:
          break;
      }
    } else if (error) {
      toast.error("Oops c'è stato un errore");
    }
  }, [status, error, response]);

  return asyncAction;
}
