import * as React from 'react';
import useSWR from 'swr';

import { getAxios } from './utilities';

export default function useSkills({ clusters }) {
  const { data, ...swr } = useSWR(
    clusters ? '/clusters/skills' : null,
    async () => {
      const clusterIds = clusters.map(cluster => cluster.id);

      const axios = getAxios();
      const promises = clusterIds.map(clusterId =>
        axios.get(`/clusters/${clusterId}/skills`)
      );

      const responses = await Promise.all(promises);
      const responsesSkills = responses.map(res => res.data);

      const skillsPerCluster = {};
      //index order is the same as cluster order
      responsesSkills.forEach((skills, index) => {
        const clusterId = clusters[index].id;
        skillsPerCluster[clusterId] = skills;
      });

      return skillsPerCluster;
    }
  );

  return { ...swr, skills: data };
}
