import React from 'react';

function Profile(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26.285}
      height={26.284}
      viewBox="0 0 26.285 26.284"
      {...props}
    >
      <path
        data-name="Path 82"
        d="M22.421 4.252A12.837 12.837 0 104.252 22.381c.007.007.01.018.017.025.074.072.155.132.23.2.206.183.413.373.63.55.116.092.237.183.357.268.205.153.411.306.625.448.146.092.3.183.446.275.2.119.4.239.6.348.173.092.35.171.526.254.193.092.382.183.58.266s.4.147.6.218.372.138.564.2c.216.065.438.116.658.17.183.045.361.1.55.133.253.05.51.083.767.117.159.022.314.053.474.069.42.041.844.064 1.271.064s.851-.023 1.271-.064c.16-.016.315-.047.474-.069.257-.035.514-.067.767-.117.183-.037.367-.092.55-.133.22-.054.442-.1.658-.17.192-.059.377-.13.564-.2s.4-.138.6-.218.387-.175.58-.266c.176-.083.353-.162.526-.254.2-.109.4-.229.6-.348.149-.092.3-.175.446-.275.215-.141.42-.294.625-.448.119-.092.24-.174.357-.268.217-.174.424-.359.63-.55.075-.069.156-.129.23-.2.007-.006.01-.017.017-.025a12.822 12.822 0 00.379-18.129zM20.36 21.421a10.255 10.255 0 01-.825.653 10.46 10.46 0 01-.887.575q-.242.138-.492.275c-.147.075-.3.146-.447.216a8.29 8.29 0 01-.979.392c-.165.056-.31.108-.467.155-.183.055-.375.1-.565.146-.149.035-.3.074-.446.1a10.14 10.14 0 01-.662.1c-.127.016-.252.039-.38.052-.352.034-.709.054-1.069.054s-.717-.02-1.069-.054c-.127-.013-.253-.036-.38-.052-.223-.03-.445-.06-.662-.1-.15-.029-.3-.069-.446-.1a12.98 12.98 0 01-.565-.146c-.157-.047-.312-.1-.467-.155s-.339-.118-.5-.183-.321-.137-.479-.209-.3-.14-.447-.216a8.524 8.524 0 01-.492-.275 11.377 11.377 0 01-.887-.575 11.4 11.4 0 01-.825-.653c-.04-.03-.077-.069-.116-.1a7.357 7.357 0 015-6.873 5.437 5.437 0 004.671 0 7.357 7.357 0 015 6.873c-.041.031-.077.068-.117.1zM9.941 7.652a3.668 3.668 0 115 5 .019.019 0 00-.016.005 3.885 3.885 0 01-.767.325c-.048.014-.092.032-.142.044-.092.024-.188.04-.282.057a3.714 3.714 0 01-.538.054h-.1a3.714 3.714 0 01-.538-.054 3.611 3.611 0 01-.282-.057c-.049-.012-.092-.03-.142-.044a3.882 3.882 0 01-.767-.325l-.016-.005a3.668 3.668 0 01-1.41-5zM22.1 19.499h0a9.216 9.216 0 00-4.91-6.321 5.5 5.5 0 10-8.106 0 9.216 9.216 0 00-4.91 6.321 11 11 0 1117.926 0z"
        fill="currentColor"
        // fill="#b7b7b7"
        stroke="#fff"
        strokeWidth={0.6}
      />
    </svg>
  );
}

export default Profile;
