import * as React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { Trans } from '@lingui/react';
import { ToastContainer } from 'react-toastify';
import cl from 'classnames';

import Dashboard from '@dashboard/Dashboard';
import LoginPage from '@auth/LoginPage';
import PrivateRoute from '@auth/PrivateRoute';
import StyleQuiz from '@dashboard/user-style/StyleQuiz';
import SkillQuiz from '@dashboard/user-style/SkillQuiz';
import Introduction from '@dashboard/introduction';
import NewTraining from '@new-training';
import EniLogo from '@common/Icons/EniLogo';
import MyFocusLogo from '@common/Icons/MyFocusLogo';

import config from '@common/config';

import s from './App.module.css';
import 'react-toastify/dist/ReactToastify.min.css';

export default function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Switch>
          <Route path="/about">About public page</Route>

          {!import.meta.env.PROD ? (
            <Route path="/login">
              <LoginPage />
            </Route>
          ) : null}

          <Route path="/logout">
            <LogoutPage />
          </Route>

          <Route path="/unauthorized">
            <UnauthPage />
          </Route>

          <PrivateRoute path="/introduction">
            <Introduction />
          </PrivateRoute>

          <PrivateRoute path="/styles/c/:clusterId/s/:skillId">
            <SkillQuiz />
          </PrivateRoute>

          <PrivateRoute path="/styles/quiz">
            <StyleQuiz />
          </PrivateRoute>

          <PrivateRoute path="/t/new">
            <NewTraining />
          </PrivateRoute>

          <PrivateRoute path="/">
            <Dashboard />
          </PrivateRoute>

          <Route path="*">
            <div>404</div>
          </Route>
        </Switch>
      </Router>
    </>
  );
}

function LogoutPage() {
  return (
    <div>
      <header className={s.Header}>
        <div></div>

        <div className={cl(s.HeaderRight, 'center-y')}>
          <div className={s.LogoWrapper}>
            <EniLogo />
          </div>

          <div className={s.Separator}></div>

          <div className={cl(s.LogoContainer, 'center-y')}>
            <MyFocusLogo />
          </div>
        </div>
      </header>

      <div className={s.Bg}>
        <main className={s.Main}>
          <h1 className={s.Title}>
            <Trans id="App.Logout.title" />
          </h1>
          <h2 className={s.Subtitle}>
            <Trans
              id="App.Logout.subtitle"
              components={{ 0: <a href={config.ssoURL} /> }}
            />
          </h2>
        </main>
      </div>
    </div>
  );
}

function UnauthPage() {
  return (
    <div>
      <header className={s.Header}>
        <div></div>

        <div className={cl(s.HeaderRight, 'center-y')}>
          <div className={s.LogoWrapper}>
            <EniLogo />
          </div>

          <div className={s.Separator}></div>

          <div className={cl(s.LogoContainer, 'center-y')}>
            <MyFocusLogo />
          </div>
        </div>
      </header>

      <div className={s.Bg}>
        <main className={s.Main}>
          <h1 className={s.Title}>
            <Trans id="unauth.title">
              Myfocus è riservato solo ai dipendenti ENI.
            </Trans>
          </h1>
          <h2 className={s.Subtitle}>
            <Trans id="unauth.subtitle">
              Se sei un dipendente e non riesci ad accedere, contatta
              l'Helpdesk.
            </Trans>
          </h2>
        </main>
      </div>
    </div>
  );
}
