import React from 'react';

function Training(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22.75}
      height={22.75}
      viewBox="0 0 22.75 22.75"
      {...props}
    >
      <path
        data-name="Path 81"
        d="M22.083 12.841h-2.221V3.333a3.333 3.333 0 00-6.665 0v15.151a1.822 1.822 0 11-3.644 0V3.333a3.333 3.333 0 00-6.665 0v9.509H.667a.667.667 0 100 1.333h.444v6.132a2.444 2.444 0 004.888 0v-6.133h.444a.667.667 0 100-1.333H4.221V3.333a2 2 0 014 0v15.151a3.155 3.155 0 106.31 0V3.333a2 2 0 114 0v9.509h-2.224a.667.667 0 100 1.333h.444v6.132a2.444 2.444 0 004.888 0v-6.133h.444a.667.667 0 100-1.333zM4.666 18.382l-2.222-.444v-1.129l2.222.444zm-1.111 3.035a1.112 1.112 0 01-1.111-1.111V19.3l2.222.444v.564a1.112 1.112 0 01-1.111 1.109zm1.111-5.523l-2.222-.444v-1.276h2.222zm15.641 2.044l-2.222.444v-1.129l2.222-.444zM19.2 21.417a1.112 1.112 0 01-1.111-1.111v-.564l2.222-.444v1.009a1.112 1.112 0 01-1.111 1.11zm1.111-5.967l-2.222.444v-1.72h2.222z"
        fill="currentColor"
        // fill="#b7b7b7"
      />
    </svg>
  );
}

export default Training;
