import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import useResource from '@common/useResource';

import ChooseCluster from './ChooseCluster';
import ChooseSkills from './ChooseSkills';
import ChooseBehaviors from './ChooseBehaviors';
import ChooseMethod from './ChooseMethod';
import TrainingSetupForm from './TrainingSetupForm';
import TrainingGetInspierdSetupForm from './TrainingGetInspierdSetupForm';
import TrainingConfiguration from './TrainingConfiguration';

export default function NewTraining() {
  const { data: clusters } = useResource('/clusters');

  const [trainingState, setTrainingState] = React.useState({
    behaviors: [],
    info: {}
  });

  function setBehaviors(behaviors) {
    setTrainingState(state => ({ ...state, behaviors }));
  }

  function setTrainingInfo(info) {
    setTrainingState(state => ({ ...state, info }));
  }

  return (
    <Switch>
      {/* Step - 1 */}
      <Route path="/t/new/clusters">
        <ChooseCluster clusters={clusters} />
      </Route>

      {/* Step - 2 */}
      <Route path="/t/new/c/:clusterId/skills">
        <ChooseSkills clusters={clusters} />
      </Route>

      {/* Step - 3 */}
      <Route path="/t/new/c/:clusterId/s/:skillId/behaviors">
        <ChooseBehaviors
          clusters={clusters}
          savedBehaviors={trainingState.behaviors}
          setBehaviors={setBehaviors}
        />
      </Route>

      {/* Step - 4 */}
      <Route path="/t/new/c/:clusterId/s/:skillId/method">
        <ChooseMethod
          clusters={clusters}
          savedBehaviors={trainingState.behaviors}
        />
      </Route>


      {/* Setp - 6 */}
      <Route path="/t/new/c/:clusterId/s/:skillId/m/:methodType/configuration">
        <TrainingConfiguration
          clusters={clusters}
          savedBehaviors={trainingState.behaviors}
          info={trainingState.info}
        />
      </Route>

      {/* Step - 5 - training method get_inspierd */}
      <Route path="/t/new/c/:clusterId/s/:skillId/m/get_inspired">
        <TrainingGetInspierdSetupForm
          clusters={clusters}
          savedBehaviors={trainingState.behaviors}
          info={trainingState.info}
          onSetInfo={setTrainingInfo}
        />
      </Route>

      {/* Step - 5 - training method any other */}
      <Route path="/t/new/c/:clusterId/s/:skillId/m/:methodType">
        <TrainingSetupForm
          clusters={clusters}
          savedBehaviors={trainingState.behaviors}
          info={trainingState.info}
          onSetInfo={setTrainingInfo}
        />
      </Route>
    </Switch>
  );
}
