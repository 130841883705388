import * as React from 'react';
import { Button } from 'reakit';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/react';
import merge from 'classnames';

import Spinner from '@common/Spinner';
import InfoIcon from '@common/Icons/InfoIcon';
import { Tabs, TabList, Tab, TabPanel } from '@common/Tab';
import MainButton from '@common/MainButton';
import Card from '@common/Card';
import Approfondimento from './Approfondimento';
import StyleSectionDesktop from './StyleSectionDesktop';
import RadarChart from './RadarChart';
import SlidersCard from './SlidersCard';
import InfoModal from './InfoModal';

import useAsync from '@common/useAsync';
import useResource from '@common/useResource';
import useSkills from '@common/useSkills';

import s from './StyleSection.module.css';

async function importRecharts() {
  return import('recharts');
}

export default function StyleSection({ isMobile }) {
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);

  const { data: clusterList } = useResource('/clusters');
  const { skills } = useSkills({ clusters: clusterList });

  const importRechartsAsync = useAsync();

  React.useEffect(() => {
    importRechartsAsync.run(importRecharts());
  }, []);

  function openModal() {
    setIsInfoModalOpen(true);
  }

  function closeModal() {
    setIsInfoModalOpen(false);
  }

  const isDownloadingRecharts = importRechartsAsync.isLoading;
  const isIdleRecharts = importRechartsAsync.isIdle;

  if (isDownloadingRecharts || isIdleRecharts || !clusterList || !skills) {
    return (
      <div className={s.LoaderContainer}>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {isMobile ? (
        <MobileStyle
          clusterList={clusterList}
          skills={skills}
          recharts={importRechartsAsync.data}
          openModal={openModal}
          closeModal={closeModal}
        />
      ) : (
        <StyleSectionDesktop
          clusterList={clusterList}
          skills={skills}
          recharts={importRechartsAsync.data}
          openModal={openModal}
          closeModal={closeModal}
        />
      )}
      {isInfoModalOpen ? (
        <InfoModal isOpen={true} onClose={closeModal} />
      ) : null}
    </>
  );
}

function MobileStyle({ recharts, clusterList, skills, openModal }) {
  return (
    <div className={s.Container}>
      <div className={merge(s.Wrapper, s.Radar)}>
        <div className={s.InfoIconContainer}>
          <Button className={s.InfoIcon} onClick={openModal}>
            <InfoIcon />
          </Button>
        </div>
        <div className={s.RadarContainer}>
          <RadarChart recharts={recharts} data={clusterList} />
        </div>
      </div>

      <div className={s.ClustersContainer}>
        <Tabs selectedId="visione-dinsieme-tab">
          <div className={merge(s.Tabs, s.BottomWrapper)}>
            <TabList aria-label="tabs" className={s.TabList}>
              <Tab id="visione-dinsieme-tab" className={s.Tab}>
                <Trans id="Styles.overview">Visione d'insieme</Trans>
              </Tab>
              <Tab id="approfondimento-tab" className={s.Tab}>
                <Trans id="Styles.deepdive">Approfondimento</Trans>
              </Tab>
            </TabList>
          </div>

          <TabPanel>
            <Visione clusters={clusterList} />
          </TabPanel>
          <TabPanel>
            <Approfondimento clusters={clusterList} skills={skills} />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}

function Visione({ clusters }) {
  const isLinkDisabled = clusters.some(cluster => cluster.enabled === false);

  return (
    <div className={s.Visione}>
      <div className={s.VisioneTitle}>
        <Trans id="Styles.cursors">
          Come percepisci di essere? Muovi i cursori per valutarti
          autonomamente.{' '}
        </Trans>
      </div>

      <div className={s.SlidersContainer}>
        <SlidersCard clusters={clusters} />
      </div>

      <Card className={s.HelpPrompt}>
        <div className={s.Label}>
          <Trans id="Styles.help">Vuoi un aiuto?</Trans>
        </div>

        {isLinkDisabled ? (
          <div>
            <Trans id="Styles.cant">
              Non puoi rifare il quiz perchè hai già iniziato il posizionamento
              di approfondimento
            </Trans>
          </div>
        ) : (
          <div>
            <Trans id="Styles.answers">
              Rispondi a delle semplici domande per posizionarti.
            </Trans>
          </div>
        )}

        <div className={s.BtnContainer}>
          {isLinkDisabled ? (
            <div className={merge('main-btn-sm', s.Ghost)}>
              <Trans id="Styles.quiz">Vai al quiz</Trans>
            </div>
          ) : (
            <Link to="/styles/quiz" className="main-btn-sm">
              <Trans id="Styles.quiz">Vai al quiz</Trans>
            </Link>
          )}
        </div>
      </Card>
    </div>
  );
}
