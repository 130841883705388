import * as React from 'react';
import { use100vh } from 'react-div-100vh';
import merge from 'classnames';

import s from './HalfContainer.module.css';

export default function HalfContainer({ className, children, height, style }) {
  const div100vh = use100vh();

  return (
    <main
      className={merge(s.Container, className)}
      style={{ height: height || `calc(${div100vh}px - 72px)`, ...style }}
    >
      {children}
    </main>
  );
}
