import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';

import useAuth, { LOGIN_STATUS } from './useAuth';
import config from '@common/config';

export default function PrivateRoute({ children, path, exact }) {
  const { status, user } = useAuth();

  if (status === LOGIN_STATUS.LOADING) {
    return <Route path={path} exact={exact}></Route>;
  }

  if (user && status === LOGIN_STATUS.LOGGEDIN) {
    return (
      <Route path={path} exact={exact}>
        {children}
      </Route>
    );
  }

  //IF REJECTED
  return <Redirect to={config.ssoURL || '/login'} />;
}
