import * as React from 'react';
import { useLingui } from '@lingui/react';

import { messages } from '../locales/en/messages';
import { messages as itMessages } from '../locales/it/messages';
import { messages as frMessages } from '../locales/fr/messages';

export default function useI18n() {
  const { i18n } = useLingui();

  function changeLang(to) {
    switch (to) {
      case 'it': {
        i18n.load('it', itMessages);
        i18n.activate('it', itMessages);
        return;
      }
      case 'fr': {
        i18n.load('fr', frMessages);
        i18n.activate('fr', frMessages);
        return;
      }
      default: {
        i18n.load('en', messages);
        i18n.activate('en', messages);
        return;
      }
    }
  }

  return { i18n, changeLang };
}
