import * as React from 'react';
import { Button } from 'reakit';
import { useDropzone } from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import { toast } from 'react-toastify';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/react';

import MainButton from '@common/MainButton/MainButton';
import Avatar from '@common/Icons/Avatar';
import Spinner from '@common/Spinner';
import Card from '@common/Card';
import Dots from '@common/Dots';

import useAsync from '@common/useAsync';
import { getAxios } from '@common/utilities';

import s from './ChangePicture.module.css';

export default function ChangePicture({ user, onClick }) {
  const editorRef = React.useRef(null);

  const saveImageAsync = useAsync();

  const { getRootProps, getInputProps, isDragActive, open, acceptedFiles } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      maxFiles: 1,
      accept: 'image/*',
      maxSize: 2000000,
      onDrop: onDropHandler
    });

  React.useEffect(() => {
    if (saveImageAsync.isSuccess) {
      onClick();
    }
  }, [saveImageAsync.isSuccess]);

  function onDropHandler(_, rejectedFile) {
    let isLargeSize = false;

    rejectedFile.forEach(file => {
      file.errors.forEach(err => {
        if (err.code === 'file-too-large') {
          isLargeSize = true;
        }
      });
    });

    if (isLargeSize) {
      toast.error('File size cannot be more than 2MB');
    }
  }

  function onSubmit() {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      // const canvas = editorRef.current.getImage();

      if (!canvas) return;

      canvas.toBlob(blob => {
        const axios = getAxios();

        const formData = new FormData();
        formData.append('photo', blob);

        saveImageAsync.run(
          axios.post('/users/me/photo', formData, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
        );
      });
    }
  }

  const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`;

  return (
    <div>
      <div className={s.Subtitle}>
        <Trans id="Picture.Change.introduction" />

        <p>
          <Trans id="Avatar.max_file_size" />
        </p>
      </div>

      <div className={s.CardContainer} {...getRootProps()}>
        <Card className={s.Card}>
          <div className={s.Name}>{fullName}</div>
          <div className={s.AvatarContainer}>
            {Array.isArray(acceptedFiles) && acceptedFiles.length > 0 ? (
              <AvatarEditor
                ref={editorRef}
                image={acceptedFiles[0]}
                color={[1, 1, 1, 0.7]}
                width={150}
                height={150}
                border={0}
                borderRadius={70}
                scale={1.2}
                rotate={0}
              />
            ) : (
              <Avatar />
            )}
          </div>
          <Button className={s.DropButton} onClick={open}>
            {Array.isArray(acceptedFiles) && acceptedFiles.length > 0
              ? /*i18n*/ i18n._('Picture.Change.edit')
              : /*i18n*/ i18n._('Picture.Change.add')}
          </Button>
          <input {...getInputProps()} />
        </Card>
      </div>

      <div className={s.DotsContainer}>
        <Dots length={3} activeIndex={1} />
      </div>

      <div className={s.ButtonContainer}>
        <MainButton className={s.MainButton} onClick={onSubmit}>
          {saveImageAsync.isLoading ? (
            <Spinner />
          ) : (
            /*i18n*/ i18n._('Picture.Change.continue')
          )}
        </MainButton>
        <Button className={s.SkipButton} onClick={onClick}>
          <Trans id="Picture.Change.skip" />
        </Button>
      </div>
    </div>
  );
}
