import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

import Header from '@common/Header';
import TrainingProgress from '@new-training/TrainingProgress';
import HalfContainer from '@common/HalfContainer/HalfContainer';
import LinearProgress from '@common/ProgressBar/LinearProgress';
import ArrowRight from '@common/Icons/ArrowRight';
import Card from '@common/Card';
import { Trans } from "@lingui/react";
import useSkills from '@common/useSkills';

import s from './ChooseSkills.module.css';

export default function ChooseCluster({ clusters }) {
  const { clusterId } = useParams();

  const { skills } = useSkills({ clusters });

  const cluster = clusters?.find(cluster => cluster.id == clusterId);

  return (
    <Layout color={cluster?.color}>
      <div className={s.HeaderWrapper}>
        <div className={s.Header}>
          <Link to="/t/new/clusters">
            <ArrowRight style={{ transform: 'rotate(180deg)' }} />
          </Link>

          <h1 className={s.Label}>{cluster?.name}</h1>

          <div></div>
        </div>
      </div>

      <div className={s.ProgressContainer}>
        <TrainingProgress indexActive={1} />
      </div>

      <div className={s.Subtitle}><Trans id="Trainings.Skills.header" /></div>

      <ul className={s.List}>
        {skills && clusters
          ? skills[clusterId].map(skill => (
              <li key={skill.id}>
                <Link to={`/t/new/c/${clusterId}/s/${skill.id}/behaviors`}>
                  <CardItem {...skill} color={cluster.color} />
                </Link>
              </li>
            ))
          : new Array(4).fill(null).map((_, index) => (
              <li key={index}>
                <div>
                  <Card className={s.Card}>
                    <div
                      className={s.CardHeader}
                      style={{ height: '40px' }}
                    ></div>
                    <div className={s.CardBody}>
                      <ContentLoader
                        speed={2}
                        width={'100%'}
                        height={20}
                        viewBox="0 0 300 20"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                      >
                        <rect
                          x="18"
                          y="8"
                          rx="3"
                          ry="3"
                          width="260"
                          height="6"
                        />
                      </ContentLoader>
                    </div>
                  </Card>
                </div>
              </li>
            ))}
      </ul>
    </Layout>
  );
}

function CardItem({ name, value, color, description }) {
  return (
    <Card className={s.Card}>
      <div className={s.CardHeader}>
        <div>{name}</div>
        <div>
          <ArrowRight />
        </div>
      </div>
      <div className={s.CardBody}>
        <div>{description}</div>
        <LinearProgress className={s.Progress} color={color} value={value} />
      </div>
    </Card>
  );
}

function Layout({ children, color }) {
  return (
    <>
      <Header />
      <div className={s.Container}>
        <HalfContainer
          height="auto"
          className={s.HalfContainer}
          style={{
            background: `linear-gradient(0deg, #fff 60%, ${
              color || '#B4DDDB'
            } 40%)`
          }}
        >
          {children}
        </HalfContainer>
      </div>
    </>
  );
}
