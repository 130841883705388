import React from 'react';

function Camera(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25.755}
      height={20.866}
      viewBox="0 0 25.755 20.866"
      {...props}
    >
      <g data-name={2099154} fill="#707070">
        <path
          data-name="Path 283"
          d="M23.384 3.197h-2.562a.856.856 0 01-.609-.252l-2.25-2.25a2.355 2.355 0 00-1.676-.694H9.474a2.355 2.355 0 00-1.676.694l-2.25 2.25a.856.856 0 01-.609.252H2.375A2.374 2.374 0 000 5.566v12.929a2.369 2.369 0 002.371 2.371h21.013a2.373 2.373 0 002.371-2.371V5.567a2.369 2.369 0 00-2.371-2.37zm.862 15.3a.863.863 0 01-.862.861H2.371a.861.861 0 01-.862-.862V5.568a.863.863 0 01.862-.861h2.567a2.355 2.355 0 001.677-.7l2.25-2.25a.856.856 0 01.609-.252h6.813a.856.856 0 01.609.252l2.25 2.25a2.355 2.355 0 001.676.694h2.563a.861.861 0 01.862.862z"
        />
        <path
          data-name="Path 284"
          d="M12.88 5.62a5.6 5.6 0 105.6 5.6 5.609 5.609 0 00-5.6-5.6zm0 9.7a4.094 4.094 0 114.094-4.094 4.1 4.1 0 01-4.094 4.09z"
        />
        <path
          data-name="Path 285"
          d="M2.376 1.506h1.618a.755.755 0 000-1.509H2.376a.755.755 0 100 1.509z"
        />
      </g>
    </svg>
  );
}

export default Camera;
