import * as React from 'react';
import { Button } from 'reakit';
import { Link, useLocation } from 'react-router-dom';
import { useLingui, Trans } from '@lingui/react';
import merge from 'classnames';

import Modal from '@common/Modal';
import Globe from '@common/Icons/Globe';
import EniLogo from '@common/Icons/EniLogo';
import MyFocusLogo from '@common/Icons/MyFocusLogo';
import CloseIcon from '@common/Icons/CloseIcon';
import Stack from '@common/Stack';
import Spinner from '@common/Spinner';

import useResource from '@common/useResource';
import useMediaQuery from '@common/useMediaQuery';
import { getAxios } from '@common/utilities';

import { messages } from '../../locales/en/messages';
import { messages as itMessages } from '../../locales/it/messages';
import { messages as frMessages } from '../../locales/fr/messages';

import s from './Header.module.css';

const pathData = [
  { path: '/profile', title: 'Questionnaire.Recap.profile' },
  { path: ['/trainings', '/trainings/completed'], title: 'Trainings.header' }
];

export default function Header({ className }) {
  const [isLangModalOpen, setIsLangModalOpen] = React.useState(false);
  const [isChangingLang, setIsChangingLang] = React.useState(false);

  const { pathname } = useLocation();
  const { data: languages } = useResource('/languages');

  const { i18n } = useLingui();
  const isMobile = useMediaQuery('(max-width: 600px)');

  function changeLang(langName) {
    setIsChangingLang(true);

    const axios = getAxios();
    axios.patch('/users/me/language', { language: langName }).then(response => {
      if (response.status === 200 || response.status === 201) {
        setIsChangingLang(false);

        const [langFirstByte] = langName.split('-');
        const newMessages =
          langFirstByte === 'en'
            ? messages
            : langFirstByte === 'it'
            ? itMessages
            : langFirstByte === 'fr'
            ? frMessages
            : messages;

        i18n.load(langFirstByte, newMessages);
        i18n.activate(langFirstByte, newMessages);

        setIsLangModalOpen(false);
      }
    });
  }

  const getTitle = () => {
    const currPathData = pathData.find(pd => {
      if (Array.isArray(pd.path)) {
        return pd.path.includes(pathname);
      }

      return pd?.path === pathname;
    });

    return currPathData ? (
      <div className={s.Title}>
        {currPathData?.title && <Trans id={currPathData.title}></Trans>}
      </div>
    ) : null;
  };

  return (
    <>
      {isMobile ? (
        <header className={merge(s.Header, className)}>
          <div className={s.LogoWrapper}>
            <EniLogo className={s.Eni} />
          </div>

          {isMobile && getTitle()}

          <Link className={s.LogoWrapper} to="/">
            <MyFocusLogo />
          </Link>
        </header>
      ) : (
        <header className={merge(s.Header, className)}>
          <Button className={s.Globe} onClick={() => setIsLangModalOpen(true)}>
            <Globe />
            <span>
              <strong>{i18n.locale.toUpperCase()}</strong>
            </span>
          </Button>

          {isMobile && getTitle()}

          <div className={s.DesktopRight}>
            <div className={s.LogoWrapper}>
              <EniLogo className={s.Eni} />
            </div>

            <div className={s.Separator} />

            <Link to="/">
              <MyFocusLogo />
            </Link>
          </div>
        </header>
      )}

      {isLangModalOpen && (
        <Modal
          isOpen={isLangModalOpen}
          onClose={() => setIsLangModalOpen(false)}
          hideOnClickOutside={false}
          className={s.Modal}
          backdropClassName={s.Backdrop}
        >
          <div className={s.LangHeader}>
            <Stack alignItens="center">
              <Globe />
              <span>{i18n.locale.toUpperCase()}</span>
            </Stack>
            <Button
              className={s.LangClose}
              onClick={() => setIsLangModalOpen(false)}
            >
              <CloseIcon />
            </Button>
          </div>
          <div className={s.LangList}>
            {isChangingLang ? (
              <Spinner />
            ) : (
              languages?.map(lang => (
                <Button
                  className={s.LangButton}
                  onClick={() => changeLang(lang.name)}
                >
                  {lang.description}
                </Button>
              ))
            )}
          </div>
        </Modal>
      )}
    </>
  );
}
