import * as React from 'react';
import { useForm } from 'react-hook-form';
import ContentLoader from 'react-content-loader';
import { Link, useHistory, useParams } from 'react-router-dom';
import merge from 'classnames';
import { Trans } from '@lingui/react';

import Card from '@common/Card';
import HalfContainer from '@common/HalfContainer';
import TrainingProgress from '@new-training/TrainingProgress';
import Header from '@common/Header';
import ArrowRight from '@common/Icons/ArrowRight';
import MainButton from '@common/MainButton';

import useResource from '@common/useResource';
import useSkills from '@common/useSkills';

import s from './ChooseBehaviors.module.css';

export default function ChooseBehaviors(props) {
  const { clusters, savedBehaviors, setBehaviors } = props;

  const { handleSubmit, register, watch } = useForm({
    mode: 'onChange'
  });
  const { clusterId, skillId } = useParams();
  const history = useHistory();

  const { data: behaviors } = useResource(`/skills/${skillId}/behaviors`);
  const { skills } = useSkills({ clusters });

  // React.useEffect(() => {
  //   if (Array.isArray(savedBehaviors) && savedBehaviors.length > 0) {
  //     history.push(`/t/new/c/${clusterId}/s/${skillId}/method`);
  //   }
  // }, [savedBehaviors]);

  function onSubmit(data) {
    const selectedBehaviors = Object.keys(data)
      .filter(key => data[key] === true)
      .map(key => Number(key));

    setBehaviors(selectedBehaviors);
    history.push(`/t/new/c/${clusterId}/s/${skillId}/method`);
  }

  if (!clusterId) {
    return null;
  }

  const selectedCluster = clusters?.find(cluster => cluster.id == clusterId);
  const selectedSkill = skills?.[clusterId]?.find(skill => skill.id == skillId);

  const formData = watch();
  const isValid = Object.keys(formData).filter(key => formData[key]).length > 0;

  return (
    <Layout color={selectedCluster?.color}>
      <div className={s.HeaderWrapper}>
        <div className={s.Header}>
          <Link to={`/t/new/c/${clusterId}/skills`}>
            <ArrowRight style={{ transform: 'rotate(180deg)' }} />
          </Link>

          <h1 className={s.Label}>{selectedSkill?.name}</h1>

          <div></div>
        </div>
      </div>

      <div className={s.ProgressContainer}>
        <TrainingProgress indexActive={2} />
      </div>

      <div className={s.Subtitle}>
        <Trans id="Trainings.Behaviors.choose" />
      </div>

      <form className={s.List}>
        {behaviors && skills && clusters ? (
          <>
            {behaviors.map(behavior => (
              <InputItem
                key={behavior.id}
                getInputProps={() => register(`${behavior.id}`)}
                color={selectedCluster.color}
                {...behavior}
              />
            ))}
            <div className="centering">
              <MainButton
                type="submit"
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid}
              >
                <Trans id="Trainings.Behaviors.continue" />
              </MainButton>
            </div>
          </>
        ) : (
          new Array(4).fill(null).map((_, index) => (
            <div key={index}>
              <div>
                <Card className={s.Card}>
                  <div
                    className={s.CardHeader}
                    style={{ height: '40px' }}
                  ></div>
                  <div className={s.CardBody}>
                    <ContentLoader
                      speed={2}
                      width={'100%'}
                      height={20}
                      viewBox="0 0 300 20"
                      backgroundColor="#f3f3f3"
                      foregroundColor="#ecebeb"
                    >
                      <rect x="18" y="8" rx="3" ry="3" width="260" height="6" />
                    </ContentLoader>
                  </div>
                </Card>
              </div>
            </div>
          ))
        )}
      </form>
    </Layout>
  );
}

function Layout({ children, color }) {
  return (
    <>
      <Header />
      <div className={s.Container}>
        <HalfContainer
          height="auto"
          className={s.HalfContainer}
          style={{
            background: `linear-gradient(0deg, #fff 60%, ${
              color || '#B4DDDB'
            } 40%)`
          }}
        >
          {children}
        </HalfContainer>
      </div>
    </>
  );
}

function InputItem({ id, name, color, getInputProps }) {
  return (
    <Card
      className={merge(s.Card, s.CardInput)}
      style={{ '--bullet-color': color }}
    >
      <label htmlFor={`${name}-${id}`}>{name}</label>
      <input
        name={name}
        type="checkbox"
        id={`${name}-${id}`}
        {...getInputProps()}
      />
    </Card>
  );
}
