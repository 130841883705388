import React from 'react';

function Edit(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18.591}
      height={18.726}
      viewBox="0 0 18.591 18.726"
      {...props}
    >
      <g data-name={1827951} fill="#363636" stroke="#fff" strokeWidth={0.3}>
        <path
          data-name="Path 312"
          d="M13.296 18.576H2.25a2.1 2.1 0 01-2.1-2.1V5.43a2.1 2.1 0 012.1-2.1h6.474a.572.572 0 110 1.143H2.25a.954.954 0 00-.953.953v11.055a.954.954 0 00.953.953h11.046a.954.954 0 00.953-.953v-6.478a.572.572 0 011.143 0v6.478a2.1 2.1 0 01-2.096 2.095zm0 0"
        />
        <path
          data-name="Path 313"
          d="M6.835 12.463a.572.572 0 01-.56-.684l.544-2.691a.57.57 0 01.156-.292L14.864.9a2.1 2.1 0 112.963 2.963l-7.892 7.893a.568.568 0 01-.292.156l-2.694.539a.563.563 0 01-.114.012zm2.695-1.11h0zM7.901 9.478l-.336 1.684 1.684-.337 7.771-7.77a.953.953 0 10-1.347-1.347zm0 0"
        />
        <path
          data-name="Path 314"
          d="M16.344 5.109a.567.567 0 01-.4-.168l-2.155-2.156a.572.572 0 01.809-.809l2.155 2.156a.572.572 0 01-.4.976zm0 0"
        />
      </g>
    </svg>
  );
}

export default Edit;
