import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// import useAuth from '../../auth/useAuth';

import TrainingsList from './TrainingsList';
import TrainingDetails from './TrainingDetails';

export default function Trainings() {
  // const { user } = useAuth();

  return (
    <Switch>
      <Route path={`/trainings`} exact>
        <TrainingsList type="ACTIVE" />
      </Route>

      <Route path={`/trainings/completed`}>
        <TrainingsList type="COMPLETED" />
      </Route>

      <Route path="/trainings/new">
        <div>New training form</div>
      </Route>

      <Route path="/trainings/details/:trainingId">
        <TrainingDetails />
      </Route>

      <Route path="*">
        <Redirect to="/trainings" />
      </Route>
    </Switch>
  );
}
