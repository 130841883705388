import React from 'react';

import Modal from '@common/Modal';
import TrainingCalendar from '@new-training/TrainingCalendar';
import CloseIcon from '@common/Icons/CloseIcon';

import { Trans } from '@lingui/react';

import s from './TrainingDatesModal.module.css';

export default function TrainingDatesModal(props) {
  const {
    openModal,
    closeModal,
    onConfirmDate,
    selectedDate,
    isUpdatingDate = false
  } = props;

  return (
    <Modal
      isOpen={openModal}
      onClose={closeModal}
      className={s.TrainingDatesModal}
      hideOnClickOutside={false}
    >
      <div className={s.ModalHeader}>
        <div className={s.TitleWrapper}>
          <div className={s.Title}>
            <Trans id="Trainings.Dates.duration" />
          </div>

          <div className={s.Subtitle}>
            <Trans id="Trainings.Dates.ranges" />
          </div>
        </div>

        <CloseIcon style={{ cursor: 'pointer' }} onClick={closeModal} />
      </div>

      <div className={s.ModalBody}>
        <TrainingCalendar
          onConfirmDate={onConfirmDate}
          selectedDate={selectedDate}
          isUpdatingDate={isUpdatingDate}
        />
      </div>
    </Modal>
  );
}
