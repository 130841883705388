import React from 'react';

function Calendar(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22.63}
      height={22.62}
      viewBox="0 0 22.63 22.62"
      {...props}
    >
      <g data-name={2278049}>
        <path
          data-name="Path 318"
          d="M18.107 1.949h-.825v-.835a.864.864 0 00-1.728 0v.835H7.076v-.835a.864.864 0 00-1.726 0v.835h-.827A4.277 4.277 0 00.25 6.222v11.875a4.277 4.277 0 004.273 4.273h13.584a4.277 4.277 0 004.273-4.273V6.222a4.277 4.277 0 00-4.273-4.273zM4.523 3.677h.827v1.685a.864.864 0 001.728 0V3.677h8.472v1.685a.864.864 0 001.728 0V3.677h.825a2.547 2.547 0 012.547 2.545v.825H1.978v-.825a2.547 2.547 0 012.545-2.545zm13.584 16.965H4.523a2.547 2.547 0 01-2.544-2.544V8.775H20.65v9.322a2.547 2.547 0 01-2.544 2.544zM7.926 12.174a.864.864 0 01-.864.864h-1.7a.864.864 0 110-1.728h1.7a.864.864 0 01.864.864zm10.206 0a.864.864 0 01-.864.864h-1.7a.864.864 0 110-1.728h1.7a.864.864 0 01.864.864zm-5.108 0a.864.864 0 01-.864.864h-1.7a.864.864 0 010-1.728h1.7a.864.864 0 01.864.864zm-5.1 5.1a.864.864 0 01-.862.862h-1.7a.864.864 0 110-1.728h1.7a.864.864 0 01.864.864zm10.206 0a.864.864 0 01-.864.864h-1.7a.864.864 0 110-1.728h1.7a.864.864 0 01.866.862zm-5.108 0a.864.864 0 01-.862.862h-1.7a.864.864 0 010-1.728h1.7a.864.864 0 01.864.864z"
          fill="#363636"
          stroke="#fff"
          strokeWidth={0.5}
        />
      </g>
    </svg>
  );
}

export default Calendar;
