import * as React from 'react';
import { Link } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

import Header from '@common/Header';
import TrainingProgress from '@new-training/TrainingProgress';
import HalfContainer from '@common/HalfContainer/HalfContainer';
import LinearProgress from '@common/ProgressBar/LinearProgress';
import ArrowRight from '@common/Icons/ArrowRight';
import Card from '@common/Card';

import { Trans } from "@lingui/react";

import s from './ChooseCluster.module.css';

export default function ChooseCluster({ clusters }) {
  return (
    <Layout>
      <div className={s.HeaderWrapper}>
        <div className={s.Header}>
          <Link to="/trainings">
            <ArrowRight style={{ transform: 'rotate(180deg)' }} />
          </Link>

          <h1><Trans id="Trainings.Clusters.header" /></h1>

          <div></div>
        </div>
      </div>

      <div className={s.ProgressContainer}>
        <TrainingProgress indexActive={0} />
      </div>

      <div className={s.Subtitle}><Trans id="Trainings.Clusters.choose" /></div>

      <ul className={s.List}>
        {clusters
          ? clusters.map(cluster => (
              <li key={cluster.id}>
                <Link to={`/t/new/c/${cluster.id}/skills`}>
                  <CardItem {...cluster} />
                </Link>
              </li>
            ))
          : new Array(4).fill(null).map((_, index) => (
              <li key={index}>
                <div>
                  <Card className={s.Card}>
                    <div
                      className={s.CardHeader}
                      style={{ height: '40px' }}
                    ></div>
                    <div className={s.CardBody}>
                      <ContentLoader
                        speed={2}
                        width={'100%'}
                        height={20}
                        viewBox="0 0 300 20"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                      >
                        <rect
                          x="18"
                          y="8"
                          rx="3"
                          ry="3"
                          width="260"
                          height="6"
                        />
                      </ContentLoader>
                    </div>
                  </Card>
                </div>
              </li>
            ))}
      </ul>
    </Layout>
  );
}

function CardItem({ name, value, color }) {
  return (
    <Card className={s.Card}>
      <div className={s.CardHeader}>
        <div>{name}</div>
        <div>
          <ArrowRight />
        </div>
      </div>
      <div className={s.CardBody}>
        <LinearProgress className={s.Progress} color={color} value={value} />
      </div>
    </Card>
  );
}

function Layout({ children }) {
  return (
    <>
      <Header />
      <div className={s.Container}>
        <HalfContainer height="auto" className={s.HalfContainer}>
          {children}
        </HalfContainer>
      </div>
    </>
  );
}
