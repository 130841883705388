import React from 'react';
import { useHistory, useParams, NavLink } from 'react-router-dom';

import faq from './faq-data';
import useMediaQuery from '@common/useMediaQuery';
import useAuth from '@auth/useAuth';

import HalfContainer from '@common/HalfContainer';
import FaqContent from './FaqContent';
import ArrowRightIcon from '@common/Icons/ArrowRight';

import s from './Faq.module.css';
import { Trans } from '@lingui/react';

export default function Faq() {
  const { section } = useParams();
  const history = useHistory();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { user } = useAuth();
  const lang = user?.language.split('-')[0];

  return (
    <HalfContainer className={s.FaqContainer} height="100%">
      <div className={s.FaqTopPanel}>
        <div className={s.TitleWrapper}>
          <ArrowRightIcon
            className={s.IconBack}
            onClick={() => history.push('/')}
          />

          <h1>
            <Trans id="Faq.title" />
          </h1>
        </div>

        <p>
          <Trans id="Faq.introduction" />
        </p>
      </div>

      <div className={s.FaqBottomPanel}>
        <div className={s.FaqSectionColumn}>
          <div className={s.FaqSectionWrapper}>
            {faq[lang].map(f => {
              return (
                <NavLink
                  key={f.section}
                  to={`/faq/${f.section}`}
                  className={s.SectionItem}
                  activeClassName={s.Active}
                >
                  <div className={s.Title}>{f.section}</div>
                  <ArrowRightIcon />
                </NavLink>
              );
            })}
          </div>
        </div>

        <div
          className={s.FaqContentColumn}
          style={{
            position: isMobile ? 'absolute' : 'relative',
            display: isMobile && !section ? 'none' : 'block'
          }}
        >
          <FaqContent lang={lang} />
        </div>
      </div>
    </HalfContainer>
  );
}
