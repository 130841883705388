import * as React from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { mutate } from 'swr';
import { Button } from 'reakit';
import { Trans } from '@lingui/react';
import { i18n } from '@lingui/core';
import merge from 'classnames';

import Card from '@common/Card';
import MainButton from '@common/MainButton';
import SecondaryButton from '@common/SecondaryButton';
import LinearProgress from '@common/ProgressBar/LinearProgress';
import Spinner from '@common/Spinner';
import Stack from '@common/Stack';
import Modal from '@common/Modal';
import ArrowRight from '@common/Icons/ArrowRight';

import useAsyncAction from '@common/useAsyncAction';

import s from './QuestionnaireRecap.module.css';

const LOCATIONS = {
  RECAP: 'RECAP',
  RESULT: 'RESULT'
};

export default function QuestionnaireRecap({
  answers,
  restart,
  submit,
  onSubmitSuccessful,
  title,
  subtitle,
  canShowResult = false,
  color,
  fontColor,
  clusterId,
  clusterName,
  hideFirstRecapPage = false
}) {
  const [showResult, setShowResult] = React.useState(false);

  const saveAnswersAsync = useAsyncAction();

  React.useEffect(() => {
    //in some cases we want to skip the recap and show the result first
    if (hideFirstRecapPage && !saveAnswersAsync.data) {
      saveAnswers();
    }
  }, [hideFirstRecapPage, saveAnswersAsync.data]);

  React.useEffect(() => {
    const res = saveAnswersAsync.data;
    if (res && res.status === 200) {
      if (typeof onSubmitSuccessful === 'function') {
        onSubmitSuccessful();
      }

      if (canShowResult && !hideFirstRecapPage) {
        setShowResult(true);
      }
    }
  }, [saveAnswersAsync.data]);

  function saveAnswers() {
    saveAnswersAsync.run(submit());
  }

  const isLoading = saveAnswersAsync.isLoading;

  const location =
    canShowResult && showResult
      ? LOCATIONS.RESULT
      : hideFirstRecapPage
      ? LOCATIONS.RESULT
      : LOCATIONS.RECAP;

  return (
    <Switch location={{ pathname: location }}>
      <Route path={LOCATIONS.RECAP}>
        <Recap
          title={title}
          answers={answers}
          subtitle={subtitle}
          isLoading={isLoading}
          restart={restart}
          saveAnswers={saveAnswers}
          color={color}
          fontColor={fontColor}
          canShowResult={canShowResult}
        />
      </Route>
      <Route path={LOCATIONS.RESULT}>
        <Result
          title={title}
          color={color}
          fontColor={fontColor}
          clusterId={clusterId}
          clusterName={clusterName}
          value={saveAnswersAsync.data?.data.value}
          hideFirstRecapPage={hideFirstRecapPage}
          restart={restart}
        />
      </Route>
    </Switch>
  );
}

function Recap({
  title,
  answers,
  subtitle,
  isLoading,
  restart,
  saveAnswers,
  color,
  fontColor,
  canShowResult
}) {
  return (
    <div
      className={s.Wrapper}
      style={{
        '--recap-color': color,
        '--recap-font-color': fontColor,
        '--bullet-color': color
      }}
    >
      <h1 className={s.Title}>{title}</h1>

      <Card className={s.Card}>
        <div>
          {canShowResult ? (
            <div>{subtitle}</div>
          ) : (
            <mark className="highlight">{subtitle}</mark>
          )}
        </div>

        <ul className={s.List}>
          {answers.map((answer, idx) => (
            <li key={idx}>{answer.recap}</li>
          ))}
        </ul>
      </Card>

      <div className={s.Bottom}>
        <div className={s.BottomTitle}>
          <Trans id="Questionnaire.Recap.confirm" />
        </div>

        <div className={s.Button}>
          <MainButton onClick={saveAnswers}>
            {isLoading ? (
              <Spinner />
            ) : (
              /*i18n*/ i18n._('Questionnaire.Recap.confirm.yes')
            )}
          </MainButton>
        </div>
        <SecondaryButton
          className={s.SecondaryButton}
          onClick={() => {
            if (!isLoading) {
              restart();
            }
          }}
        >
          <Trans id="Questionnaire.Recap.no" />
        </SecondaryButton>
      </div>
    </div>
  );
}

function Result({
  title,
  color,
  fontColor,
  clusterId,
  clusterName,
  value,
  hideFirstRecapPage,
  restart
}) {
  const history = useHistory();

  React.useEffect(() => {
    if (value) {
      mutate('/clusters/skills');
    }
  }, [value]);

  function goToSkills() {
    history.push(`/styles/${clusterId}/skills`);
  }

  function goToRadar() {
    history.push('/styles');
  }

  if (!value) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <Modal
      isOpen={true}
      className={merge(s.ResultContainer)}
      backdropClassName={s.Backdrop}
      hideOnClickOutside={false}
    >
      <div
        className={merge(s.Wrapper, s.ResultWrapper)}
        style={{
          '--recap-color': color,
          '--recap-font-color': fontColor,
          '--bullet-color': color
        }}
      >
        <Card className={s.Card}>
          <h1 className={merge(s.Title, s.ResultTitle)}>{title}</h1>
          <div className={s.Result}>
            <Trans id="Questionnaire.Recap.overview.result" />
          </div>
          <div className={s.Description}>
            <Trans
              id="Questionnaire.Recap.overview.description"
              values={{ score: (value / 20).toFixed(1) }}
            />
          </div>

          <LinearProgress className={s.Progress} value={value} color={color} />

          {hideFirstRecapPage && (
            <Stack
              className={s.HideSection}
              justifyContent="space-between"
              alignItems="center"
            >
              <div>
                <Trans id="QuestionnaireRecap.repeat">
                  Vuoi ripetere il posizionamento?
                </Trans>
              </div>
              <Button onClick={restart}>
                <Trans id="QuestionnaireRecap.go">Vai</Trans>
                <span>
                  <ArrowRight />
                </span>
              </Button>
            </Stack>
          )}
        </Card>

        <div className={s.Bottom}>
          <div className={s.BottomTitle}>
            <Trans
              id="Questionnaire.Recap.retry"
              values={{ cluster: clusterName }}
            />
          </div>

          <div className={s.Button}>
            <MainButton onClick={goToSkills}>
              <Trans id="Questionnaire.Recap.other" />
            </MainButton>
          </div>
          <SecondaryButton className={s.SecondaryButton} onClick={goToRadar}>
            <Trans id="Questionnaire.Recap.radar" />
          </SecondaryButton>
        </div>
      </div>
    </Modal>
  );
}
