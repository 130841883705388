import * as React from 'react';
import { useForm } from 'react-hook-form';
import { gsap } from 'gsap';

import Card from '@common/Card';

import s from './Deck.module.css';

export default function Deck({ cards, currentQuestion, onSubmit, color }) {
  const cardRef = React.useRef(null);

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset
  } = useForm({ mode: 'onChange' });

  React.useEffect(() => {
    if (isDirty) {
      //wait till input gets color
      //not very clean but no other way
      setTimeout(animateCardOut, 300);
    }
  }, [isDirty]);

  function animateCardOut() {
    cardRef.current.classList.add(s.Moving);
    gsap.to(cardRef.current, {
      rotation: -30,
      x: -1500,
      y: -100,
      onComplete: () => {
        handleSubmit(onSubmit)();
        reset();
      }
    });
  }

  return (
    <div
      className={s.Deck}
      style={{
        '--deck-color': color,
        '--mark-color': color,
        '--mark-text': color ? '#fff' : '',
        '--bullet-color': color ? '#fff' : ''
      }}
    >
      {cards.slice(currentQuestion, currentQuestion + 4).map((card, index) => (
        <Card
          key={`${card.text}-${index}`}
          ref={index === 0 ? cardRef : null}
          className={s.Card}
          style={{
            zIndex: cards.length - index,
            transform: `scale(${(20 - index) / 20}) translateY(${
              30 * index
            }px)`,
            opacity: (10 - index) / 10
          }}
        >
          <div className={s.CardText}>
            <mark className={'highlight'}>{cards[currentQuestion].text}</mark>
          </div>

          {index === 0 ? (
            <form>
              {card.answers.map((answer, answerIndex) => (
                <label
                  key={`${answer.text}-${answerIndex}`}
                  htmlFor={`${card.text}-${answer.text}`}
                  className={s.CardInput}
                >
                  <input
                    {...register(card.id ? `question--${card.id}` : card.text)}
                    type="radio"
                    id={`${card.text}-${answer.text}`}
                    value={answer.text}
                  />

                  <span>{answer.text}</span>
                </label>
              ))}
            </form>
          ) : (
            <div></div>
          )}
        </Card>
      ))}
    </div>
  );
}
