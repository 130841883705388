import React from 'react';
import { useHistory } from 'react-router-dom';

import Avatar from '@common/Icons/Avatar';
import ArrowRightIcon from '@common/Icons/ArrowRight';

import s from './TopPanelMobile.module.css';

export default function TopPanelMobile(props) {
  const { user, info, onGoBack } = props;

  const history = useHistory();

  function goBackHandler() {
    if (typeof onGoBack === 'function') {
      onGoBack();
      return;
    }

    history.goBack();
  }

  return (
    <div className={s.FeedbacksTopPanel}>
      <div className={s.TitleWrapper}>
        <ArrowRightIcon className={s.IconBack} onClick={goBackHandler} />

        <div className={s.UserWrapper}>
          <div className={s.ImageWrapper}>
            {user?.image ? (
              <img src={user?.image} alt={user?.name} />
            ) : (
              <Avatar className={s.IconAvatar} />
            )}
          </div>

          <div className={s.Title}>{user?.name}</div>
        </div>
      </div>

      {info && <p>{info}</p>}
    </div>
  );
}
