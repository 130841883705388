import * as React from 'react';
import { Button } from 'reakit/Button';
import { useDayzed } from 'dayzed';
import {
  isBefore,
  isAfter,
  isWithinInterval,
  isDate,
  parseISO
} from 'date-fns';
import { Trans } from '@lingui/react';

import { monthNamesShort, weekdayNamesShort } from '@common/utilities';
import useI18n from '@common/useI18n';

import MainButton from '@common/MainButton';
import Spinner from '@common/Spinner';
import MinorIcon from '@common/Icons/Minor';

import merge from 'classnames';
import s from './TrainingCalendar.module.css';

export default function TrainingCalendar(props) {
  const { selectedDate, onConfirmDate, isUpdatingDate } = props;
  const { i18n: lingui } = useI18n();

  const [localDates, setLocalDates] = React.useState(() => {
    const savedDates = selectedDate;

    if (savedDates) {
      if (savedDates.length === 1) {
        const returnDate = savedDates[0];
        return isDate(returnDate) ? returnDate : parseISO(savedDates[0]);
      }

      return savedDates.map(date => (isDate(date) ? date : parseISO(date)));
    }

    return null;
  });

  const { calendars, getBackProps, getForwardProps, getDateProps } = useDayzed({
    selected: localDates,
    onDateSelected,
    minDate: new Date()
  });

  React.useEffect(() => {
    setLocalDates(selectedDate);
  }, [selectedDate]);

  function onDateSelected({ date }, e) {
    if (!localDates) {
      setLocalDates([new Date(), date]);

      return;
    }

    const firstSelectedDate = localDates[0];
    if (isBefore(date, firstSelectedDate)) {
      return;
    }

    setLocalDates(savedDate => [savedDate[0], date]);
  }

  return (
    <>
      <div className={s.Week}>
        {weekdayNamesShort[lingui.locale].map(weekday => (
          <div
            key={`${calendars.month}${calendars.year}${weekday}`}
            className={merge(s.Day, s.WeekName)}
          >
            {weekday}
          </div>
        ))}
      </div>
      <div className={s.CalendarControl}>
        <Button {...getBackProps({ calendars })} className={s.ControlButton}>
          <MinorIcon className={s.IconMinor} />
        </Button>

        <div className="font-bold">
          {monthNamesShort[lingui.locale][calendars[0].month]}{' '}
          {calendars[0].year}
        </div>

        <Button
          {...getForwardProps({ calendars })}
          className={merge(s.ControlButton, s.Next)}
        >
          <MinorIcon className={s.IconMinor} />
        </Button>
      </div>

      {calendars &&
        calendars[0].weeks.map((week, weekIndex) => (
          <div key={weekIndex} className={s.Week}>
            {week.map((dateObj, dateIndex) => {
              const { date, selectable, selected, today } = dateObj;

              if (!date) {
                return <div key={dateIndex} className={s.Day}></div>;
              }

              const isDateBetween =
                localDates?.[0] &&
                localDates?.[1] &&
                isWithinInterval(date, {
                  start: isDate(localDates[0])
                    ? localDates[0]
                    : parseISO(localDates[0]),
                  end: isDate(localDates[1])
                    ? localDates[1]
                    : parseISO(localDates[1])
                });

              return (
                <Button
                  key={dateIndex}
                  className={
                    selectable || today ? merge(s.Day) : merge(s.Day, s.Ghost)
                  }
                  {...getDateProps({ dateObj })}
                  disabled={today ? false : !selectable}
                >
                  <span
                    className={
                      selected
                        ? merge(s.DayNumber, s.Selected)
                        : isDateBetween
                        ? merge(s.DayNumber, s.Within)
                        : s.DayNumber
                    }
                  >
                    {date.getDate()}
                  </span>
                </Button>
              );
            })}
          </div>
        ))}

      <MainButton
        className={s.BtnModalConfirm}
        onClick={() => onConfirmDate(localDates)}
        disabled={localDates === null || localDates?.length < 2}
      >
        {isUpdatingDate ? <Spinner /> : <Trans id="Trainings.Dates.confirm" />}
      </MainButton>
    </>
  );
}
