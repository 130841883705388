import * as React from 'react';
import useSWR, { useSWRInfinite } from 'swr';
import flatten from 'just-flatten-it';

import config from '@common/config';
import { getAxios } from './utilities';

const { notificationItems, notificationPollingInterval } = config;

export default function useNotifications({
  fireOnMount = true,
  refreshInterval
} = {}) {
  const {
    data: notificationPages,
    error: fetchNotificationsError,
    setSize,
    isValidating,
    mutate: mutateNotificationPages
  } = useSWRInfinite(getKey, fetcher, {
    fireOnMount,
    shouldRetryOnError: false,
    revalidateOnFocus: true
  });

  const lastId = getFirstNotificationId(notificationPages);

  const {
    data: newNotificationsObject,
    error: fetchNewNotificationsError,
    mutate: mutateNewNotifications
  } = useSWR(
    notificationPages ? ['/users/me/notifications/latest', lastId] : null,
    (url, lastId) => {
      return fetcher(`${url}?id=${lastId}&items=${notificationItems}`);
    },
    {
      fireOnMount,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      refreshInterval:
        refreshInterval === undefined
          ? notificationPollingInterval
          : refreshInterval
    }
  );

  const notifications = React.useMemo(() => {
    if (notificationPages && notificationPages.length > 1) {
      const previousPage = notificationPages[notificationPages.length - 2];
      const notificationsLength = previousPage.notifications.length;
      //last id of prev page
      const lastId = previousPage.notifications[notificationsLength - 1]?.id;
      //first id of last page
      const firstId =
        notificationPages[notificationPages.length - 1].notifications?.[0]?.id;
      //they could be the same in case creation date is identical
      //in that case filter the first one
      if (lastId && lastId === firstId) {
        let shouldStop = false;
        return flatten(
          notificationPages.map(noti => noti.notifications)
        ).filter(noti => {
          if (noti.id === lastId && !shouldStop) {
            shouldStop = true;
            return false;
          }

          return noti;
        });
      }
    }

    if (notificationPages) {
      return flatten(notificationPages.map(noti => noti.notifications));
    }

    return null;
  }, [notificationPages]);

  const newNotifications = React.useMemo(() => {
    if (lastId && newNotificationsObject) {
      const lastNNotifications = notifications.slice(0, notificationItems);
      const lastIdsMap = new Map(
        lastNNotifications.map(item => [item.id, true])
      );
      return newNotificationsObject.notifications.filter(
        item => !lastIdsMap.has(item.id)
      );
    }

    return null;
  }, [lastId, newNotificationsObject]);

  const hasMore = React.useMemo(() => {
    if (notificationPages) {
      return getHasMore(notificationPages);
    }

    return null;
  }, [getHasMore, notificationPages]);

  function loadMore() {
    const hasMore = getHasMore(notificationPages);

    if (hasMore) {
      return setSize(size => size + 1);
    }
  }

  function setMultipleReads(notificationIds) {
    if (!Array.isArray(notificationIds)) {
      console.error('notifications is not an array');
      return;
    }

    //converting array to map
    const notificationsMap = {};
    notificationIds.forEach(notId => {
      notificationsMap[notId] = true;
    });

    const readNewNotifications = {
      count: newNotificationsObject.count - notificationIds.length,
      notifications: newNotificationsObject.notifications.map(not =>
        notificationsMap[not.id] ? { ...not, read: true } : not
      )
    };
    mutateNewNotifications(readNewNotifications, false);

    const readNotifications = notificationPages.map(notPage => ({
      count: notPage.count - notificationIds.length,
      notifications: notPage.notifications.map(not =>
        notificationsMap[not.id] ? { ...not, read: true } : not
      )
    }));
    mutateNotificationPages(readNotifications, false);

    const axios = getAxios();
    notificationIds.forEach(notificationId => {
      axios.patch(`/users/me/notifications/read`, { id: [notificationId] });
    });
  }

  function setRead(notificationId) {
    const readNewNotifications = {
      count: newNotificationsObject.count - 1,
      notifications: newNotificationsObject.notifications.map(not =>
        not.id === notificationId ? { ...not, read: true } : not
      )
    };
    mutateNewNotifications(readNewNotifications, false);

    const readNotifications = notificationPages.map(notPage => ({
      count: notPage.count - 1,
      notifications: notPage.notifications.map(not =>
        not.id === notificationId ? { ...not, read: true } : not
      )
    }));
    mutateNotificationPages(readNotifications, false);

    const axios = getAxios();
    axios.patch(`/users/me/notifications/read`, { id: [notificationId] });
  }

  const unreadNotificationsCount = newNotificationsObject?.count || 0;
  const allNotifications = newNotifications
    ? newNotifications.concat(notifications)
    : [];

  return {
    loadMore,
    allNotifications,
    unreadNotificationsCount,
    setRead,
    setMultipleReads,
    isValidating,
    hasMore
  };
}

async function fetcher(url) {
  const axios = getAxios();
  const response = await axios.get(url);

  if ([401, 403].includes(response.status)) {
    throw new Error('UNAUTH');
  }

  return response.data;
}

function getKey(index, previousData) {
  if (
    previousData &&
    previousData.notifications &&
    !previousData.notifications.length
  )
    return null;

  if (index === 0) {
    return `/users/me/notifications?items=${config.notificationItems}`;
  }

  const notificationsLength = previousData.notifications.length;
  const lastId = previousData.notifications[notificationsLength - 1].id;

  return `/users/me/notifications?id=${lastId}&items=${config.notificationItems}`;
}

function getFirstNotificationId(notifications) {
  if (!notifications) {
    return null;
  }

  const firstPage = notifications[0];
  if (!firstPage) {
    return null;
  }

  const nots = firstPage.notifications;
  if (nots.length === 0) {
    return null;
  }

  return nots[0]?.id;
}

function getHasMore(notificationPages) {
  if (notificationPages && Array.isArray(notificationPages)) {
    const notificationsPagesLength = notificationPages.length;

    if (
      notificationPages[notificationsPagesLength - 1] &&
      notificationPages[notificationsPagesLength - 1].notifications.length >= 0
    ) {
      const notificationsLength =
        notificationPages[notificationsPagesLength - 1].notifications.length;

      if (notificationsLength === 0) {
        return false;
      }

      if (notificationsLength === 1 && notificationsPagesLength > 1) {
        const lastId =
          notificationPages[notificationsPagesLength - 1].notifications[
            notificationsLength - 1
          ].id;
        const prevNotsLength =
          notificationPages[notificationsPagesLength - 2].notifications.length;
        const prevLastId =
          notificationPages[notificationsPagesLength - 2].notifications[
            prevNotsLength - 1
          ].id;

        if (lastId === prevLastId) {
          return false;
        }
      }
    }
  }

  return true;
}
