import React from 'react';

function ArrowRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25.742}
      height={14.496}
      viewBox="0 0 25.742 14.496"
      {...props}
    >
      <path
        data-name={120827}
        d="M18.494.354l-1.12 1.12 4.983 4.983H.25v1.584h22.107l-4.983 4.983 1.12 1.12 6.894-6.894z"
        strokeWidth={0.5}
      />
    </svg>
  );
}

export default ArrowRight;
