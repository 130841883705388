import * as React from 'react';
import { Button } from 'reakit';
import merge from 'classnames';

import s from './SecondaryButton.module.css';

export default function SecondaryButton({ children, className, ...props }) {
  return (
    <Button {...props} className={merge(className, s.Button)}>
      {children}
    </Button>
  );
}
