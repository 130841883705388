import React from 'react';
import { Trans } from '@lingui/react';
import merge from 'classnames';

import s from './SelectedBehaviorsBar.module.css';

export default function SelectedBehaviorsBar({
  className,
  behaviors,
  cluster
}) {
  return (
    <div
      className={merge(s.SelectedBehaviourContainer, className)}
      style={{ '--cluster-color': cluster?.color }}
    >
      <div className={s.Subtitle}>
        <Trans id="Trainings.Behaviors.available" />
      </div>

      <ul className={s.SelectedBehaviourList}>
        {behaviors?.map((sb, idx) => (
          <li key={`${sb}-${idx}`} className={s.Item}>
            {sb.name}
          </li>
        ))}
      </ul>
    </div>
  );
}
