import * as React from 'react';

function CloseIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={'1em'}
      height={'1em'}
      viewBox="0 0 19.871 19.975"
      {...props}
    >
      <path
        data-name={1828778}
        d="M11.664 10.039l7.348-7.348A1.222 1.222 0 0017.284.962L9.935 8.31 2.587.962A1.222 1.222 0 00.859 2.691l7.348 7.348-7.348 7.348a1.223 1.223 0 101.729 1.729l7.347-7.348 7.348 7.348a1.223 1.223 0 101.729-1.729zm0 0"
        stroke="#f7f7f7"
      />
    </svg>
  );
}

export default CloseIcon;
