import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import useResource from '@common/useResource';
import useSkills from '@common/useSkills';
import { trainingMethods as methods } from '@common/utilities';

import Card from '@common/Card';
import HalfContainer from '@common/HalfContainer';
import TrainingProgress from '@new-training/TrainingProgress';
import SelectedBehaviorsBar from '@new-training/SelectedBehaviorsBar';
import Header from '@common/Header';
import MainButton from '@common/MainButton';
import ExampleModal from './ExampleModal';
import ArrowRightIcon from '@common/Icons/ArrowRight';
import InfoIcon from '@common/Icons/InfoIcon';
import { Trans } from '@lingui/react';
import s from './ChooseMethod.module.css';

export default function ChooseMethod({ clusters, savedBehaviors }) {
  const history = useHistory();
  const { clusterId, skillId } = useParams();

  const { data: behaviors } = useResource(`/skills/${skillId}/behaviors`);
  const { skills } = useSkills({ clusters });
  const examplesUrl = clusterId ? `/clusters/${clusterId}/examples` : null;
  const { data: examples, isValidating: isFetchingExamples } =
    useResource(examplesUrl);

  const [openExamplesModal, setOpenExamplesModal] = React.useState(null);

  const selectedCluster = clusters?.find(cluster => cluster.id == clusterId);
  const selectedSkill = skills?.[clusterId]?.find(skill => skill.id == skillId);
  const selectedBehaviors = behaviors?.filter(behavior =>
    savedBehaviors.includes(behavior.id)
  );

  React.useEffect(() => {
    if (!savedBehaviors || savedBehaviors.length < 1) {
      history.push(`/t/new/c/${clusterId}/s/${skillId}/behaviors`);
    }
  }, [savedBehaviors]);

  function onSelectMethod(methodType) {
    history.push(`/t/new/c/${clusterId}/s/${skillId}/m/${methodType}`);
  }

  return (
    <>
      {openExamplesModal && (
        <ExampleModal
          type={openExamplesModal}
          clusterId={clusterId}
          examples={examples}
          isFetching={isFetchingExamples}
          onCloseModal={() => setOpenExamplesModal(null)}
        />
      )}

      <Layout color={selectedCluster?.color}>
        <div className={s.HeaderWrapper}>
          <div className={s.Header}>
            <Link to={`/t/new/c/${clusterId}/s/${skillId}/behaviors`}>
              <ArrowRightIcon style={{ transform: 'rotate(180deg)' }} />
            </Link>

            <h1 className={s.Label}>{selectedSkill?.name}</h1>
          </div>
        </div>

        <div className={s.ProgressContainer}>
          <TrainingProgress indexActive={3} />
        </div>

        {selectedBehaviors?.length > 0 && (
          <SelectedBehaviorsBar
            behaviors={selectedBehaviors}
            cluster={selectedCluster}
          />
        )}

        <div className={s.Subtitle}>
          <Trans id="Trainings.Methods.header" />
        </div>

        <div className={s.ListContainer}>
          {methods.map(m => (
            <Card key={m.type} className={s.Card}>
              <div className={s.CardTitle}>
                <span>
                  <Trans id={m.name}>{m.name}</Trans>
                </span>
              </div>

              <div className={s.CardBody}>
                <Trans id={m.description} />

                <div
                  className={s.ExampleWrapper}
                  onClick={() => setOpenExamplesModal(m.type)}
                >
                  <InfoIcon className={s.IconInfo} />
                  <span>
                    {m.type === 'get_inspired' ? (
                      <Trans id="Trainings.Methods.examples.get_inspired" />
                    ) : (
                      <Trans id="Trainings.Methods.examples" />
                    )}
                  </span>
                </div>
              </div>

              <div className={s.CardFooter}>
                <MainButton
                  className={s.BtnMain}
                  onClick={() => onSelectMethod(m.type)}
                >
                  <Trans id="Trainings.Methods.select" />
                  <ArrowRightIcon className={s.IconArrowRight} />
                </MainButton>
              </div>
            </Card>
          ))}
        </div>

        {/* <div className={s.Tips}>
        <BulbIcon />
        <span>
          <Trans id="Trainings.Methods.hints" />
        </span>
      </div> */}
      </Layout>
    </>
  );
}

function Layout({ children, color }) {
  return (
    <>
      <Header />

      <div className={s.Container}>
        <HalfContainer
          height="auto"
          className={s.HalfContainer}
          style={{
            '--cluster-color': color || '#B4DDDB'
            // background: `linear-gradient(0deg, #fff 60%, ${
            //   color || '#B4DDDB'
            // } 40%)`
          }}
        >
          {children}
        </HalfContainer>
      </div>
    </>
  );
}
