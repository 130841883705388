import * as React from 'react';

function WelcomeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="0.762em"
      height="1em"
      viewBox="0 0 177.165 232.532"
      {...props}
    >
      <g data-name="Group 72">
        <path
          data-name="Path 72"
          d="M34.902 175.576c9.053 14.425 24.409 25.09 43.498 30.712 18.843 5.456 41.42 5.873 59.296-3.827 14.731-7.283 22.61-20.295 24.409-35.748 1.575-13.525-1.51-28.915-8.731-43.97a121.132 121.132 0 00-23.72-33.087c-38.77-38.812-76.39-41.68-91.713-12.722-8.331 14.262-12.31 32.618-13.389 49.99-.845 17.534 1.212 34.089 10.35 48.652z"
          fill="#ffeb7f"
        />
        <g data-name="Group 71">
          <path
            data-name="Path 73"
            d="M95.811 129.321s-36.42-19.094-40.047-31.68-7.69-24.38-8.282-34.516-.955-15.43-2.048-18.36-5.824-8.96-4.973-11.2a5.806 5.806 0 012.604-3.078l6.891 10.647 3.905 9.513 1.199-13.144-5.403-22.213s.301-2.414 2.231-2.638a2.71 2.71 0 012.724 1.376L60.07 31.24l2.838 17.636-.223-16.06-4.896-21.03s.01-5.156 1.962-5.884 3.825 2.887 3.825 2.887l6.315 18.46 1.445 7.896.31-8.733s-1.853-13.928-.63-16.608 2.804-1.805 2.804-1.805l6.205 17.495.23 23.87s1.079-7.79 1.492-9.24a5.917 5.917 0 014.16-4.024c2.719-.95 1.16 8.642 1.16 8.642s2.128 9.693 1.827 12.107-.346 12.07-3.072 19.336c-2.265 6.036.335 12.703 3.371 16.317s18.302 15.783 25.138 16.86c2.609.407-9.228 25.095-18.52 19.96z"
            fill="#fff4ed"
          />
          <g data-name="Group 65">
            <path
              data-name="Path 74"
              d="M52.413 57.16a13.141 13.141 0 002.494-1.598c3.72-2.656 11.456-8.196 19.397-8.477l-.064-1.802c-8.48.303-16.516 6.05-20.377 8.815a19.293 19.293 0 01-2.032 1.365.416.416 0 01.11-.02l.15 1.796a1.326 1.326 0 00.322-.078z"
              fill="#f0af9b"
            />
          </g>
          <g data-name="Group 66">
            <path
              data-name="Path 75"
              d="M58.296 62.659c.075-.044 7.59-4.375 11.924-4.915l-.223-1.789c-4.701.586-12.285 4.959-12.605 5.145z"
              fill="#f0af9b"
            />
          </g>
          <g data-name="Group 67">
            <path
              data-name="Rectangle 16"
              fill="#f0af9b"
              d="M64.444 48.76l-1.802.026-.216-15.148 1.802-.026z"
            />
          </g>
          <g data-name="Group 68">
            <path
              data-name="Path 76"
              d="M71.512 46.562l1.03-12.055.282-6.813-1.8-.074-.281 6.794-1.025 11.995z"
              fill="#f0af9b"
            />
          </g>
          <g data-name="Group 69">
            <path
              data-name="Rectangle 17"
              fill="#f0af9b"
              d="M55.023 54.702l-1.803.052-.187-6.43 1.803-.053z"
            />
          </g>
          <g data-name="Group 70">
            <path
              data-name="Path 77"
              d="M62.998 81.959c.024-.202 2.627-20.232 16.083-27.257a.98.98 0 00.185-.109l-.953-1.52a.877.877 0 01.796.024.824.824 0 01.44.72c.03-.382.629-2.226 1.065-3.574.263-.815.536-1.656.748-2.357l-1.725-.522c-.207.683-.478 1.518-.738 2.324-.307.951-.624 1.925-.84 2.664-.109.374-.204.705-.261.994-5.728 3.16-10.392 8.997-13.512 16.921a51.6 51.6 0 00-3.08 11.472z"
              fill="#f0af9b"
            />
          </g>
        </g>
        <path
          data-name="Path 78"
          d="M95.813 129.32s48.084 41.071 62.29 40.121a4.828 4.828 0 003.502-1.684c1.984-14.512-1.192-30.943-8.985-46.945l-38.292-11.457z"
        />
      </g>
    </svg>
  );
}

export default WelcomeIcon;
