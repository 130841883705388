import * as React from 'react';
import { useHistory } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import { i18n } from '@lingui/core';

import PageLayout from '@common/PageLayout';
import QuestionnaireHeader from '@common/QuestionnaireHeader';
import QuestionnaireRecap from '@common/QuestionnaireRecap';
import useResource from '@common/useResource';
import Deck from '@common/DeckDragAndDrop';

import { getAxios } from '@common/utilities';
import useMediaQuery from '@common/useMediaQuery';
import useQuestionnaire from '@common/useQuestionnaire';

import s from './StyleQuiz.module.css';

export default function StyleQuiz() {
  const { data: clusters, mutate } = useResource(`/clusters`);
  const { data: questions } = useResource(`/clusters/questions`);

  const { questionnaireState, restart, onSubmit } = useQuestionnaire({
    cards: questions
  });

  const history = useHistory();

  function onSubmitSuccessful() {
    history.push('/styles');
  }

  function submitAnswers() {
    const { answers } = questionnaireState;

    const clustersMapValue = {};
    answers.forEach(answersList => {
      answersList.forEach((answer, position) => {
        const cluster = answer.cluster;
        const value = answersList.length - (position + 1) + 1;
        if (clustersMapValue[cluster]) {
          clustersMapValue[cluster] += value;
        } else {
          clustersMapValue[cluster] = value;
        }
      });
    });

    const clustersLength = Object.keys(clustersMapValue).length;
    const newClusters = clusters.map(cluster => {
      const newValue = clustersMapValue[cluster.id];
      return {
        ...cluster,
        value: Math.round((newValue / (clustersLength * answers.length)) * 100)
      };
    });

    const axios = getAxios();
    return axios
      .post('/clusters/progress', {
        clusters: newClusters.map(cluster => ({
          id: cluster.id,
          value: cluster.value
        }))
      })
      .then(res => {
        if (res.status === 200 || res.status === 201) {
          mutate(newClusters, false);
        }

        axios
          .put(`/events/use-cluster-quiz`)
          .catch(error => console.error(error));

        return res;
      });
  }

  function onAnswerSubmit(answers) {
    //answers are submitted in order of importance
    onSubmit({
      ...questionnaireState,
      currentQuestion: questionnaireState.currentQuestion + 1,
      answers: [...questionnaireState.answers, answers]
    });
  }

  if (!questions || !clusters) {
    return (
      <PageLayout>
        <QuizLoader />
      </PageLayout>
    );
  }

  if (questionnaireState.currentQuestion === questions.length) {
    const filteredAnswers = questionnaireState.answers.map(answersList => {
      //answers are in order of importance
      return answersList[0];
    });

    return (
      <PageLayout>
        <div className={s.Wrapper}>
          <QuestionnaireRecap
            submit={submitAnswers}
            restart={restart}
            answers={filteredAnswers}
            onSubmitSuccessful={onSubmitSuccessful}
            title={/*i18n*/ i18n._('Quiz.Styles.position')}
            subtitle={/*i18n*/ i18n._('Quiz.Styles.check')}
          />
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <div className={s.Wrapper}>
        <QuestionnaireHeader
          title={/*i18n*/ i18n._('Quiz.Styles.position')}
          currentQuestion={questionnaireState.currentQuestion}
          cardsLength={questions.length}
          goBackLink={'/styles'}
        />

        <Deck
          onSubmit={onAnswerSubmit}
          cards={questions}
          currentQuestion={questionnaireState.currentQuestion}
        />
      </div>
    </PageLayout>
  );
}

function QuizLoader(props) {
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <ContentLoader
      speed={2}
      width={400}
      height={600}
      viewBox="0 0 400 600"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="48" y="48" rx="3" ry="3" width="200" height="25" />
      <rect x="48" y="88" rx="3" ry="3" width="120" height="15" />
      <rect
        x="48"
        y="116"
        rx="3"
        ry="3"
        width={isMobile ? '300' : '350'}
        height="442"
      />
    </ContentLoader>
  );
}
