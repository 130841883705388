import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useSWR, { cache } from 'swr';
import { i18n } from '@lingui/core';

import useAsync from '@common/useAsync';
import useLocalStorage from '@common/useLocalStorage';

import { messages as itMessages } from '../locales/it/messages';
import { messages as frMessages } from '../locales/fr/messages';
import { messages } from '../locales/en/messages';

import {
  getAxios,
  // getGroupFromSteps,
  getPathFromStepId
} from '@common/utilities';
import config from '@common/config';

export const LOGIN_STATUS = {
  LOADING: 'LOADING',
  LOGGEDIN: 'LOGGEDIN',
  REJECTED: 'REJECTED'
};

function stepsFetcher() {
  const axios = getAxios();
  return axios.get('/users/me/steps');
}

function loggedInUserFetcher() {
  const axios = getAxios();
  return axios.get('/users/me');
}

function logoutFetch() {
  const axios = getAxios();
  return axios.post('/auth/logout');
}

export default function useAuth({ fireOnMount = true, redirect = true } = {}) {
  const location = useLocation();
  const history = useHistory();

  const {
    data: logoutResponse,
    error: logoutError,
    status: logoutStatus,
    run
  } = useAsync();

  const [hasLoggedInBefore, setHasLoggedInBefore] = useLocalStorage(
    'uid',
    null
  );
  //I'm storing the logged in user id in local storage
  //so I can say the user has logged in before
  //if there's no such thing I'll just redirect to login
  //withouth even trying and do the ajax call
  const {
    data: user,
    mutate,
    error,
    isValidating
  } = useSWR(
    '/users/me',
    () => {
      const promises = [loggedInUserFetcher(), stepsFetcher()];
      return Promise.all(promises).then(([resOne, resTwo]) => {
        if ([401, 403].includes(resOne.status)) {
          setHasLoggedInBefore(null);

          const error = new Error('Not authorized!');
          error.status = 403;
          throw error;
        }

        const steps = resTwo.data;
        const user = resOne.data;

        const lang = user.language.split('-')[0];
        const hasAlreadySameLang = i18n.locale === lang;
        const langMap = {
          it: itMessages,
          fr: frMessages,
          en: messages
        };

        if (langMap[lang] && !hasAlreadySameLang)
          i18n.load(lang, langMap[lang]);

        if (!hasAlreadySameLang) i18n.activate(lang);

        const { id } = user;
        setHasLoggedInBefore(id);

        return { ...user, steps };
      });
    },
    {
      initialData: null,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnMount: Boolean(fireOnMount)
    }
  );

  React.useEffect(() => {
    if (user) {
      if (redirect) {
        const { steps } = user;

        // const group = getGroupFromSteps(steps);
        const routePath = getPathFromStepId(steps);

        // #TODO: do this in a proper way
        if (!routePath && ['/login'].includes(location.pathname)) {
          return history.push('/');
        }

        //if firstStep is something I can't recognize
        if (!routePath || routePath === location.pathname) {
          return;
        }

        return history.push(routePath);
      }
    }
  }, [user, location.pathname]);

  React.useEffect(() => {
    if (logoutResponse && logoutResponse.status === 200) {
      cache.clear();
      setHasLoggedInBefore(null);
    }
  }, [logoutResponse]);

  React.useEffect(() => {
    if (error) {
      if (error.status === 403 && redirect) {
        if (config.ssoURL) {
          window.location.href = config.ssoURL;
        } else {
          history.push('/login');
        }
      }
    }
  }, [error]);

  function logout() {
    run(logoutFetch());
  }

  return {
    status: user
      ? LOGIN_STATUS.LOGGEDIN
      : !user && !error
      ? LOGIN_STATUS.LOADING
      : LOGIN_STATUS.REJECTED,
    user,
    mutate,
    isValidating,
    logout,
    logoutStatus,
    logoutResponse,
    logoutError
  };
}
