import React from 'react';
import { i18n } from '@lingui/core';

import MainButton from '@common/MainButton';
import ArrowRightIcon from '@common/Icons/ArrowRight';

import s from './DetailsTopPanel.module.css';

export default function DetailsTopPanel({ title, goBack, onAlertModal }) {
  return (
    <div className={s.DetailsTopPanel}>
      <div className={s.TitleWrapper}>
        <ArrowRightIcon className={s.BtnIconBack} onClick={goBack} />
        <h1 className={s.Title}>{title || 'N/A'}</h1>
      </div>

      <MainButton className={s.BtnDeleteTraining} onClick={onAlertModal}>
        {/*i18n*/ i18n._('DetailsTopPanel.Button.delete')}
      </MainButton>
    </div>
  );
}
