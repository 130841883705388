import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Trans } from '@lingui/react';

import Card from '@common/Card';
import ArrowRight from '@common/Icons/ArrowRight';
import LinearProgress from '@common/ProgressBar/LinearProgress';
import Spinner from '@common/Spinner';

import useResource from '@common/useResource';

import useSkills from '@common/useSkills';

import s from './ClusterSkills.module.css';

export default function ClusterSkills() {
  const { clusterId } = useParams();

  const { data: clusters } = useResource('/clusters');
  const { skills } = useSkills({
    clusters
  });

  const selectedCluster = clusters?.find(cluster => cluster.id == clusterId);

  if (!clusters || !skills) {
    return (
      <div className={s.LoaderContainer}>
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className={s.Container}
      style={{
        background: `linear-gradient(0deg, #fff 65%, ${selectedCluster.color} 35%)`
      }}
    >
      <div className={s.Header}>
        <Link className={s.ArrowBtn} to="/styles">
          <ArrowRight style={{ transform: 'rotate(180deg)' }} />
        </Link>

        <h1 className={s.LabelTitle}>{selectedCluster.name}</h1>

        <div className={s.Right}></div>
      </div>

      <div className={s.ContentContainer}>
        <div className={s.Subtitle}>
          <Trans id="Quiz.Skills.selection" />
        </div>
        <ul>
          {skills[selectedCluster.id].map(skill => (
            <li>
              <CardItem
                name={skill.name}
                progress={skill.value}
                color={selectedCluster.color}
                clusterId={selectedCluster.id}
                skillId={skill.id}
                description={skill.description}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function CardItem({ name, progress, color, clusterId, skillId, description }) {
  return (
    <Link className={s.CardLink} to={`/styles/c/${clusterId}/s/${skillId}`}>
      <Card className={s.Card}>
        <div className={s.CardHeader}>
          <div>{name}</div>
          <ArrowRight />
        </div>
        <div className={s.CardContent}>
          <div>{description}</div>

          <LinearProgress
            className={s.ProgressBar}
            color={color}
            value={progress}
          />
        </div>
      </Card>
    </Link>
  );
}
