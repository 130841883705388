import * as React from 'react';
import { useHistory, NavLink, Redirect, Route, Switch } from 'react-router-dom';
import merge from 'classnames';
import { Trans } from '@lingui/react';

import HalfContainer from '@common/HalfContainer';
import ReceivedFeedbacks from './ReceivedFeedbacks';
import ArrowRightIcon from '@common/Icons/ArrowRight';

import s from './Feedbacks.module.css';

export default function Feedbacks() {
  const history = useHistory();

  return (
    <HalfContainer className={s.FeedbacksContainer} height="100%">
      <div className={s.TopPanel}>
        <div className={s.TitleWrapper}>
          <ArrowRightIcon
            className={s.IconBack}
            onClick={() => history.push('/')}
          />

          <h1 className={s.Title}>
            <Trans id="Feedback.check" />
          </h1>
        </div>

        <ul className={s.Nav}>
          <li>
            <NavLink
              to={`/feedbacks/r`}
              activeClassName={s.Active}
              className={merge(s.Link, 'font-size-medium')}
            >
              <Trans id="Feedback.requested" />
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/feedbacks/s`}
              activeClassName={s.Active}
              className={merge(s.Link, 'font-size-medium')}
            >
              <Trans id="Feedback.sent" />
            </NavLink>
          </li>
        </ul>
      </div>

      <Switch>
        <Route path={`/feedbacks/r`}>
          <ReceivedFeedbacks type="RECEIVED" />
        </Route>
        <Route path={`/feedbacks/s`}>
          <ReceivedFeedbacks type="SENT" />
        </Route>
        <Route path={`/feedbacks`}>
          <Redirect to="/feedbacks/r" />
        </Route>
      </Switch>
    </HalfContainer>
  );
}
