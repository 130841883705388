import React from 'react';
import { i18n } from '@lingui/core';
import {
  DndContext,
  closestCenter,
  TouchSensor,
  MouseSensor,
  useSensor,
  useSensors,
  KeyboardSensor
} from '@dnd-kit/core';
import {
  useSortable,
  SortableContext,
  sortableKeyboardCoordinates
} from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import { Trans } from '@lingui/react';
import { Button } from 'reakit';

import Card from '@common/Card';
import MainButton from '@common/MainButton';
import PlusIcon from '@common/Icons/Plus';
import EditIcon from '@common/Icons/Edit';
import HamburgerIcon from '@common/Icons/Hamburger';
import Spinner from '@common/Spinner';

import s from './BackPlanningDetailsConfig.module.css';

export default function BackPlanningDetailsConfig(props) {
  const {
    isCompleted,
    configuration,
    onSort,
    onConfigChange,
    onConfigStatusUpdate,
    onSave,
    isProcessing,
    onDeleteRow
  } = props;

  const inputRef = React.useRef(null);

  const [addNew, setAddNew] = React.useState(false);
  const [editIdx, setEditIdx] = React.useState(null);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10
      }
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  React.useEffect(() => {
    if (configuration.length > 0) return;

    setAddNew(true);
  }, []);

  React.useEffect(() => {
    if (!inputRef.current) return;

    if (addNew) {
      inputRef.current.value = '';
    }

    inputRef.current.focus();
  }, [addNew, editIdx]);

  React.useEffect(() => {
    if (editIdx || editIdx === 0) {
      setEditIdx(null);
      return;
    }

    if (!inputRef.current) return;
    setAddNew(false);
  }, [configuration]);

  function addClickHandler() {
    if (editIdx || editIdx === 0) {
      setEditIdx(null);
    }

    setAddNew(true);
  }

  function editClickHandler(idx) {
    if (addNew) {
      setAddNew(false);
    }

    setEditIdx(idx);
  }

  function getIndex(value) {
    return value.split('-').slice(-1)[0];
  }

  function DragEndHandler(event) {
    const { active, over } = event;

    if (over) {
      const activeIdx = getIndex(active.id);
      const overIdx = getIndex(over.id);

      if (activeIdx !== overIdx) {
        onSort(activeIdx, overIdx);
      }
    }
  }

  function onDelete(idx) {
    onDeleteRow(idx);
  }

  return (
    <Card className={s.BackPlanningDetailsCard}>
      {!isCompleted && (
        <div className={s.ButtonWrapper}>
          <MainButton className={s.BtnSaveConfig} onClick={onSave}>
            {isProcessing ? (
              <Spinner />
            ) : (
              <Trans id="TrainingDetails.Button.save_configuration" />
            )}
          </MainButton>
        </div>
      )}

      <div className={s.ConfigurationContainer}>
        <div className={s.ConfigurationHeader}>
          <div className="font-bold">
            <Trans id="Trainings.BackPlanning.actions">Azioni</Trans>
          </div>

          {!isCompleted && (
            <div
              className={s.PlusIconWrapper}
              role="button"
              onClick={addClickHandler}
            >
              <PlusIcon />
            </div>
          )}
        </div>

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={DragEndHandler}
          modifiers={[restrictToParentElement]}
        >
          <SortableContext
            items={[
              ...configuration?.map((conf, idx) => `${conf.name}-${idx}`)
            ]}
          >
            <div className={s.ListsContainer}>
              {configuration.map((conf, idx) => (
                <React.Fragment key={`${conf.name}-${idx}`}>
                  {editIdx === idx ? (
                    <input
                      ref={inputRef}
                      key={`${conf.name}-${idx}`}
                      type="text"
                      className={s.EditInput}
                      defaultValue={conf.name}
                      onKeyPress={ev =>
                        onConfigChange({ ev, mode: 'update', editIdx })
                      }
                      onBlur={ev =>
                        onConfigChange({ ev, mode: 'update', editIdx })
                      }
                    />
                  ) : (
                    <SortableCard
                      key={`${conf.name}-${idx}`}
                      index={idx}
                      item={conf}
                      isCompleted={isCompleted}
                      disabled={editIdx || editIdx === 0}
                      onEdit={() => editClickHandler(idx)}
                      onDelete={() => onDelete(idx)}
                      onStatusUpdate={ev => onConfigStatusUpdate(ev, idx)}
                    />
                  )}
                </React.Fragment>
              ))}

              {addNew && (
                <input
                  ref={inputRef}
                  type="text"
                  placeholder={
                    /*i18n*/ i18n._(
                      'BackplanningDetailsConfig.action.placeholder'
                    )
                  }
                  onKeyPress={ev =>
                    onConfigChange({ ev, mode: 'add', editIdx })
                  }
                  onBlur={ev => onConfigChange({ ev, mode: 'add', editIdx })}
                />
              )}
            </div>
          </SortableContext>
        </DndContext>
      </div>
    </Card>
  );
}

function SortableCard(props) {
  const {
    index,
    item,
    isCompleted,
    disabled,
    onEdit,
    onStatusUpdate,
    onDelete
  } = props;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: `${item.name}-${index}`,
      disabled
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div className={s.ListItemWrapper} ref={setNodeRef} style={style}>
      <div className={s.ListItemContainer}>
        <div className={s.ListItem}>
          <div className={s.RadioWrapper}>
            <input
              name={item.name}
              type="checkbox"
              value={item.name}
              id={`${item.name}-${index}`}
              checked={item.completed}
              onChange={onStatusUpdate}
              disabled={isCompleted}
            />
            <label htmlFor={`${item.name}-${index}`}>{item.name}</label>
          </div>

          {!isCompleted && (
            <EditIcon style={{ cursor: 'pointer' }} onClick={onEdit} />
          )}
        </div>

        {!isCompleted && (
          <div className={s.DeleteBtn}>
            <Button onClick={() => onDelete()}>
              <Trans id="TrainingDetails.Alert.Delete.Button.confirm">
                Elimina
              </Trans>
            </Button>
          </div>
        )}
      </div>

      {!isCompleted && (
        <button className={s.DragHandle} {...attributes} {...listeners}>
          <HamburgerIcon />
        </button>
      )}
    </div>
  );
}
