import React from 'react';
import useSWR from 'swr';
import { Button } from 'reakit';
import ContentLoader from 'react-content-loader';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/react';
import HalfContainer from '@common/HalfContainer';
import Card from '@common/Card';
import MainButton from '@common/MainButton';
import ArrowRightIcon from '@common/Icons/ArrowRight';
import CameraIcon from '@common/Icons/Camera';
import Avatar from '@common/Icons/Avatar';
import Modal from '@common/Modal';
import ChangeAvatarMenu from './ChangeAvatarMenu';
import ChangeAvatar from './ChangeAvatar';

import useAuth from '@auth/useAuth';
import useResource from '@common/useResource';
import { getAxios } from '@common/utilities';
import useMediaQuery from '@common/useMediaQuery';

import merge from 'classnames';
import styles from './Profile.module.css';

export default function Profile() {
  const history = useHistory();
  const { user, mutate, logout, logoutResponse, logoutError } = useAuth();

  const [openModal, setOpenModal] = React.useState('');

  const {
    data: summary,
    error,
    isValidating
  } = useResource('/users/me/profiling');

  React.useEffect(() => {
    if (logoutResponse && logoutResponse.status === 200) {
      history.push('/logout');
    }
  }, [logoutResponse]);

  const closeModalHandler = () => {
    setOpenModal('');
  };

  const removePhotoHandler = async () => {
    const axios = getAxios();

    try {
      await axios.delete('/users/me/photo');

      mutate();
      closeModalHandler();
      toast.success('La foto è stata rimossa');
    } catch (error) {
      toast.error("Oops c'è stato un errore");
    }
  };

  function goToProfileQuiz() {
    history.push('/introduction?goToQuest=true');
  }

  const fullName = `${user?.firstName} ${user?.lastName}`;

  return (
    <>
      <Modal
        isOpen={openModal}
        onClose={closeModalHandler}
        className={styles.ChangeAvatarMenuModal}
      >
        {openModal && openModal === 'menu-change-avatar' && (
          <ChangeAvatarMenu
            onSelectPhoto={() => setOpenModal('change-avatar')}
            onRemovePhoto={removePhotoHandler}
            onCancel={closeModalHandler}
          />
        )}

        {openModal && openModal === 'change-avatar' && (
          <ChangeAvatar
            onCloseModal={closeModalHandler}
            onChangeComplete={mutate}
          />
        )}
      </Modal>

      <HalfContainer className={styles.ProfileContainer} height="100%">
        <div className={styles.UserContainer}>
          <div className={styles.AvatarWrapper}>
            {!user?.photo ? (
              <Avatar className={styles.AvatarIcon} />
            ) : (
              <img
                src={user?.photo}
                alt={fullName}
                className={styles.UserAvatar}
              />
            )}

            <div
              className={styles.ChangePhoto}
              role="button"
              onClick={() => setOpenModal('menu-change-avatar')}
            >
              <CameraIcon />
            </div>
          </div>

          <div>
            <h1>{fullName || ''}</h1>
            <Button onClick={logout}>Logout</Button>
          </div>
        </div>

        <div className={styles.CardContainer}>
          <Card className={merge(styles.Card, styles.TodayCard)}>
            <p className="font-bold">
              <Trans id="Profile.today" />
            </p>

            {!summary && isValidating && <Skeleton />}

            {summary ? (
              <ul>
                {summary.map(s => (
                  <li key={`${s.source}-${s.id}`}>{s.text || 'N/A'}</li>
                ))}
              </ul>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <Trans id="Profile.nosummary" />
              </div>
            )}
          </Card>

          <Card className={merge(styles.Card, styles.RifaiTestCard)}>
            <div className={styles.TextContainer}>
              <p className="font-bold">
                <Trans id="Profile.changed" />
              </p>
              <p>
                <Trans id="Profile.redo.extended" />
              </p>
            </div>

            <MainButton
              className={styles.BtnRifaiTest}
              onClick={goToProfileQuiz}
            >
              <Trans id="Profile.redo" /> <ArrowRightIcon />
            </MainButton>
          </Card>
        </div>
      </HalfContainer>
    </>
  );
}

const Skeleton = props => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <ContentLoader
      speed={2}
      width={isMobile ? '100%' : 480}
      height={150}
      viewBox={`0 0 ${isMobile ? '100%' : 480} 150`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      {[...Array(4).keys()].map(cl => {
        return (
          <React.Fragment key={cl}>
            <circle cx="7" cy={38 * cl + 9} r="7" />
            <rect x="25" y={38 * cl} rx="3" ry="3" width="100%" height="18" />
          </React.Fragment>
        );
      })}
    </ContentLoader>
  );
};
