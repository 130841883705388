import * as React from 'react';

import s from './RadarChart.module.css';

export default function RadarChart({ recharts, data }) {
  const {
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    ResponsiveContainer,
    Label,
    LabelList
  } = recharts;

  return (
    <ResponsiveContainer aspect={1.26}>
      <RadarChart cx="50%" cy="50%" outerRadius="65%" data={data}>
        <filter id="dropshadow">
          <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
          <feOffset dx="2" dy="2" result="offsetblur" />
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.2" />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <PolarGrid gridType="circle" />
        <PolarAngleAxis dataKey="name" tick={true}></PolarAngleAxis>
        <PolarRadiusAxis domain={[0, 100]} tick={false}></PolarRadiusAxis>
        <Radar
          name="Mike"
          dataKey="value"
          stroke="#B4DDDB"
          fill="#B4DDDB"
          fillOpacity={0.6}
        ></Radar>
      </RadarChart>
    </ResponsiveContainer>
  );
}
