import * as React from 'react';

function Avatar(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={139}
      height={139}
      viewBox="0 0 139 139"
      {...props}
    >
      <defs>
        <filter
          id="avatar"
          x={0}
          y={0}
          width={139}
          height={139}
          filterUnits="userSpaceOnUse"
        >
          <feOffset />
          <feGaussianBlur stdDeviation={5} result="blur" />
          <feFlood floodOpacity={0.122} result="color" />
          <feComposite operator="out" in="SourceGraphic" in2="blur" />
          <feComposite operator="in" in="color" />
          <feComposite operator="in" in2="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Group 845" transform="translate(-123 -348.147)">
        <g data-type="innerShadowGroup">
          <circle
            data-name="Ellipse 38"
            cx={69.5}
            cy={69.5}
            r={69.5}
            transform="translate(123 348.147)"
            fill="#fff"
          />
          <g transform="translate(123 348.15)" filter="url(#avatar)">
            <circle
              data-name="Ellipse 38"
              cx={69.5}
              cy={69.5}
              r={69.5}
              fill="#fff"
            />
          </g>
        </g>
        <circle
          data-name="Ellipse 37"
          cx={51.5}
          cy={51.5}
          r={51.5}
          transform="translate(141 366.147)"
          fill="#e3e3e3"
          opacity={0.768}
        />
        <path
          data-name="Path 254"
          d="M192.5 360.796a56.85 56.85 0 1056.853 56.85 56.853 56.853 0 00-56.853-56.85zm0 17a18.8 18.8 0 11-18.8 18.8 18.806 18.806 0 0118.8-18.8zm-.013 81.838a41.724 41.724 0 01-27.17-10.019 8.013 8.013 0 01-2.812-6.091 18.964 18.964 0 0119.057-18.959h21.885a18.937 18.937 0 0119.023 18.959 7.994 7.994 0 01-2.809 6.088 41.71 41.71 0 01-27.174 10.022z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default Avatar;
