import axios from 'axios';
import { format, isDate, parseISO } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import { i18n } from '@lingui/core';

import config from './config';

export const weekdayNamesShort = {
  it: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
  en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  fr: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
};

export const monthNamesShort = {
  it: [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
  ],
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'Dicember'
  ],
  fr: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Jiullet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre'
  ]
};

export const trainingMethods = [
  {
    type: 'dare',
    name: 'D.A.R.E',
    description: 'TrainingMethod.Dare.description'
  },
  {
    type: 'back-planning',
    name: 'Back planning',
    description: 'TrainingMethod.BackPlanning.description'
  },
  {
    type: 'get_inspired',
    name: 'get_inspired',
    description: 'TrainingMethod.GetInspired.description'
  }
];

export function noop() {
  return;
}

const locales = { enUS, it, fr };
// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export function formatDate(date, formatStr = 'PP', lang) {
  const locale = lang === 'en' ? enUS : lang === 'fr' ? fr : it;
  const _date = isDate(date) ? date : parseISO(date);

  return format(_date, formatStr, {
    locale // or global.__localeId__
  });
}

export function getAxios() {
  const axiosInstance = axios.create({
    baseURL: config.apiVersion,
    withCredentials: true,
    validateStatus: function (status) {
      return status >= 200 && status < 404;
    },
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  });

  return axiosInstance;
}

export function getGroupFromSteps(steps) {
  const groupObject = steps.find(step => step.id === 'groups');

  if (!groupObject) {
    return null;
  }

  const group = groupObject.groups.find(group => group.default == 1);

  return group;
}

export function getPathFromStepId(steps) {
  const { stepsNames } = config;
  //find first step I can recognize
  let firstStep = null;
  let stepsIds = Object.values(stepsNames);

  for (let step of steps) {
    const { id } = step;
    if (stepsIds.includes(id)) {
      firstStep = step;
    }
  }

  // const { id } = firstStep;
  switch (firstStep?.id) {
    case stepsNames.GROUPS: {
      const group = getGroupFromSteps(steps);

      if (!group) {
        return '/no-project';
      }

      if (!Boolean(group.privacy)) {
        return '/privacy';
      }

      return '/';
    }

    case stepsNames.CHANGE_PERSONAL_DATA:
      return '/login/magic/name';

    case stepsNames.PROFILING:
      return '/introduction';

    default:
      return null;
  }
}

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function getIsFirefox() {
  return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
}

export function getIsIE11() {
  return (
    navigator.userAgent.indexOf('MSIE') !== -1 ||
    navigator.appVersion.indexOf('Trident/') > -1
  );
}

export function hexToRgbA(hex, opacity) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      (opacity || '1') +
      ')'
    );
  }
  console.error('Bad Hex');
}
