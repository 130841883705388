import React from 'react';

import MainButton from '@common/MainButton';

import s from './ChangeAvatarMenu.module.css';
import { Trans } from "@lingui/react";

export default function ChangeAvatarMenu(props) {
  const { onSelectPhoto, onRemovePhoto, onCancel } = props;

  return (
    <div className={s.ChangeAvatarMenuContainer}>
      <h2><Trans id="Avatar.Change.Menu.change" /></h2>

      <div className={s.MenuWrapper}>
        <div className={s.MenuItem} role="button" onClick={onRemovePhoto}>
          <Trans id="Avatar.Change.Menu.remove" />
        </div>
        <div className={s.MenuItem} role="button" onClick={onSelectPhoto}>
          <Trans id="Avatar.Change.Menu.gallery" />
        </div>
      </div>

      <MainButton className={s.BtnCancel} onClick={onCancel}>
        <Trans id="Avatar.Change.Menu.cancel" />
      </MainButton>
    </div>
  );
}
