import * as React from 'react';
import { useParams } from 'react-router-dom';
import { i18n } from '@lingui/core';

import { formatDate } from '@common/utilities';
import useMediaQuery from '@common/useMediaQuery';
import useI18n from '@common/useI18n';

import CardItem from '../CardItem';
import TopPanelMobile from '../TopPanelMobile';

import s from './FeedbacksPerUser.module.css';

export default function FeedbacksPerUser({
  feedbacks,
  openChat,
  selectedFeedbackId
}) {
  const { userId } = useParams();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { i18n: lingui } = useI18n();

  const feedbacksPerUser = React.useMemo(() => {
    const userFeedbacks = feedbacks.find(item => item.user.id == userId);

    return userFeedbacks;
  }, [userId, feedbacks]);

  const user = feedbacksPerUser?.user || null;
  const userFeedbacks = feedbacksPerUser?.feedbacks || [];

  return (
    <>
      {isMobile && (
        <TopPanelMobile
          user={user}
          info={/*i18n*/ i18n._('Feedback.trainings.received')}
        />
      )}

      <ul className={s.List}>
        {userFeedbacks.map(feedback => (
          <li key={feedback.feedback}>
            <CardItem
              selected={feedback.feedback == selectedFeedbackId}
              className={s.ListItem}
              subtitleClassName={s.Date}
              title={feedback.name}
              subtitle={
                feedback.interacted_at
                  ? formatDate(
                      feedback.interacted_at,
                      'd MMMM yyyy',
                      lingui.locale
                    )
                  : null
              }
              rightNumber={feedback.messages}
              onClick={() => openChat(userId, feedback.feedback)}
            />
          </li>
        ))}
      </ul>
    </>
  );
}
