import * as React from 'react';
// import { useParams } from 'react-router-dom';
import { Dialog, useDialogState } from 'reakit';

import styles from './FeedbackPerUserModal.module.css';

export default function FeedbackPerUserModal(props) {
  const { children, feedbacks, ...rest } = props;

  const dialogState = useDialogState({ visible: true });

  // const { userId } = useParams();

  // const user = React.useMemo(() => {
  //   const item = feedbacks.find(item => item.user.id == userId);
  //   return item.user;
  // }, [userId, feedbacks]);

  return (
    <Dialog className={styles.Dialog} {...rest} {...dialogState}>
      <div className={styles.Body}>{children}</div>
    </Dialog>
  );
}
