import * as React from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useHistory } from 'react-router';
import { Trans } from '@lingui/react';
import { use100vh } from 'react-div-100vh';
import { Button, Dialog, DialogBackdrop, useDialogState } from 'reakit';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { differenceInMinutes, isToday, isYesterday, parseISO } from 'date-fns';
import merge from 'classnames';

import Stack from '@common/Stack';
import Minor from '@common/Icons/Minor';

import useMediaQuery from '@common/useMediaQuery';
import { formatDate } from '@common/utilities';

import styles from './NotificationModal.module.css';

export default function NotificationModal({
  className,
  isOpen,
  backdropClassName,
  onClose,
  loadMore,
  isRevalidating,
  notifications,
  hasMore,
  onClick
}) {
  const scrollRef = React.useRef(null);

  const isMobile = useMediaQuery('(max-width: 600px)');
  const dialog = useDialogState({ visible: isOpen });
  const height = use100vh();

  React.useEffect(() => {
    //THIS IS TO MAKE SCROLL WORK INSIDE OF MODAL
    //FOR IOS AS WELL
    //MORE INFO HERE https://github.com/reakit/reakit/issues/469
    if (dialog.visible) {
      if (scrollRef.current) {
        disableBodyScroll(scrollRef.current);
      }
    } else {
      if (scrollRef.current) {
        enableBodyScroll(scrollRef.current);
      }
    }

    return () => {
      if (scrollRef.current) {
        enableBodyScroll(scrollRef.current);
      }
    };
  }, [dialog.visible]);

  React.useEffect(() => {
    isOpen ? dialog.show() : dialog.hide();
  }, [isOpen]);

  React.useEffect(() => {
    if (!dialog.visible) {
      onClose();
    }
  }, [dialog.visible]);

  function loadMoreItems() {
    if (isRevalidating) {
      return () => {};
    }

    return loadMore();
  }

  if (!notifications) {
    return (
      <DialogBackdrop {...dialog} className={backdropClassName}>
        <Dialog
          {...dialog}
          className={merge(className, styles.Modal)}
          aria-label="Notification modal"
        ></Dialog>
      </DialogBackdrop>
    );
  }

  return (
    <DialogBackdrop {...dialog} className={backdropClassName}>
      <Dialog {...dialog} className={merge(className, styles.Modal)}>
        <div className={styles.Header}>
          <strong>
            <Trans id="notifications.not">Notifiche</Trans>
          </strong>
          <Button onClick={onClose}>
            <Trans id="notification.close">Chiudi</Trans>
          </Button>
        </div>

        {notifications.length > 0 ? (
          <Virtuoso
            style={{ height: isMobile ? height - 60 : '100%' }}
            data={notifications}
            endReached={loadMoreItems}
            overscan={20}
            scrollerRef={ref => {
              scrollRef.current = ref;
            }}
            itemContent={(index, notification) => (
              <Notification
                index={index}
                notification={notification}
                onClick={onClick}
                close={dialog.hide}
              />
            )}
            components={{
              Footer: () => {
                return !hasMore ? null : (
                  <div
                    style={{
                      padding: '2rem',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    Loading...
                  </div>
                );
              }
            }}
          />
        ) : (
          <div className={styles.NoNotifications}>
            <Trans id="notifications.zero">Non hai ancora notifiche</Trans>
          </div>
        )}
      </Dialog>
    </DialogBackdrop>
  );
}

function Notification({ notification, index, onClick, close }) {
  const history = useHistory();

  function goTo() {
    onClick(notification.id);
    close();

    const [_, url] = notification.metadata.url.split('#');
    history.push(url);
  }

  return (
    <div
      className={
        notification.read
          ? merge(styles.Notification, index === 0 ? styles.First : null)
          : merge(
              styles.Notification,
              styles.Unread,
              index === 0 ? styles.First : null
            )
      }
    >
      <div className={styles.Wrapper}>
        <Stack justifyContent="space-between" alignItems="center">
          <div className={styles.Title}>
            <Trans id="notification.news">Novità</Trans>
          </div>
          <div className={styles.Time}></div>
        </Stack>

        <div className={styles.NotificationText}>{notification.text}</div>

        <Stack justifyContent="flex-end">
          <Button onClick={goTo}>
            <Stack alignItems="center">
              {!notification.read ? (
                <div className={styles.OpenLabel}>
                  <Trans id="notification.open">Apri</Trans>
                </div>
              ) : null}
              <Minor className={styles.OpenIcon} />
            </Stack>
          </Button>
        </Stack>
      </div>
    </div>
  );
}

function getFormattedDate(date) {
  const creationDate = parseISO(date);
  const minutesDifference = differenceInMinutes(new Date(), creationDate);

  if (minutesDifference < 59) {
    //1 minute
    return `${minutesDifference} minuti fa`;
  } else if (isToday(creationDate)) {
    //number of hrs
    return `${Math.round(minutesDifference / 60)} ore fa`;
  } else if (isYesterday(creationDate)) {
    return 'Un giorno fa';
  } else {
    return formatDate(creationDate, 'd/M/y');
  }
}

function getLinkFromNotification(notification) {
  const { metadata } = notification;

  switch (metadata.type) {
    case 'feedback.request': {
      return `/feedbacks/s/${metadata.user}/t/${metadata.tactic}/${metadata.training}`;
    }
    case 'feedback.evaluation': {
      return `/feedbacks/r/${metadata.user}/t/${metadata.tactic}/${metadata.training}`;
    }
    case 'training.reminder': {
      return `/trainings/details/${metadata.group}/${metadata.tactic}/${metadata.serial}`;
    }
    case 'chat.message': {
      if (typeof metadata.coach === 'boolean' && metadata.serial) {
        return `/feedbacks/${metadata.coach ? 's' : 'r'}/${metadata.user}/t/${
          metadata.tactic
        }/${metadata.serial}`;
      }

      return '/';
    }
    default:
      return '/';
  }
}
