import React from 'react';
import { Trans } from '@lingui/react';

import ArrowRightIcon from '@common/Icons/ArrowRight';
import CalendarIcon from '@common/Icons/Calendar';

import merge from 'classnames';
import s from './DetailsDuration.module.css';

export default function DetailsDuration(props) {
  const {
    isCompleted,
    subSectionTitleClass,
    startDate,
    endDate,
    onOpenCalendar
  } = props;

  return (
    <div className={s.DetailsDurationContainer}>
      <div className={subSectionTitleClass}>
        <Trans id="Trainings.Configuration.time">Durata allenamento</Trans>
      </div>

      <div className={merge(s.DetailsCard, s.DurationCard)}>
        <div className={s.DurationWrapper}>
          <CalendarIcon />

          <span>
            {startDate && endDate ? (
              `${startDate} - ${endDate}`
            ) : (
              <Trans id="Trainings.Configuration.time">
                Durata allenamento
              </Trans>
            )}
          </span>
        </div>

        {!isCompleted && (
          <ArrowRightIcon
            className={s.BtnDuration}
            style={{ cursor: 'pointer' }}
            onClick={onOpenCalendar}
          />
        )}
      </div>
    </div>
  );
}
