import * as React from 'react';
import ReactDOM from 'react-dom';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';

import App from './app/App';

import { messages } from './locales/en/messages';
import { messages as itMessages } from './locales/it/messages';

import 'focus-visible';
import 'normalize.css';

import './index.css';

const browserLang = navigator.language;

if (browserLang.includes('en')) {
  i18n.load('en', messages);
  i18n.activate('en');
} else if (browserLang.includes('it')) {
  i18n.load('it', itMessages);
  i18n.activate('it');
} else {
  i18n.load('en', messages);
  i18n.activate('en');
}

if (!import.meta.env.PROD && import.meta.env.VITE_MOCK_API === 'true') {
  import('../mocks/index').then(({ worker }) => {
    worker.start();
    renderApp();
  });
} else {
  renderApp();
}

function renderApp() {
  ReactDOM.render(
    <I18nProvider i18n={i18n}>
      <App />
    </I18nProvider>,
    document.getElementById('root')
  );
}
