import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';

import Header from '@common/Header';
import Navbar from '@common/Navbar';
import Home from '@dashboard/Home';
import Profile from '@dashboard/Profile';
import StyleSection from './user-style/StyleSection';
import ClusterSkills from './user-style/ClusterSkills';
import Trainings from '@dashboard/Trainings';
import Feedbacks from './Feedbacks';
import Faq from './Faq';

import useMediaQuery from '@common/useMediaQuery';
// import useAuth from '@auth/useAuth';

import styles from './Dashboard.module.css';

export default function Dashboard() {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const div100vh = use100vh();
  // const { user } = useAuth({ fireOnMount: false });

  const contentContainerHeight = !isMobile
    ? `calc(${div100vh}px - 73px)`
    : `calc(${div100vh}px - 173px)`;

  return (
    <div className={styles.Container}>
      <Header />

      <div
        className={styles.ContentContainer}
        style={{ height: contentContainerHeight }}
      >
        <Navbar className={styles.Navbar} />

        <div className={styles.ContentPanel}>
          <Switch>
            <Route path="/styles/:clusterId/skills">
              <ClusterSkills />
            </Route>

            <Route path="/styles">
              <StyleSection isMobile={isMobile} />
            </Route>

            <Route path="/" exact>
              <Home />
            </Route>

            <Route path="/profile">
              <Profile />
            </Route>

            <Route path="/trainings">
              <Trainings />
            </Route>

            <Route path="/feedbacks">
              <Feedbacks />
            </Route>

            <Route path="/faq/:section">
              <Faq />
            </Route>
            <Route path="/faq">
              <Faq />
            </Route>
            <Route path="*">
              <div>404</div>
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}
