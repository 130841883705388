import React from 'react';

import s from './GetInspierdConfig.module.css';

export default function GetInspierdConfig(props) {
  const { configuration } = props;
  console.log(configuration);

  return (
    <div className={s.GetInspiredDetailsCard}>
      <div
        dangerouslySetInnerHTML={{ __html: configuration.description }}
      ></div>
    </div>
  );
}
