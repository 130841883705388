import React from 'react';

function Message(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20.323}
      height={18.63}
      viewBox="0 0 20.323 18.63"
      {...props}
    >
      <g data-name={813419}>
        <g data-name="Group 222">
          <g data-name="Group 221" fill="#1c1c1c">
            <path
              data-name="Path 287"
              d="M4.657 5.081h9.311a.423.423 0 000-.847H4.653a.423.423 0 100 .847z"
            />
            <path
              data-name="Path 288"
              d="M9.738 9.315H4.657a.423.423 0 100 .847h5.081a.423.423 0 000-.847z"
            />
            <path
              data-name="Path 289"
              d="M18.7 1.694c-.025 0-.048.006-.073.007v-.007A1.7 1.7 0 0016.933 0H1.694A1.7 1.7 0 000 1.694v16.512a.424.424 0 00.261.391.419.419 0 00.162.032.423.423 0 00.3-.124l4.11-4.11h12.1a1.7 1.7 0 001.694-1.694V5.679l1.219-1.219A1.621 1.621 0 0018.7 1.694zm-.92 11.008a.848.848 0 01-.847.847H4.657a.423.423 0 00-.3.124l-3.51 3.511V1.694a.848.848 0 01.847-.847h15.242a.848.848 0 01.847.847v.289a1.593 1.593 0 00-.227.185l-4.607 4.606H4.657a.423.423 0 000 .847h7.613l-.407 2.034a.423.423 0 00.415.506.433.433 0 00.083-.008l2.116-.423a.421.421 0 00.217-.116l3.089-3.088zm-3.6-3.777l-1.368.274.274-1.369 3.843-3.843 1.1 1.1zm5.063-5.063l-.62.62-1.1-1.1.62-.62a.776.776 0 011.095 1.1z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Message;
