import * as React from 'react';
import merge from 'classnames';

import s from './LinearProgress.module.css';

export default function LinearProgress({ color, value, ...props }) {
  return (
    <progress
      className={merge(s.Progress, props.className)}
      max="100"
      value={value}
      style={{ ...props.style, '--progress-color': color }}
    />
  );
}
