import React from 'react';
import { Trans } from '@lingui/react';
import { toast } from 'react-toastify';

import { getAxios } from '@common/utilities';
import useAsync from '@common/useAsync';

import MainButton from '@common/MainButton';
import Spinner from '@common/Spinner';

import merge from 'classnames';
import s from './TrainingCompleteInfo.module.css';

export default function TrainingCompleteInfo(props) {
  const { run: runCompleteTraining, isLoading: isCompletingTraining } =
    useAsync();

  const { trainingId, trainingName, onOpenCalendar, onNextStep } = props;

  function completeTrainingHandler() {
    const axios = getAxios();

    runCompleteTraining(
      axios
        .put(`/trainings/${trainingId}/complete`)
        .then(resp => {
          if (resp.status !== 200) throw new Error();

          onNextStep();
        })
        .catch(() => {
          toast.error('Oops! An error occured');
        })
    );
  }

  return (
    <>
      <div className={s.TrainingCompleteInfo}>
        <div className={s.ModalHeader}>
          <div className={s.Title}>
            <Trans id="TrainingCompleteInfo.title">
              Hai concluso l’allenamento!
            </Trans>
          </div>
        </div>

        <div className={s.ModalBody}>
          <Trans id="TrainingCompleteInfo.body" values={{ trainingName }}>
            Oggi si conclude il tuo allenamento "{trainingName}"
          </Trans>
        </div>
      </div>

      <div className={s.ModalFooter}>
        <div className={s.Text}>
          <Trans id="TrainingCompleteInfo.footer">
            Hai raggiunto il tuo obiettivo?
          </Trans>
        </div>

        <div className={s.ButtonWrapper}>
          <MainButton className={s.Button} onClick={completeTrainingHandler}>
            {isCompletingTraining ? <Spinner /> : <Trans id="yes">'Si'</Trans>}
          </MainButton>
          <MainButton
            className={merge(s.Button, s.Outlined)}
            onClick={onOpenCalendar}
          >
            <Trans id="no">No</Trans>
          </MainButton>
        </div>
      </div>
    </>
  );
}
