import React from 'react';
import { Trans } from '@lingui/react';

import Modal from '@common/Modal';
import DareModalContent from './DareModal';
import BackPlanningModal from './BackPlanningModal';
import GetInspiredModal from './GetInspiredModal';
import Spinner from '@common/Spinner';
import CloseIcon from '@common/Icons/CloseIcon';

import s from './ExampleModal.module.css';

export default function ExampleModal(props) {
  const { type, clusterId, examples, isFetching, onCloseModal } = props;

  const dataForType = examples?.filter(eg => eg.type === type);

  return (
    <Modal
      isOpen={type}
      className={s.ExampleModal}
      backdropClassName={s.ExampleModalBackdrop}
      onClose={onCloseModal}
    >
      <div className={s.ExampleModalTitleBar}>
        <div className={s.ModalTitle}>
          {type === 'dare' ? (
            'D.A.R.E'
          ) : type === 'back-planning' ? (
            'Back Planning'
          ) : (
            <Trans id="get_inspired">Lasciati Ispirare</Trans>
          )}
        </div>
        <CloseIcon style={{ cursor: 'pointer' }} onClick={onCloseModal} />
      </div>

      <div className={s.ExampleModalBody}>
        {type === 'get_inspired' ? (
          <GetInspiredModal />
        ) : (
          <>
            {!examples && isFetching && (
              <div className={s.InfoWrapper}>
                <Spinner />
              </div>
            )}

            {!isFetching && dataForType?.length < 1 && (
              <div className={s.InfoWrapper}>No examples available </div>
            )}

            {dataForType?.length > 0 &&
              (type === 'dare' ? (
                <DareModalContent examples={dataForType} />
              ) : type === 'back-planning' ? (
                <BackPlanningModal examples={dataForType} />
              ) : type === 'get_inspired' ? (
                <GetInspiredModal />
              ) : null)}
          </>
        )}
      </div>
    </Modal>
  );
}
