import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { parseISO, isDate, isEqual } from 'date-fns';
import { arrayMove } from '@dnd-kit/sortable';
import { i18n } from '@lingui/core';
import { toast } from 'react-toastify';
import { Trans } from '@lingui/react';

import useResource from '@common/useResource';
import useAsync from '@common/useAsync';
import { formatDate, getAxios } from '@common/utilities';
import useMediaQuery from '@common/useMediaQuery';
import useI18n from '@common/useI18n';

import Card from '@common/Card';
import DetailsTopPanel from './DetailsTopPanel';
import TrainingDetailsMobile from './TrainingDetailsMobile';
import DetailsInfo from './DetailsInfo';
import DetailsDuration from './DetailsDuration';
import TrainingDatesModal from '@new-training/TrainingDatesModal';
import BackPlanningDetailsConfig from './BackPlanningDetailsConfig';
import GetInspiredDetailsConfig from './GetInspiredDetailsConfig';
import DareDetailsConfig from './DareDetailsConfig';
import DetailsFeedback from './DetailsFeedback';
import AlertModal from '@common/Modal/AlertModal';
import TrainingCompleteModal from '../TrainingCompleteModal';
import SkeletonConfig from './SkeletonConfig';

import merge from 'classnames';
import s from './TrainingDetails.module.css';

export default function TrainingDetails() {
  const history = useHistory();
  const { trainingId } = useParams();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { i18n: lingui } = useI18n();

  const {
    data: training,
    // error: trainingError,
    mutate: mutateTraining
  } = useResource(`/trainings/${trainingId}`);

  const {
    id,
    completed,
    cluster,
    skill,
    name,
    goal,
    storage,
    created_at,
    end_at
  } = {
    ...(training || {})
  };

  const trainingType = storage?.type;

  const { run: runUpdateTrainingDates, isLoading: isUpdatingTrainingDates } =
    useAsync();
  const { run: runDeleteTraining, isLoading: isDeletingTraining } = useAsync();
  const { run: runUpdateTrainingConfig, isLoading: isUpdatingTrainingConfig } =
    useAsync();

  const [selectedDates, setSelectedDates] = React.useState(null);
  const [localConfig, setLocalConfig] = React.useState(
    trainingType === 'dare' ? {} : []
  );
  const [openModals, setOpenModals] = React.useState({
    dateModal: false,
    deleteAlertModal: false,
    trainingCompleteModal: false
  });

  React.useEffect(() => {
    if (!training) return;

    // ===  DATES  ===
    const start = isDate(created_at) ? created_at : parseISO(created_at);
    const end = isDate(end_at) ? end_at : parseISO(end_at);

    setSelectedDates([start, end]);

    // ===  CONFIGURATIONS  ===
    // #TODO: replace this - old config object
    const parsedConfig = JSON.parse(storage?.configuration);
    let newConfig;

    if (
      trainingType === 'backplanning' &&
      typeof parsedConfig[0] === 'string'
    ) {
      newConfig = parsedConfig.map(item => ({
        name: item,
        completed: false
      }));
    } else if (
      trainingType === 'dare' &&
      (typeof parsedConfig.do[0] === 'string' ||
        typeof parsedConfig.add[0] === 'string' ||
        typeof parsedConfig.reinforce[0] === 'string' ||
        typeof parsedConfig.eliminate[0] === 'string')
    ) {
      newConfig = generateNewDareConfigStructure(parsedConfig);
    }

    setLocalConfig(newConfig ? newConfig : parsedConfig);
  }, [training]);

  function generateNewDareConfigStructure(config) {
    const keys = Object.keys(config);
    let newConfig = {};

    keys.forEach(key => {
      newConfig[key] = config['do'].map(conf => ({ name: conf, counter: 0 }));
    });

    return newConfig;
  }

  function goBack() {
    history.push('/trainings');
  }

  function toggleModalHandler(type, mode) {
    const exists = Object.keys(openModals).includes(type);

    if (!exists) return;

    if (mode === 'open') {
      setOpenModals(modals => ({ ...modals, [type]: true }));
      return;
    }

    setOpenModals(modals => ({ ...modals, [type]: false }));
  }

  function sortingHandler(activeIdx, overIdx) {
    setLocalConfig(items => arrayMove(items, activeIdx, overIdx));
  }

  function updateConfigHandler(params) {
    const { ev, mode, editIdx, confKey } = params;

    if (!ev.target.value || (ev.key !== 'Enter' && ev.type !== 'blur')) return;

    if (mode === 'add') {
      if (trainingType === 'dare') {
        const newItem = { name: ev.target.value, counter: 0 };
        setLocalConfig(config => ({
          ...config,
          [confKey]: [...config[confKey], newItem]
        }));
      } else if (trainingType === 'back-planning') {
        setLocalConfig(config => [
          ...config,
          { name: ev.target.value, completed: false }
        ]);
      }

      return;
    }

    if (trainingType === 'dare') {
      setLocalConfig(config => ({
        ...config,
        [confKey]: config[confKey].map((conf, idx) =>
          idx === editIdx ? { ...conf, name: ev.target.value } : conf
        )
      }));
    } else if (trainingType === 'back-planning') {
      setLocalConfig(config =>
        config.map((conf, idx) =>
          idx === editIdx ? { ...conf, name: ev.target.value } : conf
        )
      );
    }
  }

  function updateBackPlanningConfigStatusHandler(ev, updateIdx) {
    setLocalConfig(conf =>
      conf.map((item, idx) =>
        idx === updateIdx ? { ...item, completed: ev.target.checked } : item
      )
    );
  }

  function updateDareConfigCounterHandler(confKey, updateIdx) {
    setLocalConfig(config => ({
      ...config,
      [confKey]: config[confKey].map((conf, idx) =>
        idx === updateIdx ? { ...conf, counter: conf.counter + 1 } : conf
      )
    }));
  }

  function onDeleteRow(selectedIdx, confId) {
    if (trainingType === 'back-planning') {
      setLocalConfig(config =>
        config.filter((conf, idx) => idx !== selectedIdx)
      );
      return;
    }

    if (!confId) {
      console.error('cannot delete row for dare, confId not provided');
      return;
    }

    setLocalConfig(config => {
      return {
        ...config,
        [confId]: config[confId].filter((conf, idx) => idx !== selectedIdx)
      };
    });
  }

  function saveConfiguration() {
    const jsonConfig = JSON.stringify(localConfig);

    if (jsonConfig === storage?.configuration) {
      toast.info(/*i18n*/ i18n._('TrainingDetails.Config.update_to_date'));

      return;
    }

    const data = {
      type: storage.type,
      configuration: jsonConfig
    };

    const axios = getAxios();

    runUpdateTrainingConfig(
      axios
        .patch(`/trainings/${trainingId}/storage`, { data })
        .then(() => {
          mutateTraining();
          toast.success(
            /*i18n*/ i18n._('TrainingDetails.Config.update_success')
          );
          trainingCompleteCheckHandler();
        })
        .catch(() => {
          toast.error(/*i18n*/ i18n._('TrainingDetails.Config.update_error'));
        })
    );
  }

  function saveDate(newDates) {
    const startDateIsEqual = isEqual(selectedDates[0], newDates[0]);
    const endDateIsEqual = isEqual(selectedDates[1], newDates[1]);

    if (startDateIsEqual && endDateIsEqual) {
      toggleModalHandler('dateModal', 'close');

      return;
    }

    const payload = {
      created_at: formatDate(newDates[0], 'Y-MM-dd'),
      end_at: formatDate(newDates[1], 'Y-MM-dd')
    };

    const axios = getAxios();

    runUpdateTrainingDates(
      axios
        .patch(`/trainings/${trainingId}/dates`, payload)
        .then(resp => {
          if (resp.status !== 200) throw new Error();

          mutateTraining();
          toggleModalHandler('dateModal', 'close');

          toast.success(
            /*i18n*/ i18n._('TrainingDetails.Dates.update_success')
          );
        })
        .catch(() => {
          toast.error(/*i18n*/ i18n._('TrainingDetails.Dates.update_error'));
        })
    );
  }

  function deleteTraining() {
    if (!trainingId) return;

    const axios = getAxios();

    runDeleteTraining(
      axios
        .delete(`/trainings/${trainingId}`)
        .then(resp => {
          if (resp.status !== 200) throw new Error();

          toggleModalHandler('deleteAlertModal', 'close');
          toast.success(/*i18n*/ i18n._('TrainingDetails.Delete.success'));

          history.push('/trainings');
        })
        .catch(() => {
          toast.error(/*i18n*/ i18n._('TrainingDetails.Delete.error'));
        })
    );
  }

  function trainingCompleteCheckHandler() {
    let isCompleted;

    if (trainingType === 'back-planning') {
      isCompleted = checkBackPlanningCompletion();
    } else if (trainingType === 'dare') {
      isCompleted = checkDareCompletion();
    }

    if (!isCompleted) return;

    toggleModalHandler('trainingCompleteModal', 'open');
  }

  function checkDareCompletion() {
    let trainingCompleted = true;
    const keys = Object.keys(localConfig);

    keys.forEach(key => {
      localConfig[key].forEach(lc => {
        if (lc.counter < 1) {
          trainingCompleted = false;
          return;
        }
      });
    });

    return trainingCompleted;
  }

  function checkBackPlanningCompletion() {
    if (!localConfig || localConfig.length < 1) return false;

    let trainingCompleted = true;

    localConfig.forEach(lc => {
      if (lc.completed === false) {
        trainingCompleted = false;
      }
    });

    return trainingCompleted;
  }

  const startDate = selectedDates?.[0]
    ? formatDate(selectedDates[0], 'PPP', lingui.locale)
    : null;
  const endDate = selectedDates?.[1]
    ? formatDate(selectedDates[1], 'PPP', lingui.locale)
    : null;

  const { dateModal, deleteAlertModal, trainingCompleteModal } = openModals;

  return (
    <>
      <AlertModal
        openModal={deleteAlertModal}
        closeModal={() => toggleModalHandler('deleteAlertModal', 'close')}
        title={/*i18n*/ i18n._('TrainingDetails.Alert.Delete.title')}
        message={/*i18n*/ i18n._('TrainingDetails.Alert.Delete.message')}
        confirmButtonText={
          /*i18n*/ i18n._('TrainingDetails.Alert.Delete.Button.confirm')
        }
        cancelButtonText={
          /*i18n*/ i18n._('TrainingDetails.Alert.Delete.Button.cancel')
        }
        onConfirm={deleteTraining}
        onCancel={() => toggleModalHandler('deleteAlertModal', 'close')}
        isProcessing={isDeletingTraining}
      />

      <TrainingDatesModal
        openModal={dateModal}
        closeModal={() => toggleModalHandler('dateModal', 'close')}
        onConfirmDate={saveDate}
        selectedDate={selectedDates}
        isUpdatingDate={isUpdatingTrainingDates}
      />

      <TrainingCompleteModal
        training={training}
        openModal={trainingCompleteModal}
        onToggleModal={toggleModalHandler}
      />

      <div
        className={s.TrainingDetailsContainer}
        style={{ '--cluster-color': cluster?.color }}
      >
        <DetailsTopPanel
          title={name}
          goBack={goBack}
          onAlertModal={() => toggleModalHandler('deleteAlertModal', 'open')}
        />

        {isMobile ? (
          <TrainingDetailsMobile
            subSectionTitleClass={s.SubSectionTitle}
            behaviors={skill?.behaviors}
            goal={goal}
            training={training}
            trainingType={trainingType}
            isCompleted={completed}
            configuration={localConfig}
            onConfigChange={updateConfigHandler}
            onConfigCounterUpdate={updateDareConfigCounterHandler}
            onSort={sortingHandler}
            onConfigStatusUpdate={updateBackPlanningConfigStatusHandler}
            onSave={saveConfiguration}
            isProcessing={isUpdatingTrainingConfig}
            startDate={startDate}
            endDate={endDate}
            onOpenCalendar={() => toggleModalHandler('dateModal', 'open')}
            onAlertModal={() => toggleModalHandler('deleteAlertModal', 'open')}
            toggleModalHandler={toggleModalHandler}
            onDeleteRow={onDeleteRow}
          />
        ) : (
          <div className={s.DetailsBottomPanel}>
            <section className={merge(s.Section, s.SectionInfo)}>
              <DetailsInfo
                subSectionTitleClass={s.SubSectionTitle}
                behaviors={skill?.behaviors}
                goal={goal}
              />

              <DetailsDuration
                isCompleted={completed}
                subSectionTitleClass={s.SubSectionTitle}
                startDate={startDate}
                endDate={endDate}
                onOpenCalendar={() => toggleModalHandler('dateModal', 'open')}
              />
            </section>

            <section className={merge(s.Section, s.SectionConfig)}>
              <div className={s.SubSectionTitle}>
                {trainingType === 'dare' ? (
                  <Trans id="TrainingDetails.dare">
                    Quando porti a termine un’azione, premi sul numero per
                    portarti avanti
                  </Trans>
                ) : trainingType === 'back-planning' ? (
                  <Trans id="TrainingDetails.back-planning">
                    Spunta le azioni che hai portato a termine per monitorare i
                    tuoi progressi
                  </Trans>
                ) : trainingType === 'get_inspired' ? (
                  <Trans id="TrainingDetails.get-inspired">Allenamento</Trans>
                ) : (
                  <Trans id="TrainingDetails.wait">Please wait...</Trans>
                )}
              </div>

              {!training && (
                <Card style={{ padding: '2rem 1rem' }}>
                  <SkeletonConfig />
                </Card>
              )}

              {trainingType === 'dare' ? (
                <DareDetailsConfig
                  isCompleted={completed}
                  configuration={localConfig}
                  onConfigChange={updateConfigHandler}
                  onConfigCounterUpdate={updateDareConfigCounterHandler}
                  onSave={saveConfiguration}
                  isProcessing={isUpdatingTrainingConfig}
                  onDeleteRow={onDeleteRow}
                />
              ) : trainingType === 'back-planning' ? (
                <BackPlanningDetailsConfig
                  isCompleted={completed}
                  configuration={localConfig}
                  onSort={sortingHandler}
                  onConfigChange={updateConfigHandler}
                  onConfigStatusUpdate={updateBackPlanningConfigStatusHandler}
                  onSave={saveConfiguration}
                  isProcessing={isUpdatingTrainingConfig}
                  onDeleteRow={onDeleteRow}
                />
              ) : trainingType === 'get_inspired' ? (
                <GetInspiredDetailsConfig
                  isCompleted={completed}
                  onCompleteTraining={toggleModalHandler}
                  configuration={localConfig}
                />
              ) : null}
            </section>

            <section className={merge(s.Section, s.SectionFeedback)}>
              <DetailsFeedback
                subSectionTitleClass={s.SubSectionTitle}
                trainingId={trainingId}
              />
            </section>
          </div>
        )}
      </div>
    </>
  );
}
