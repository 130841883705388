import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import useResource from '@common/useResource';
import useSkills from '@common/useSkills';

import { Trans } from '@lingui/react';
import { i18n } from '@lingui/core';

import TrainingLayout from '@new-training/TrainingLayout';
import TrainingProgress from '@new-training/TrainingProgress';
import SelectedBehaviorsBar from '@new-training/SelectedBehaviorsBar';
import MainButton from '@common/MainButton';
import Modal from '@common/Modal';
import ArrowRightIcon from '@common/Icons/ArrowRight';
import CloseIcon from '@common/Icons/CloseIcon';
import EditIcon from '@common/Icons/Edit';

import merge from 'classnames';
import s from './TrainingSetupForm.module.css';

export default function TrainingSetupForm(props) {
  const { clusters, savedBehaviors, info, onSetInfo } = props;

  const history = useHistory();
  const { clusterId, skillId, methodType } = useParams();

  const { data: behaviors } = useResource(`/skills/${skillId}/behaviors`);
  const { data: goals } = useResource(`/skills/${skillId}/goals`);
  const { skills } = useSkills({ clusters });

  const { handleSubmit, register, setValue, watch } = useForm();
  const [openGoalModal, setOpenGoalModal] = React.useState(false);

  const selectedCluster = clusters?.find(cluster => cluster.id == clusterId);
  const selectedSkill = skills?.[clusterId]?.find(skill => skill.id == skillId);
  const selectedBehaviors = behaviors?.filter(behavior =>
    savedBehaviors.includes(behavior.id)
  );

  React.useEffect(() => {
    const fields = Object.keys(info);

    if (fields.length < 1) return;

    fields.forEach(field => setValue(field, info[field]));
  }, []);

  React.useEffect(() => {
    if (!savedBehaviors || savedBehaviors.length < 1) {
      history.push(`/t/new/c/${clusterId}/s/${skillId}/behaviors`);
    }
  }, [savedBehaviors]);

  function openModalHandler() {
    setOpenGoalModal(true);
  }

  function closeModalHandler() {
    setOpenGoalModal(false);
  }

  function removePredefinedGoal(ev) {
    ev.stopPropagation();
    setValue('predefinedGoal', null);
  }

  function onSubmit(data) {
    if (!data.goal && !data.predefinedGoal) return;

    onSetInfo(data);
    history.push(
      `/t/new/c/${clusterId}/s/${skillId}/m/${methodType}/configuration`
    );
  }

  const predefinedGoalValue = watch('predefinedGoal');
  const formIsValid =
    watch('name') && (watch('predefinedGoal') || watch('goal'));

  return (
    <>
      <Modal
        isOpen={openGoalModal}
        onClose={closeModalHandler}
        hideOnClickOutside={false}
        backdropClassName={s.ModalBackdrop}
        className={s.GoalsModal}
      >
        <div className={s.ModalHeader}>
          <Trans id="Trainings.Setup.goal" />
          <CloseIcon
            style={{ cursor: 'pointer' }}
            onClick={closeModalHandler}
          />
        </div>

        <div className={s.ModalBody}>
          <GoalsList
            register={register}
            goals={goals}
            color={selectedCluster?.color}
            getInputProps={() => register(`predefinedGoal`)}
          />

          <MainButton
            className={s.BtnModalConfirm}
            onClick={closeModalHandler}
            disabled={!predefinedGoalValue}
          >
            <Trans id="Trainings.Setup.confirm" />
          </MainButton>
        </div>
      </Modal>

      <TrainingLayout color={selectedCluster?.color}>
        <div className={s.HeaderWrapper}>
          <div className={s.Header}>
            <Link to={`/t/new/c/${clusterId}/s/${skillId}/method`}>
              <ArrowRightIcon style={{ transform: 'rotate(180deg)' }} />
            </Link>

            <h1 className={s.Label}>{selectedSkill?.name}</h1>
          </div>
        </div>

        <div className={s.ProgressContainer}>
          <TrainingProgress indexActive={4} />
        </div>

        {selectedBehaviors?.length > 0 && (
          <SelectedBehaviorsBar behaviors={selectedBehaviors} />
        )}

        <div className={s.FormContainer}>
          <h2>
            <Trans id="Trainings.Setup.setup" />
          </h2>

          <form className={s.Form}>
            <div className={s.FormField}>
              <label htmlFor="trainingName" className={s.FieldLabel}>
                <Trans id="Trainings.Setup.name" />
              </label>
              <input
                type="text"
                name="name"
                id="trainingName"
                className={s.Input}
                placeholder={/*i18n*/ i18n._('Trainings.Setup.training.title')}
                {...register('name', { required: true })}
              />
            </div>

            <div className={s.FormField}>
              <label htmlFor="trainingGoal" className={s.FieldLabel}>
                <Trans id="Trainings.Setup.goal.define" />
              </label>
              <label className={s.FieldSubLabel}>
                <Trans id="Trainings.Setup.goal.hints" />
              </label>

              <div
                className={s.BtnGoalsModal}
                role="button"
                onClick={openModalHandler}
              >
                {predefinedGoalValue ? (
                  <>
                    <div className={s.PredefinedGoalWrapper}>
                      <CloseIcon
                        className={merge(s.BtnIcon, s.BtnRemove)}
                        onClick={removePredefinedGoal}
                      />

                      <span>{predefinedGoalValue}</span>
                    </div>

                    <EditIcon
                      className={s.BtnIcon}
                      // onClick={openModalHandler}
                    />
                  </>
                ) : (
                  <>
                    <Trans id="Trainings.Setup.goal.view" />
                    <ArrowRightIcon
                      className={s.BtnIcon}
                      // onClick={openModalHandler}
                    />
                  </>
                )}
              </div>

              <div className={s.TextareaWrapper}>
                <div className={s.TextareaTitle}>
                  <Trans id="Trainings.Setup.goal.title.create" />
                </div>

                <textarea
                  id="trainingGoal"
                  name="goal"
                  className={s.Input}
                  rows="6"
                  placeholder={
                    /*i18n*/ i18n._('Trainings.Setup.goal.custom.placeholder')
                  }
                  {...register('goal')}
                  disabled={predefinedGoalValue}
                ></textarea>
              </div>
            </div>
          </form>
        </div>

        <div className="centering" style={{ paddingBottom: '3rem' }}>
          <MainButton onClick={handleSubmit(onSubmit)} disabled={!formIsValid}>
            <Trans id="Trainings.Setup.continue" />
          </MainButton>
        </div>
      </TrainingLayout>
    </>
  );
}

function GoalsList(props) {
  const { getInputProps, goals, color } = props;

  return (
    <>
      {goals?.length < 1 ? (
        <div className={s.EmptyPredefinedGoals}>
          <Trans id="Trainings.Setup.PredefinedGoalsList.empty" />
        </div>
      ) : (
        goals?.map((goal, idx) => (
          <div
            key={goal.text}
            className={s.GoalItem}
            style={{ '--bullet-color': color }}
          >
            <input
              name="goal"
              type="radio"
              value={goal.text}
              id={`${goal.text}-${idx}`}
              {...getInputProps()}
            />

            <label htmlFor={`${goal.text}-${idx}`}>
              {goal.questions.map(q => (
                <div>{q}</div>
              ))}

              <div className={s.GoalText}>
                <strong>{goal.text}</strong>
              </div>
            </label>
          </div>
        ))
      )}
    </>
  );
}
