import * as React from 'react';
import { useParams } from 'react-router-dom';

import PageLayout from '@common/PageLayout';
import Spinner from '@common/Spinner';
import QuestionnaireHeader from '@common/QuestionnaireHeader';
import QuestionnaireRecap from '@common/QuestionnaireRecap';
import Deck from '@common/Deck';

import { i18n } from '@lingui/core';

import useResource from '@common/useResource';
import useSkills from '@common/useSkills';
import useQuestionnaire from '@common/useQuestionnaire';
import { getAxios } from '@common/utilities';

import s from './SkillQuiz.module.css';

export default function SkillQuiz() {
  const { skillId, clusterId } = useParams();

  const { data: clusters } = useResource('/clusters');
  const { skills } = useSkills({ clusters });

  let { data: questions } = useResource(`/skills/${skillId}/questions`);

  const { questionnaireState, restart, onSubmit } = useQuestionnaire();

  function onAnswerSubmit(data) {
    const currQuestion = questions[questionnaireState.currentQuestion];
    const currentQuestionId = currQuestion.id;

    const submittedAnswerValue = data[`question--${currentQuestionId}`];
    const submittedAnswer = currQuestion.answers.find(
      answ => answ.text === submittedAnswerValue
    );

    onSubmit({
      ...questionnaireState,
      currentQuestion: questionnaireState.currentQuestion + 1,
      answers: [
        ...questionnaireState.answers,
        {
          answer: submittedAnswer.id,
          id: currentQuestionId
        }
      ]
    });
  }

  function onSubmitSuccessful() {
    return;
  }

  function submitAnswers() {
    const { answers } = questionnaireState;

    const axios = getAxios();
    return axios.post(`/skills/${skillId}/answers`, { answers });
  }

  if (!clusters || !skills || !questions) {
    return (
      <PageLayout color="#fff">
        <Spinner />
      </PageLayout>
    );
  }

  const selectedCluster = clusters.find(cluster => cluster.id == clusterId);
  const selectedSkill = skills[selectedCluster.id].find(
    skill => skill.id == skillId
  );

  if (questionnaireState.currentQuestion === questions.length) {
    return (
      <PageLayout color={selectedCluster.color}>
        <div className={s.Wrapper}>
          <QuestionnaireRecap
            clusterId={clusterId}
            clusterName={selectedCluster.name}
            canShowResult={true}
            color={selectedCluster.color}
            fontColor={'#fff'}
            submit={submitAnswers}
            restart={restart}
            answers={questionnaireState.answers}
            onSubmitSuccessful={onSubmitSuccessful}
            title={selectedSkill.name}
            subtitle={/*i18n*/ i18n._('Quiz.Skills.check')}
            hideFirstRecapPage={true}
          />
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout color={selectedCluster.color}>
      <div className={s.Wrapper}>
        <QuestionnaireHeader
          color={selectedCluster.color}
          title={selectedSkill.name}
          currentQuestion={questionnaireState.currentQuestion}
          cardsLength={questions.length}
          goBackLink={'/styles'}
          isSkillMode
        />

        <Deck
          color={selectedCluster.color}
          onSubmit={onAnswerSubmit}
          cards={questions}
          currentQuestion={questionnaireState.currentQuestion}
        />
      </div>
    </PageLayout>
  );
}
