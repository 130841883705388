import * as React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/react';

import Card from '@common/Card';
import ArrowRight from '@common/Icons/ArrowRight';

import { hexToRgbA } from '@common/utilities';

import s from './ClusterCard.module.css';

export default function ClusterCard({ cluster, skills }) {
  return (
    <Card
      className={s.Card}
      style={{ background: hexToRgbA(cluster.color, 0.2) }}
    >
      <div
        className={s.CardTitle}
        style={{ background: hexToRgbA(cluster.color, 1) }}
      >
        {cluster.name}
      </div>

      <div className={s.Skills}>
        {skills[cluster.id].map(skill => (
          <div
            key={skill.id}
            className={s.Skill}
            style={{ '--card-color': cluster.color }}
          >
            <div>{skill.name}</div>
            <progress className={s.Progress} max="100" value={skill.value} />
          </div>
        ))}
      </div>

      <Link className={s.ArrowBtn} to={`/styles/${cluster.id}/skills`}>
        <div>
          <Trans id="Cluster.deepdive" />
        </div>
        <ArrowRight />
      </Link>
    </Card>
  );
}
