import * as React from 'react';

function Arrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15.972}
      height={17.574}
      viewBox="0 0 15.972 17.574"
      {...props}
    >
      <g
        data-name="Polygon 1"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M14.465 7.917a1 1 0 010 1.74L2.493 16.441A1 1 0 011 15.571V2.003a1 1 0 011.493-.87z" />
        <path
          d="M14.972 8.787a.99.99 0 00-.507-.87L2.493 1.133A1 1 0 001 2.003v13.568a1 1 0 001.493.87l11.972-6.784a.99.99 0 00.507-.87m1 0c0 .73-.38 1.38-1.014 1.74L2.986 17.311a1.98 1.98 0 01-1.993-.012A1.98 1.98 0 010 15.57V2.003C0 1.283.371.637.993.275A1.98 1.98 0 012.986.263l11.972 6.784a1.978 1.978 0 011.014 1.74z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default Arrow;
