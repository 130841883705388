import React from 'react';

function Style(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25.678}
      height={23.15}
      viewBox="0 0 25.678 23.15"
      {...props}
    >
      <path
        data-name="Path 79"
        d="M22.95.2a2.523 2.523 0 00-1.817 4.278l-4.618 9.235a2.445 2.445 0 00-.306-.031 2.517 2.517 0 00-.951.189l-3.585-4.032a2.5 2.5 0 00.324-1.212 2.528 2.528 0 10-4.435 1.64L3.179 17.94a2.536 2.536 0 00-.452-.046 2.558 2.558 0 101.908.888l4.381-7.674a2.4 2.4 0 001.4-.143L14 15a2.5 2.5 0 00-.32 1.212 2.528 2.528 0 104.344-1.75l4.618-9.235a2.444 2.444 0 00.306.031 2.528 2.528 0 100-5.056zM2.728 21.265a.843.843 0 11.843-.843.843.843 0 01-.843.843zm6.741-11.8a.843.843 0 11.843-.843.843.843 0 01-.844.846zm6.741 7.583a.843.843 0 11.843-.843.843.843 0 01-.844.847zM22.95 3.57a.843.843 0 11.843-.843.843.843 0 01-.843.843z"
        fill="currentColor"
        // fill="#b7b7b7"
        stroke="#fefefe"
        strokeWidth={0.4}
      />
    </svg>
  );
}

export default Style;
