import React from 'react';
import ContentLoader from 'react-content-loader';
import { Trans } from '@lingui/react';

import Card from '@common/Card';

import merge from 'classnames';
import s from './DetailsInfo.module.css';

export default function DetailsInfo({ subSectionTitleClass, behaviors, goal }) {
  return (
    <>
      <div className={s.InfoContentWrapper}>
        <div className={merge(subSectionTitleClass, s.Subtitle)}>
          <Trans id="Trainings.Behaviors.available">
            Comportamenti da allenare
          </Trans>
        </div>

        <div className={merge(s.DetailsCard, s.BehaviorCard)}>
          {!behaviors ? (
            <Card style={{ padding: '1.2rem 1rem', height: 100 }}>
              <SkeletonOneLine />
              <SkeletonOneLine />
            </Card>
          ) : (
            <ul>
              {behaviors.map(b => (
                <li key={b}>{b}</li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <div className={s.InfoContentWrapper}>
        <div className={merge(subSectionTitleClass, s.Subtitle)}>
          <Trans id="ExampleModal.Label.goal">Obiettivo</Trans>
        </div>

        {!goal ? (
          <Card style={{ padding: '1.2rem 1rem', height: 100 }}>
            <SkeletonOneLine />
            <SkeletonOneLine />
          </Card>
        ) : (
          <div className={s.DetailsCard}>{goal}</div>
        )}
      </div>
    </>
  );
}

function SkeletonOneLine(props) {
  return (
    <ContentLoader
      width={'100%'}
      height={30}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="20" />
    </ContentLoader>
  );
}
