import * as React from 'react';
import { use100vh } from 'react-div-100vh';
import { Route, useHistory, useRouteMatch, Switch } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import merge from 'classnames';

import { i18n } from '@lingui/core';
import { Trans } from '@lingui/react';

import useResource from '@common/useResource';
import useMediaQuery from '@common/useMediaQuery';

import CardItem from '../CardItem';
import FeedbacksPerUser from '../FeedbacksPerUser';
import FeedbackPerUserModal from '../FeedbackPerUserModal';
import FeedbackChat from '../FeedbackChat';

import styles from './ReceivedFeedbacks.module.css';

export default function ReceivedFeedbacks({ type = 'RECEIVED' }) {
  const feedbackTypePrefix = type === 'RECEIVED' ? 'r' : 's';
  const feedbackEndpointSuffix = type === 'RECEIVED' ? 'received' : 'sent';

  const isMobile = useMediaQuery('(max-width: 600px)');
  const height = use100vh();

  const history = useHistory();
  const userMatch = useRouteMatch(`/feedbacks/${feedbackTypePrefix}/:userId`);
  const chatMatch = useRouteMatch(
    `/feedbacks/${feedbackTypePrefix}/:userId/feedback/:feedbackId`
  );

  const {
    data: feedbacks,
    error: fetchFeedbacksError,
    mutate,
    updateServerData
  } = useResource(`/trainings/feedbacks/${feedbackEndpointSuffix}`);

  function onListUserClick(userId) {
    history.push(`/feedbacks/${feedbackTypePrefix}/${userId}`);
  }

  function openChat(userId, feedbackId) {
    history.push(
      `/feedbacks/${feedbackTypePrefix}/${userId}/feedback/${feedbackId}`
    );
  }

  if (!feedbacks && !fetchFeedbacksError) {
    return (
      <div className={merge(styles.Container, styles.Padding)}>
        <FeedbackListLoader />
      </div>
    );
  }

  if (feedbacks?.length === 0) {
    return (
      <div className={merge(styles.Container, styles.EmptyInfo)}>
        <div>
          {type === 'RECEIVED' ? (
            <Trans id="Feedback.Received.empty" />
          ) : (
            <Trans id="Feedback.Sent.empty" />
          )}
        </div>
      </div>
    );
  }

  const selectedUserId = userMatch?.params?.userId;
  const selectedFeedbackId = chatMatch?.params?.feedbackId;

  return (
    <div className={styles.Container}>
      <div
        className={
          selectedUserId ? merge(styles.Col, styles.Shadow) : styles.Col
        }
      >
        <ul
          className={styles.List}
          // style={{ height: isMobile ? height * 0.57 : null }}
          style={{ height: isMobile ? height * 0.53 : null }}
        >
          {feedbacks?.map(feedback => (
            <li key={feedback.user.id}>
              <FeedbackItem
                messages={feedback.messages}
                selected={feedback.user.id == selectedUserId}
                user={feedback.user}
                onClick={() => onListUserClick(feedback.user.id)}
              />
            </li>
          ))}
        </ul>
      </div>

      {isMobile ? (
        <Switch>
          <Route path={`/feedbacks/${feedbackTypePrefix}/:userId`} exact>
            <FeedbackPerUserModal feedbacks={feedbacks}>
              <FeedbacksPerUser
                selectedFeedbackId={selectedFeedbackId}
                feedbacks={feedbacks}
                openChat={openChat}
              />
            </FeedbackPerUserModal>
          </Route>

          <Route
            path={`/feedbacks/${feedbackTypePrefix}/:userId/feedback/:feedbackId`}
          >
            <FeedbackPerUserModal feedbacks={feedbacks} style={{ zIndex: 3 }}>
              <FeedbackChat
                selectedUserId={selectedUserId}
                selectedFeedbackId={selectedFeedbackId}
                feedbacks={feedbacks}
                type={type}
              />
            </FeedbackPerUserModal>
          </Route>
        </Switch>
      ) : (
        <>
          <Route path={`/feedbacks/${feedbackTypePrefix}/:userId`}>
            <div
              className={
                selectedFeedbackId
                  ? merge(styles.Col, styles.Shadow)
                  : styles.Col
              }
            >
              <FeedbacksPerUser
                feedbacks={feedbacks}
                openChat={openChat}
                selectedFeedbackId={selectedFeedbackId}
              />
            </div>
          </Route>
          <Route
            path={`/feedbacks/${feedbackTypePrefix}/:userId/feedback/:feedbackId`}
          >
            <div className={styles.ColLast}>
              <FeedbackChat
                selectedUserId={selectedUserId}
                selectedFeedbackId={selectedFeedbackId}
                feedbacks={feedbacks}
                type={type}
              />
            </div>
          </Route>
        </>
      )}
    </div>
  );
}

function FeedbackItem({ user, onClick, selected, messages }) {
  return (
    <CardItem
      isUserCard
      selected={selected}
      pictureHref={user.image}
      alt={/*i18n*/ i18n._('Feedback.Received.photo', { name: user.name })}
      title={user.name}
      subtitle={user.interacted_at}
      rightNumber={messages}
      onClick={onClick}
      className={styles.ListItem}
    />
  );
}

function FeedbackListLoader() {
  return (
    <ContentLoader
      className={styles.Loader}
      viewBox="0 0 290 275"
      height={275}
      width={290}
    >
      <circle cx="70.2" cy="73.2" r="41.3" />
      <rect x="129.9" y="39.5" width="125.5" height="17" />
      <rect x="129.9" y="64.7" width="296" height="17" />

      <circle cx="70.7" cy="183.5" r="41.3" />
      <rect x="130.4" y="149.9" width="125.5" height="17" />
      <rect x="130.4" y="175" width="296" height="17" />
    </ContentLoader>
  );
}
