import React from 'react';

function Hamburger(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={9}
      viewBox="0 0 24 9"
      {...props}
    >
      <g data-name="Group 919" fill="none" stroke="#707070">
        <path data-name="Line 135" d="M0 .5h24" />
        <path data-name="Line 134" d="M0 4.5h24" />
        <path data-name="Line 133" d="M0 8.5h24" />
      </g>
    </svg>
  );
}

export default Hamburger;
