import * as React from 'react';

import styles from './Spinner.module.css';

export default function Spinner({ strokeWidth = 4, strokeColor }) {
  return (
    <div className={styles.SpinnerContainer}>
      <svg className={styles.Circular} viewBox="25 25 50 50">
        <circle
          className={styles.Path}
          style={{ stroke: strokeColor }}
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
}
