import * as React from 'react';
import { gsap } from 'gsap';
import { Trans } from '@lingui/react';
import { CSS } from '@dnd-kit/utilities';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import merge from 'classnames';

import Card from '@common/Card';
import MainButton from '@common/MainButton';
import Hamburger from '@common/Icons/Hamburger';

import s from './DeckDragAndDrop.module.css';

export default function Deck({ color, cards, currentQuestion, onSubmit }) {
  const cardRef = React.useRef(null);

  const [answers, setAnswers] = React.useState(cards[currentQuestion].answers);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10
      }
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  React.useEffect(() => {
    if (currentQuestion > 0) {
      setAnswers(cards[currentQuestion].answers);
    }
  }, [currentQuestion]);

  function confirm() {
    cardRef.current.classList.add(s.Moving);
    gsap.to(cardRef.current, {
      rotation: -30,
      x: -1500,
      y: -100,
      onComplete: () => {
        onSubmit(answers);
      }
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setAnswers(answers => {
        const oldIndex = answers.findIndex(answ => answ.text === active.id);
        const newIndex = answers.findIndex(answ => answ.text === over.id);

        return arrayMove(answers, oldIndex, newIndex);
      });
    }
  }

  const answerIds = answers.map(answer => answer.text);

  return (
    <>
      <div
        className={s.Deck}
        style={{
          '--deck-color': color,
          '--mark-color': color,
          '--mark-text': color ? '#fff' : '',
          '--bullet-color': color ? '#fff' : ''
        }}
      >
        {cards
          .slice(currentQuestion, currentQuestion + 4)
          .map((card, index) => (
            <Card
              key={`${card.text}-${index}`}
              ref={index === 0 ? cardRef : null}
              className={s.Card}
              style={{
                zIndex: cards.length - index,
                transform: `scale(${(20 - index) / 20}) translateY(${
                  30 * index
                }px)`,
                opacity: (10 - index) / 10
              }}
            >
              <div className={merge(s.CardText, index !== 0 ? 'hide' : '')}>
                <mark className={'highlight'}>
                  {cards[currentQuestion].text}
                </mark>

                <div className={s.Subtitle}>
                  <Trans id="Questionnaire.quick.instruction" />
                </div>
              </div>

              {index === 0 ? (
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext
                    strategy={verticalListSortingStrategy}
                    items={answerIds}
                  >
                    {answers.map((answer, answerIndex) => (
                      <SortableAnswer
                        key={answerIndex}
                        answer={answer}
                        index={answerIndex}
                        id={answer.text}
                      />
                    ))}
                  </SortableContext>
                </DndContext>
              ) : (
                <div></div>
              )}
            </Card>
          ))}
      </div>

      <MainButton className={s.ConfirmBtn} onClick={confirm}>
        <Trans id="DetailsFeedbackMobile.button.confirm">Conferma</Trans>
      </MainButton>
    </>
  );
}

function SortableAnswer({ answer, id, index }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
      disabled: false
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div
      className={s.CardInput}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className={s.FirstCol}>
        <div className={s.Value}>
          <div>{index + 1}</div>
        </div>
        <div className={s.Text}>{answer.text}</div>
      </div>

      <div>
        <Hamburger />
      </div>
    </div>
  );
}
