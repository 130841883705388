import React from 'react';
import { useHistory, NavLink, Link } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import { Trans } from '@lingui/react';
import { i18n } from '@lingui/core';
import merge from 'classnames';

import useResource from '@common/useResource';
import { formatDate } from '@common/utilities';
import useI18n from '@common/useI18n';

import HalfContainer from '@common/HalfContainer';
import Card from '@common/Card';
import ArrowRightIcon from '@common/Icons/ArrowRight';
import PlusIcon from '@common/Icons/Plus';

import s from './TrainingsList.module.css';

export default function TrainingsList({ type }) {
  const history = useHistory();
  const { i18n: lingui } = useI18n();

  const {
    data: trainings,
    // error: trainingError,
    // mutate: mutateDailyGoals,
    isValidating: isLoadingTraining
  } = useResource(`/users/me/sessions${type === 'COMPLETED' ? '' : '/active'}`);

  React.useEffect(() => {
    if (type && type !== 'COMPLETED') {
      history.push('/trainings');
    }
  }, []);

  const isEmptyList = trainings && trainings?.length < 1;

  return (
    <HalfContainer className={s.TrainingsContainer} height="100%">
      <div className={s.TrainingsTopPanel}>
        <h1>
          <Trans id="Trainings.header" />
        </h1>

        <ul className={s.Nav}>
          <li>
            <NavLink
              exact
              to={`/trainings`}
              activeClassName={s.Active}
              className={merge(s.Link, 'font-size-medium')}
            >
              <Trans id="Trainings.activated" />
            </NavLink>
          </li>

          <li>
            <NavLink
              to={`/trainings/completed`}
              activeClassName={s.Active}
              className={merge(s.Link, 'font-size-medium')}
            >
              <Trans id="Trainings.finished" />
            </NavLink>
          </li>
        </ul>

        {type === 'ACTIVE' && (
          <>
            <div
              className={s.CreateNewInfo}
              style={{
                display: isEmptyList ? 'block' : 'none'
              }}
            >
              <Trans id="Trainings.create.extended" />
            </div>

            <div
              role="button"
              className={merge(s.AddTraining, isEmptyList && s.NoTrainings)}
              onClick={() => history.push('/t/new/clusters')}
            >
              <PlusIcon className={s.IconPlus} />{' '}
              <Trans id="Trainings.create" />
            </div>
          </>
        )}
      </div>

      <div className={s.CardContainer}>
        {!trainings && isLoadingTraining && (
          <>
            {[0, 1].map(i => (
              <Card key={i} className={merge(s.Card, s.CardSkeleton)}>
                <SkeletonCard />
              </Card>
            ))}
          </>
        )}

        {trainings?.map(t => {
          const startDate = t.created_at
            ? formatDate(t.created_at, 'PPP', lingui.locale)
            : 'N/A';
          const endDate = t.end_at
            ? formatDate(t.end_at, 'PPP', lingui.locale)
            : 'N/A';

          return (
            <Link className={s.TrainingLink} to={`/trainings/details/${t.id}`}>
              <Card
                key={t.id}
                className={s.Card}
                style={{ '--cluster-color': t.cluster.color }}
              >
                <div className={s.CardTitle}>
                  <span>{t.name}</span>

                  <ArrowRightIcon className={s.IconArrowRight} />
                </div>

                <div className={s.CardBody}>
                  <div className={s.TrainingType}>
                    <div className="text-capitalize">
                      {t?.cluster.name || 'N/A'}
                    </div>
                    <span> | </span>
                    {t.skill?.name}
                  </div>
                  <div className={s.TrainingDuration}>
                    {startDate} - {endDate}
                  </div>
                </div>
              </Card>
            </Link>
          );
        })}
      </div>
    </HalfContainer>
  );
}

const SkeletonCard = props => {
  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={150}
      // viewBox="0 0 300 115"
      backgroundColor="#f3f3f3"
      foregroundColor="#dedede"
      {...props}
    >
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="40" />
      <rect x="0" y="80" rx="3" ry="3" width="100%" height="25" />
      <rect x="0" y="120" rx="3" ry="3" width="75%" height="18" />
    </ContentLoader>
  );
};
