import React from 'react';

function Minor(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={7.102}
      height={13.15}
      viewBox="0 0 7.102 13.15"
      {...props}
    >
      <g data-name="next (11)">
        <g data-name="Group 814">
          <path
            data-name="Path 315"
            d="M.15 6.936l6.082 6.065a.51.51 0 00.72-.722l-5.72-5.7 5.72-5.7a.51.51 0 00-.72-.722L.15 6.222a.509.509 0 000 .722z"
            fill="currentColor"
            // fill="#363636"
          />
        </g>
      </g>
    </svg>
  );
}

export default Minor;
