import React from 'react';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/react';
import { Button } from 'reakit';

import Card from '@common/Card';
import MainButton from '@common/MainButton';
import Spinner from '@common/Spinner';
import PlusIcon from '@common/Icons/Plus';
import EditIcon from '@common/Icons/Edit';

import s from './DareDetailsConfig.module.css';

const dareconfig = [
  { id: 'do', label: 'Do' },
  { id: 'add', label: 'Add' },
  { id: 'reinforce', label: 'Reinforce' },
  { id: 'eliminate', label: 'Eliminate' }
];

export default function DareDetailsConfig(props) {
  const {
    isCompleted,
    configuration,
    onConfigChange,
    onConfigCounterUpdate,
    onSave,
    isProcessing,
    onDeleteRow
  } = props;

  const inputRef = React.useRef(null);

  const [addNew, setAddNew] = React.useState(null);
  const [editArea, setEditArea] = React.useState(null);

  React.useEffect(() => {
    if (!inputRef.current) return;

    if (addNew) {
      inputRef.current.value = '';
    }

    inputRef.current.focus();
  }, [addNew, editArea]);

  React.useEffect(() => {
    if (editArea) {
      setEditArea(null);
      return;
    }

    if (!inputRef.current) return;

    setAddNew(null);
  }, [configuration]);

  function addClickHandler(id) {
    if (editArea) {
      setEditArea(null);
    }

    setAddNew(id);
  }

  function editClickHandler(dconf, idx) {
    if (addNew) {
      setAddNew(null);
    }

    setEditArea({ id: dconf, index: idx });
  }

  function onDelete(idx, confId) {
    onDeleteRow(idx, confId);
  }

  return (
    <Card className={s.DareDetailsCard}>
      {!isCompleted && (
        <div className={s.ButtonWrapper}>
          <MainButton className={s.BtnSaveConfig} onClick={onSave}>
            {isProcessing ? (
              <Spinner />
            ) : (
              <Trans id="TrainingDetails.Button.save_configuration" />
            )}
          </MainButton>
        </div>
      )}

      {dareconfig.map(confKey => (
        <div key={`${confKey.id}`} className={s.ConfigurationContainer}>
          <div className={s.ConfigurationHeader}>
            <div className={s.ConfigTitle}>{confKey.label}</div>

            {!isCompleted && (
              <div
                className={s.PlusIconWrapper}
                role="button"
                onClick={() => addClickHandler(confKey.id)}
              >
                <PlusIcon />
              </div>
            )}
          </div>

          <div className={s.ListsContainer}>
            {configuration[confKey.id]?.map((conf, idx) => (
              <React.Fragment key={`${conf.name}-${idx}`}>
                {editArea?.id === confKey.id && editArea?.index === idx ? (
                  <input
                    key={`${confKey.id}-${conf.name}-${idx}`}
                    ref={inputRef}
                    type="text"
                    className={s.EditInput}
                    defaultValue={conf.name}
                    onKeyPress={ev =>
                      onConfigChange({
                        ev,
                        mode: 'update',
                        confKey: confKey.id,
                        editIdx: editArea.index
                      })
                    }
                    onBlur={ev =>
                      onConfigChange({
                        ev,
                        mode: 'update',
                        confKey: confKey.id,
                        editIdx: editArea.index
                      })
                    }
                  />
                ) : (
                  <div className={s.ListItemContainer}>
                    <div className={s.ListItem}>
                      <div className={s.CounterWrapper}>
                        {isCompleted ? (
                          <div className={s.BtnCounter}>{conf.counter}</div>
                        ) : (
                          <button
                            className={s.BtnCounter}
                            onClick={() =>
                              onConfigCounterUpdate(confKey.id, idx)
                            }
                          >
                            {conf.counter}
                          </button>
                        )}
                        {conf.name}
                      </div>

                      {!isCompleted && (
                        <EditIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => editClickHandler(confKey.id, idx)}
                        />
                      )}
                    </div>

                    {!isCompleted && (
                      <div className={s.DeleteBtn}>
                        <Button onClick={() => onDelete(idx, confKey.id)}>
                          <Trans id="TrainingDetails.Alert.Delete.Button.confirm">
                            Elimina
                          </Trans>
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </React.Fragment>
            ))}

            {addNew === confKey.id && (
              <input
                ref={inputRef}
                type="text"
                placeholder={i18n._('Trainings.DARE.new')}
                onKeyPress={ev =>
                  onConfigChange({ ev, mode: 'add', confKey: confKey.id })
                }
              />
            )}
          </div>
        </div>
      ))}
    </Card>
  );
}
