import * as React from 'react';
import merge from 'classnames';

import s from './Card.module.css';

const Card = React.forwardRef(({ className, children, ...props }, ref) => {
  return (
    <div {...props} ref={ref} className={merge(s.Card, className)}>
      {children}
    </div>
  );
});

export default Card;
