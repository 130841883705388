import * as React from 'react';
import merge from 'classnames';

import s from './Dots.module.css';

export default function Dots({ className, length, activeIndex }) {
  return (
    <div className={merge(s.Dots, className)}>
      {new Array(length).fill(null).map((_, index) => (
        <div
          key={index}
          className={merge(s.Dot, activeIndex == index ? s.Active : '')}
        />
      ))}
    </div>
  );
}
