import * as React from 'react';
import { useDialogState, DialogBackdrop, Dialog } from 'reakit';
import merge from 'classnames';

import styles from './Modal.module.css';

export default function Modal({
  isOpen,
  children,
  backdropClassName,
  className,
  hideOnClickOutside = true,
  onClose,
  ...props
}) {
  const dialog = useDialogState({ visible: isOpen });

  React.useEffect(() => {
    isOpen ? dialog.show() : dialog.hide();
  }, [isOpen]);

  React.useEffect(() => {
    if (!dialog.visible) {
      if (onClose && typeof onClose === 'function') onClose();
    }
  }, [dialog.visible]);

  return (
    <DialogBackdrop
      {...dialog}
      className={merge(styles.Backdrop, backdropClassName)}
    >
      <Dialog
        {...dialog}
        className={merge(styles.Modal, className)}
        hideOnClickOutside={hideOnClickOutside}
        {...props}
        aria-label="Modal"
      >
        {children}
      </Dialog>
    </DialogBackdrop>
  );
}
