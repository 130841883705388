import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/react';

import useResource from '@common/useResource';
import useAsync from '@common/useAsync';
import { getAxios, emailRegex } from '@common/utilities';

import MainButton from '@common/MainButton';
import Spinner from '@common/Spinner';
import ArrowRightIcon from '@common/Icons/ArrowRight';

import merge from 'classnames';
import s from './TrainingCompleteFeedback.module.css';

export default function TrainingCompleteFeedback({ trainingId, onNextStep }) {
  const feedbackUrl = trainingId ? `/trainings/${trainingId}/feedbacks` : null;
  const { data: feedbacks } = useResource(feedbackUrl);

  const { run: runSearchCoach, isLoading: isSearchingCoach } = useAsync();
  const { run: runSaveFeedbackRequest, isLoading: isSavingFeedbackRequest } =
    useAsync();

  const [sendFeedbackList, setSendFeedbackList] = React.useState([]);

  const {
    handleSubmit,
    register,
    formState: { isValid: isValidForm },
    reset: resetForm
  } = useForm({ mode: 'onChange' });

  function searchCoach(formData) {
    const axios = getAxios();

    runSearchCoach(
      axios
        .post('/trainings/feedbacks/coach/search', { email: formData.email })
        .then(resp => {
          if (resp.status !== 200) throw new Error();

          const alreadySent = checkAlreadySent(resp.data);

          if (alreadySent) {
            toast.info('Feedback already sent to this user');
            return;
          }

          setFeedbackRequestToState(formData, resp.data);
        })
        .catch(err => {
          console.log(err);
          toast.error(
            /*i18n*/ i18n._('DetailsFeedback.response.email_not_enabled')
          );
        })
    );
  }

  function checkAlreadySent(userArr) {
    let rest;

    if (userArr.length > 0) {
      rest = feedbacks?.find(fb => fb.user.id === userArr[0]?.id);
    }

    return rest ? true : false;
  }

  function setFeedbackRequestToState(formData, coach) {
    let data = { message: formData.message };

    if (coach.length > 0) {
      data.type = 'user';
      data.coach = coach[0].id;
      data.coachName = coach[0].name;
    } else {
      data.type = 'email';
      data.email = formData.email;
    }

    resetForm();
    setSendFeedbackList(list => [...list, data]);
  }

  function removeFromSendFeedbackList(index) {
    setSendFeedbackList(list => list.filter((l, idx) => idx !== index));
  }

  function saveRequestFeedback() {
    const axios = getAxios();

    let promises = [];
    let payload = { training: trainingId };

    sendFeedbackList.forEach(item => {
      if (item.type === 'email') {
        payload = { ...payload, ...item };
      } else {
        payload = {
          ...payload,
          type: item.type,
          coach: item.coach,
          message: item.message
        };
      }

      promises.push(axios.post('/trainings/feedbacks/coach', payload));
    });

    runSaveFeedbackRequest(
      Promise.all(promises)
        .then(() => {
          onNextStep();
        })
        .catch(err => {
          toast.error(
            /*i18n*/ i18n._('DetailsFeedback.response.feedback_sent_error')
          );
        })
    );
  }

  return (
    <>
      <div className={s.RequestFeedbackWrapper}>
        <div className={s.Header}>
          <div className={s.Title}>
            <Trans id="TrainingCompleteFeedback.request_feedback_title" />
          </div>

          <div className={s.subtitle}>
            <Trans id="TrainingCompleteFeedback.request_feedback_subtitle" />
          </div>
        </div>

        <div className={s.RequestFormContainer}>
          <div className={s.FormTitle}>
            <Trans id="TrainingCompleteFeedback.form.title" />
          </div>

          <form className={s.RequestFeedbackForm}>
            <input
              type="text"
              name="name"
              id="trainingName"
              className={merge(s.Input, s.EmailInput)}
              placeholder="Email"
              {...register('email', { required: true, pattern: emailRegex })}
            />

            <hr className={s.Divider} />

            <textarea
              id="trainingGoal"
              name="goal"
              rows="4"
              className={s.TextArea}
              placeholder={
                /*i18n*/ `${i18n._(
                  'DetailsFeedback.form.comment.placeholder'
                )}...`
              }
              {...register('message')}
            ></textarea>

            <MainButton
              type="submit"
              className={s.BtnSubmit}
              disabled={!isValidForm}
              onClick={handleSubmit(searchCoach)}
            >
              {isSearchingCoach ? (
                <Spinner />
              ) : (
                <>
                  <Trans id="DetailsFeedback.form.submit" />

                  <ArrowRightIcon
                    style={{ marginLeft: '1rem' }}
                    className={!isValidForm ? s.DisabledIconBtnSubmit : ''}
                  />
                </>
              )}
            </MainButton>
          </form>
        </div>
      </div>

      {feedbacks?.length > 0 && <AlreadySentMessage feedbacks={feedbacks} />}

      <SendFeedbackList
        list={sendFeedbackList}
        onRemove={removeFromSendFeedbackList}
      />

      <div className={s.ButtonContainer}>
        {sendFeedbackList.length < 1 ? (
          <button className={s.SkipButton} onClick={onNextStep}>
            <Trans id="TrainingCompleteFeedback.button.skip" />
          </button>
        ) : (
          <MainButton
            className={s.ContinueButton}
            onClick={saveRequestFeedback}
          >
            {isSavingFeedbackRequest ? (
              <Spinner />
            ) : (
              <Trans id="TrainingCompleteFeedback.button.continue" />
            )}
          </MainButton>
        )}
      </div>
    </>
  );
}

function SendFeedbackList({ list, onRemove }) {
  return (
    <>
      {list.length < 1 ? null : (
        <div className={s.SendFeedbackList}>
          {list.map((list, idx) => (
            <div
              key={list.type === 'user' ? list.coach : list.email}
              className={s.ItemList}
            >
              {list.type === 'user' ? list.coachName : list.email}

              <div
                role="button"
                style={{ cursor: 'pointer' }}
                onClick={() => onRemove(idx)}
              >
                <Trans id="TrainingCompleteFeedback.button.remove" />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

function AlreadySentMessage({ feedbacks }) {
  const alreadyUsers = feedbacks?.map(fb => fb.user.name);
  let usersNameInMsg = '';

  alreadyUsers.forEach((au, idx) => {
    usersNameInMsg = alreadyUsers[idx + 2]
      ? (usersNameInMsg = `${usersNameInMsg} <span>${au}</span>,`)
      : alreadyUsers[idx + 1]
      ? (usersNameInMsg = `${usersNameInMsg} <span>${au}</span> ${
          /*i18n*/ i18n._('Feedback.AlreadySentRequest.and')
        }`)
      : (usersNameInMsg = `${usersNameInMsg} <span>${au}</span>`);
  });

  const htmlString = /*i18n*/ i18n._('Feedback.AlreadySentRequest.text', {
    names: usersNameInMsg
  });

  return (
    <div className={s.AlreadySentContainer}>
      <div
        className={s.Message}
        dangerouslySetInnerHTML={{
          __html: htmlString
        }}
      ></div>
    </div>
  );
}
