import * as React from 'react';

import Card from '@common/Card';
import Dots from '@common/Dots';
import MainButton from '@common/MainButton';
import { Trans } from '@lingui/react';

import s from './StartQuestionnaire.module.css';

export default function StartQuestionnaire({ onClick }) {
  return (
    <div>
      <div className={s.Title}>
        <Trans id="Questionnaire.Start.goodjob" />
      </div>

      <div className={s.CardContainer}>
        <Card className={s.Card}>
          <div>
            <Trans id="Questionnaire.Start.gatherinfo" />
          </div>

          <h1>
            <mark className="highlight">
              <Trans
                id="Questionnaire.Start.position"
                components={{ 0: <br /> }}
              />
            </mark>
          </h1>

          <h2>
            <Trans
              id="Questionnaire.Start.answer"
              components={{ 0: <br />, 1: <br /> }}
            />
          </h2>
        </Card>
      </div>

      <div className={s.DotsContainer}>
        <Dots length={3} activeIndex={2} />
      </div>

      <div className={s.ButtonContainer}>
        <MainButton onClick={onClick}>
          <Trans id="Questionnaire.Start.continue" />
        </MainButton>
      </div>
    </div>
  );
}
