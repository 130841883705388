import * as React from 'react';
import { useHistory } from 'react-router';
import { Trans } from '@lingui/react';

import Card from '@common/Card';
import MainButton from '@common/MainButton';
import ArrowRight from '@common/Icons/ArrowRight';
import ClusterCard from '../ClusterCard';

import s from './Approfondimento.module.css';

export default function Approfondimento({ clusters, skills }) {
  const history = useHistory();

  function goToTrainings() {
    history.push('/trainings');
  }

  return (
    <div className={s.Approfondimento}>
      <div className={s.Expl}>
        <Trans id="Styles.cluster">
          Come ti posizioneresti rispetto a questi cluster? Muovi i cursori per
          valutarti autonomamente.
        </Trans>
      </div>

      <div className={s.ClustersList}>
        <ul>
          {clusters.map(cluster => (
            <li key={cluster.id}>
              <ClusterCard cluster={cluster} skills={skills} />
            </li>
          ))}
        </ul>
      </div>

      <Card className={s.Prompt}>
        <div>
          <Trans id="Styles.results">
            In base ai tuoi risultati, identifica le priorità e scegli su cosa
            allenarti.
          </Trans>
        </div>
        <div className={s.BtnContainer}>
          <MainButton className={s.ArrowBtn} onClick={goToTrainings}>
            <div>
              <Trans id="Styles.train">Allenati</Trans>
            </div>
            <ArrowRight />
          </MainButton>
        </div>
      </Card>
    </div>
  );
}
