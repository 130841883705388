import React from 'react';
import { Trans } from '@lingui/react';

import s from './BackPlanningModal.module.css';

export default function BackPlanningModal({ examples }) {
  return (
    <div className={s.BackPlanningModalContainer}>
      <p>
        <Trans id="BackPlanningModal.intro" components={{ 0: <strong /> }} />
      </p>

      {examples?.map((eg, idx) => (
        <div key={idx} className={s.ConfigurationContainer}>
          <div className={s.ExampleLabel}>
            <Trans id="ExampleModal.Example.label">Esempio</Trans> {idx + 1}
          </div>

          <div className={s.GoalWrapper}>
            <div className={s.GoalLabel}>
              <Trans id="ExampleModal.Label.goal" />
            </div>

            <div className={s.Goal}>{eg.goal}</div>
          </div>

          <section className={s.ConfigSection}>
            <div className={s.GoalLabel}>
              <Trans id="ExampleModal.Label.action" />
            </div>

            {eg.configuration?.map(config => (
              <div key={config.name} className={s.ConfigCard}>
                {config.name}
              </div>
            ))}
          </section>
        </div>
      ))}
    </div>
  );
}
