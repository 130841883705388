import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reakit';
import ContentLoader from 'react-content-loader';
import merge from 'classnames';
import { Trans, useLingui } from '@lingui/react';
import { i18n } from '@lingui/core';

import useAuth from '../../auth/useAuth';
import useResource from '@common/useResource';
import useNotifications from '@common/useNotifications';
import { messages } from '../../locales/en/messages';
import { messages as itMessages } from '../../locales/it/messages';
import { messages as frMessages } from '../../locales/fr/messages';
import { getAxios } from '@common/utilities';

import HalfContainer from '@common/HalfContainer';
import Card from '@common/Card';
import ArrowRightIcon from '@common/Icons/ArrowRight';
import TrainingIcon from '@common/Icons/Training';
import MessageIcon from '@common/Icons/Message';
import CautionIcon from '@common/Icons/Caution';
import QuestionCircleIcon from '@common/Icons/QuestionCircle';
import NotificationIcon from '@common/Icons/NotificationIcon';
import NotificationModal from '@dashboard/NotificationModal';
import Modal from '@common/Modal';
import Spinner from '@common/Spinner';
import Globe from '@common/Icons/Globe';
import CloseIcon from '@common/Icons/CloseIcon';
import Stack from '@common/Stack';

import s from './Home.module.css';

export default function Home() {
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] =
    React.useState(false);
  const [isLangModalOpen, setIsLangModalOpen] = React.useState(false);
  const [isChangingLang, setIsChangingLang] = React.useState(false);

  const { user } = useAuth();
  const { i18n: lingui } = useLingui();

  const history = useHistory();

  const { data: languages } = useResource('/languages');
  const { data: trainings, isValidating: isLoadingTraining } = useResource(
    '/users/me/sessions/active'
  );
  const {
    loadMore,
    allNotifications,
    unreadNotificationsCount,
    setRead,
    isValidating,
    hasMore
  } = useNotifications();

  const goToHandler = to => {
    if (!to) return;

    history.push(to);
  };

  function changeLang(langName) {
    setIsChangingLang(true);

    const axios = getAxios();
    axios.patch('/users/me/language', { language: langName }).then(response => {
      if (response.status === 200 || response.status === 201) {
        setIsChangingLang(false);

        const [langFirstByte] = langName.split('-');
        const newMessages =
          langFirstByte === 'en'
            ? messages
            : langFirstByte === 'it'
            ? itMessages
            : langFirstByte === 'fr'
            ? frMessages
            : messages;

        lingui.load(langFirstByte, newMessages);
        lingui.activate(langFirstByte, newMessages);

        setIsLangModalOpen(false);
      }
    });
  }

  const newFeedbackNotification = allNotifications?.filter(
    ({ metadata, read }) =>
      (metadata.type.includes('feedback.request') ||
        metadata.type.includes('chat')) &&
      !read
  ).length;

  return (
    <>
      <HalfContainer className={s.HomeContainer} height="100%">
        <Stack
          className={s.ControlsContainer}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack className={s.Globe} alignItems="center">
            <Button onClick={() => setIsLangModalOpen(true)}>
              <Globe />
            </Button>
            <span>{lingui.locale.toUpperCase()}</span>
          </Stack>

          <NotificationButton
            newNotificationNum={unreadNotificationsCount}
            onClick={() => setIsNotificationMenuOpen(true)}
          />
        </Stack>

        <div className={s.TitleContainer}>
          <h1>
            <Trans id="Home.hello" values={{ name: user.firstName }} />
          </h1>
          <h3>
            <Trans id="Home.today" />
          </h3>
        </div>

        <div className={s.CardContainer}>
          {!trainings && isLoadingTraining && (
            <Card className={merge(s.Card, s.CardSkeleton)}>
              <SkeletonCard />
            </Card>
          )}
          {trainings?.map(t => (
            <Card key={t.id} className={s.Card}>
              <div className={s.CardContent}>
                <div className={merge(s.CardTitle, s.WithIcon)}>
                  <TrainingIcon className={s.IconCardTitle} />
                  <div>
                    <Trans id="Home.training" />
                  </div>
                </div>

                <div className={merge('highlight', s.Highlight)}>{t.name}</div>
              </div>

              <div
                className={s.CardFooter}
                role="button"
                onClick={() => goToHandler(`/trainings/details/${t.id}`)}
              >
                <Trans id="Home.training.resume" />{' '}
                <ArrowRightIcon className={s.IconArrowRight} />
              </div>
            </Card>
          ))}

          {!allNotifications && isValidating && (
            <Card className={merge(s.Card, s.CardSkeleton)}>
              <SkeletonCard />
            </Card>
          )}

          <Card className={merge(s.Card, s.CardNotification)}>
            <div className={s.CardContent}>
              <div className={s.CardTitle}>
                <MessageIcon className={s.IconCardTitle} />
                <div>
                  {allNotifications?.count > 0 ? (
                    <Trans id="Home.feedback.continue" />
                  ) : (
                    <Trans id="Home.feedback.request" />
                  )}
                </div>
              </div>

              {newFeedbackNotification < 1 ? (
                <div className={s.CardBodyMessage}>
                  <Trans id="Home.feedback.empty" />
                </div>
              ) : (
                <div className={merge('highlight', s.Highlight)}>
                  <CautionIcon className={s.IconCaution} />
                  <Trans id="Home.feedback.received" />

                  {newFeedbackNotification < 2
                    ? /*i18n*/ i18n._('Home.feedback.received.one')
                    : /*i18n*/ i18n._('Home.feedback.received.more', {
                        count: newFeedbackNotification
                      })}
                </div>
              )}
            </div>

            <div
              className={s.CardFooter}
              role="button"
              onClick={() => goToHandler('/feedbacks')}
            >
              <Trans id="Home.feedback" />{' '}
              <ArrowRightIcon className={s.IconArrowRight} />
            </div>
          </Card>

          <Card className={merge(s.Card, s.CardFaq)}>
            <div className={merge(s.CardTitle, s.FaqTitle)}>
              <Trans id="Home.faq" />
            </div>

            <div
              className={merge(s.CardFooter, s.FaqFooter)}
              role="button"
              onClick={() => goToHandler('/faq')}
            >
              <div className={s.Text}>
                <QuestionCircleIcon className={s.IconFaq} />{' '}
                <Trans id="Home.faq.footer" />
              </div>
              <ArrowRightIcon className={s.IconArrowRight} />
            </div>

            <div className={s.MailFooter}>
              <Trans
                id="Home.faq.mail"
                components={[
                  <a href="mailto:eni.myfocus@coreconsulting.it"></a>
                ]}
              >
                Oppure scrivici a{' '}
                <a href="mailto:eni.myfocus@coreconsulting.it">
                  eni.myfocus@coreconsulting.it
                </a>
              </Trans>
            </div>
          </Card>
        </div>
      </HalfContainer>

      <NotificationModal
        isOpen={isNotificationMenuOpen}
        onClose={() => setIsNotificationMenuOpen(false)}
        className={s.Notification}
        backdropClassName={s.Backdrop}
        notifications={allNotifications}
        loadMore={loadMore}
        isRevalidating={isValidating}
        hasMore={hasMore}
        onClick={setRead}
      />

      {isLangModalOpen && (
        <Modal
          isOpen={isLangModalOpen}
          onClose={() => setIsLangModalOpen(false)}
          hideOnClickOutside={false}
          className={s.LangModal}
          backdropClassName={s.LangBackdrop}
        >
          <div className={s.LangHeader}>
            <Stack alignItens="center">
              <Globe />
              <span>{lingui.locale.toUpperCase()}</span>
            </Stack>
            <Button
              className={s.LangClose}
              onClick={() => setIsLangModalOpen(false)}
            >
              <CloseIcon />
            </Button>
          </div>
          <div className={s.LangList}>
            {isChangingLang ? (
              <Spinner />
            ) : (
              languages?.map(lang => (
                <Button
                  className={s.LangButton}
                  onClick={() => changeLang(lang.name)}
                >
                  {lang.description}
                </Button>
              ))
            )}
          </div>
        </Modal>
      )}
    </>
  );
}

const SkeletonCard = props => {
  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={150}
      // viewBox="0 0 300 115"
      backgroundColor="#f3f3f3"
      foregroundColor="#dedede"
      {...props}
    >
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="25" />
      <rect x="0" y="50" rx="3" ry="3" width="100%" height="40" />
      <rect x="0" y="130" rx="3" ry="3" width="75%" height="25" />
    </ContentLoader>
  );
};

function NotificationButton({ newNotificationNum, onClick }) {
  return (
    <Button className={s.NotificationButton} onClick={onClick}>
      <NotificationIcon />
      {newNotificationNum > 0 && (
        <div className={s.NotNum}>{newNotificationNum}</div>
      )}
    </Button>
  );
}
