export const EVENTS = {
  VIDEO_INTRO: 'video-intro',
  SEARCH_KEYWORD: 'search-keyword',
  SEARCH_MARKER: 'search-marker',
  SEARCH_TACTIC: 'search-tactic',
  REVIEW_TACTIC: 'review-tactic'
};

export const TRAINING_TYPE = {
  DARE: 'dare',
  BACK_PLANNING: 'back-planning'
};

const config = {
  apiVersion: '/api/v1',
  notificationItems: 20,
  notificationPollingInterval: 10000,
  chatPollingInterval: 120000,
  appURL: import.meta.env.VITE_APP_URL,
  ssoURL: import.meta.env.VITE_SSO_LOGIN_URL,
  goalFeedbackModalStates: {
    FEEDBCAK: 'FEEDBACK',
    RESTART: 'RESTART'
  },
  events: [
    EVENTS.VIDEO_INTRO,
    EVENTS.SEARCH_KEYWORD,
    EVENTS.SEARCH_MARKER,
    EVENTS.SEARCH_TACTIC,
    EVENTS.REVIEW_TACTIC
  ],
  eventsDescriptions: {
    [EVENTS.SEARCH_KEYWORD]:
      'Su cosa vuoi migliorarti? Scrivi una o più parole chiave nella barra di ricerca e scegli su cosa allenarti! Un esempio: “Comunicazione riunione”',
    [EVENTS.SEARCH_MARKER]:
      'In questa pagina troverai tutti gli indicatori legati alla tua ricerca. Seleziona un indicatore per scoprire quali microtattiche sono disponibili. Ricorda: potrai sempre cambiare keyword di ricerca tornando indietro.',
    [EVENTS.SEARCH_TACTIC]:
      'Scopri tutti gli allenamenti suddivisi per situazioni. Clicca sui vari allenamenti per leggere l’anteprima. Puoi anche filtrare i risultati per situazione.',
    [EVENTS.REVIEW_TACTIC]:
      'Ci siamo quasi! Imposta la durata dell’allenamento. Se vuoi puoi aggiungere i tuoi colleghi per richiedere un feedback e inserire uno o più momenti in cui potrai metterti alla prova.'
  },
  stepsNames: {
    GROUPS: 'groups',
    CHANGE_PERSONAL_DATA: 'change-personal-data',
    PROFILING: 'profiling'
  }
};

export default config;
