import React from 'react';
import { Trans } from '@lingui/react';
import { i18n } from '@lingui/core';
import { Link, useHistory, useParams } from 'react-router-dom';
import { arrayMove } from '@dnd-kit/sortable';
import { toast } from 'react-toastify';

import useResource from '@common/useResource';
import useSkills from '@common/useSkills';
import { formatDate, getAxios } from '@common/utilities';
import useI18n from '@common/useI18n';
import useAsync from '@common/useAsync';

import TrainingLayout from '@new-training/TrainingLayout';
import TrainingProgress from '@new-training/TrainingProgress';
import SelectedBehaviorsBar from '@new-training/SelectedBehaviorsBar';
import Card from '@common/Card';
import MainButton from '@common/MainButton';
import ArrowRightIcon from '@common/Icons/ArrowRight';
import CalendarIcon from '@common/Icons/Calendar';
import EditIcon from '@common/Icons/Edit';
import BackPlanningConfig from './BackPlanningConfig';
import GetInspierdConfig from './GetInspierdConfig';
import DareConfig from './DareConfig';
import TrainingDatesModal from '@new-training/TrainingDatesModal';
import Spinner from '@common/Spinner';

import s from './TrainingConfiguration.module.css';

export default function TrainingConfiguration(props) {
  const { clusters, info, savedBehaviors } = props;

  const history = useHistory();
  const { clusterId, skillId, methodType } = useParams();
  const { i18n: lingui } = useI18n();

  const initialConfig =
    methodType === 'back-planning'
      ? []
      : methodType === 'dare'
      ? {
          do: [],
          add: [],
          reinforce: [],
          eliminate: []
        }
      : { description: info.description };

  const [configuration, setConfiguration] = React.useState(initialConfig);
  const [openDurationModal, setOpenDurationModal] = React.useState(false);
  const [selectedDates, setSelectedDates] = React.useState([new Date()]);
  // const [selectedDates, setSelectedDates] = React.useState(null);

  const { data: behaviors } = useResource(`/skills/${skillId}/behaviors`);
  const { skills } = useSkills({ clusters });
  const createTrainingAsync = useAsync();

  const selectedCluster = clusters?.find(cluster => cluster.id == clusterId);
  // const selectedSkill = skills?.[clusterId]?.find(skill => skill.id == skillId);
  const selectedBehaviors = behaviors?.filter(behavior =>
    savedBehaviors.includes(behavior.id)
  );

  React.useEffect(() => {
    if (!savedBehaviors || savedBehaviors.length < 1) {
      history.push(`/t/new/c/${clusterId}/s/${skillId}/behaviors`);
    }
  }, [savedBehaviors]);

  function updateDareConfigHandler(ev, mode, confId, editIdx) {
    if (!ev.target.value || (ev.key !== 'Enter' && ev.type !== 'blur')) return;

    if (mode === 'add') {
      setConfiguration(config => ({
        ...config,
        [confId]: [...config[confId], { name: ev.target.value, counter: 0 }]
      }));

      return;
    }

    setConfiguration(config => ({
      ...config,
      [confId]: config[confId].map((conf, idx) =>
        idx === editIdx ? { ...conf, name: ev.target.value } : conf
      )
    }));
  }

  function sortingHandler(activeIdx, overIdx) {
    setConfiguration(items => arrayMove(items, activeIdx, overIdx));
  }

  function onDeleteRow(selectedIdx, confId) {
    if (methodType === 'back-planning') {
      setConfiguration(config =>
        config.filter((conf, idx) => idx !== selectedIdx)
      );
      return;
    }

    if (!confId) {
      console.error('cannot delete row for dare, confId not provided');
      return;
    }

    setConfiguration(config => {
      return {
        ...config,
        [confId]: config[confId].filter((conf, idx) => idx !== selectedIdx)
      };
    });
  }

  function updateBackPlanningConfigHandler(ev, mode, editIdx) {
    if (!ev.target.value || (ev.key !== 'Enter' && ev.type !== 'blur')) return;

    if (mode === 'add') {
      setConfiguration(config => [
        ...config,
        { name: ev.target.value, completed: false }
      ]);

      return;
    }

    setConfiguration(config =>
      config.map((conf, idx) =>
        idx === editIdx ? { ...conf, name: ev.target.value } : conf
      )
    );
  }

  function closeDurationModalHandler() {
    setOpenDurationModal(false);
  }

  function confirmDateHandler(newDates) {
    setSelectedDates(newDates);
    closeDurationModalHandler();
  }

  function createTrainingHandler() {
    if (!selectedDates) return;

    const jsonConfig = JSON.stringify(configuration);

    const created_at = formatDate(selectedDates[0], 'Y-MM-dd') || null;
    const end_at = formatDate(selectedDates[1], 'Y-MM-dd') || null;

    const payload = {
      behaviors: savedBehaviors,
      name: info.name,
      goal: info.goal || info.predefinedGoal,
      created_at,
      end_at,
      storage: {
        type: methodType,
        configuration: jsonConfig
      }
    };

    const axios = getAxios();

    createTrainingAsync.run(
      axios
        .post(`/trainings/train/${skillId}`, payload)
        .then(resp => {
          if (resp.status !== 201) throw new Error();

          const newTrainingId = resp?.data?.id;

          newTrainingId
            ? history.push(`/trainings/details/${newTrainingId}`)
            : history.push('/trainings');
        })
        .catch(err => {
          toast.error(/*i18n*/ i18n._('Trainings.Configuration.create.error'));
        })
    );
  }

  let startDate;
  let endDate;

  if (selectedDates?.length > 1) {
    startDate = formatDate(selectedDates[0], 'PPP', lingui.locale);
    endDate = formatDate(selectedDates[1], 'PPP', lingui.locale);
  }

  const { isLoading } = createTrainingAsync;

  return (
    <>
      <TrainingDatesModal
        openModal={openDurationModal}
        closeModal={closeDurationModalHandler}
        onConfirmDate={confirmDateHandler}
        selectedDate={selectedDates}
      />

      <TrainingLayout bgHeight="45%" color={selectedCluster?.color}>
        <div className={s.HeaderWrapper}>
          <div className={s.Header}>
            <Link to={`/t/new/c/${clusterId}/s/${skillId}/m/${methodType}`}>
              <ArrowRightIcon style={{ transform: 'rotate(180deg)' }} />
            </Link>

            <h1 className={s.Label}>{info?.name}</h1>
          </div>
        </div>

        <div className={s.ProgressContainer}>
          <TrainingProgress indexActive={5} />
        </div>

        {selectedBehaviors?.length > 0 && (
          <SelectedBehaviorsBar behaviors={selectedBehaviors} />
        )}

        {methodType != 'get_inspired' && (
          <div className={s.GoalContainer}>
            <div className={s.Subtitle}>
              <Trans id="Trainings.Configuration.goal" />
            </div>

            <Card className={s.GoalCard}>
              {info.goal || info.predefinedGoal}
            </Card>
          </div>
        )}

        {methodType === 'dare' ? (
          <DareConfig
            configuration={configuration}
            onConfigChange={updateDareConfigHandler}
            onDeleteRow={onDeleteRow}
          />
        ) : methodType === 'back-planning' ? (
          <BackPlanningConfig
            configuration={configuration}
            onSort={sortingHandler}
            onConfigChange={updateBackPlanningConfigHandler}
            onDeleteRow={onDeleteRow}
          />
        ) : (
          <GetInspierdConfig configuration={configuration} />
        )}

        <TrainingDuration
          startDate={startDate}
          endDate={endDate}
          onOpenModal={() => setOpenDurationModal(true)}
        />

        <div
          className="centering"
          style={{ marginTop: '2rem', paddingBottom: '3rem' }}
        >
          <MainButton type="submit" onClick={createTrainingHandler}>
            {isLoading ? (
              <Spinner />
            ) : (
              /*i18n*/ i18n._('Trainings.Configuration.start')
            )}
          </MainButton>
        </div>
      </TrainingLayout>
    </>
  );
}

function TrainingDuration(props) {
  const { startDate, endDate, onOpenModal } = props;

  return (
    <div className={s.DurationContainer}>
      <div className={s.Subtitle}>
        <Trans id="Trainings.Configuration.duration" />
      </div>

      <Card role="button" className={s.CardDuration}>
        <div className={s.Duration}>
          <CalendarIcon />
          <span>
            {startDate && endDate
              ? `${startDate} - ${endDate}`
              : /*i18n*/ i18n._('Trainings.Configuration.time')}
          </span>
        </div>

        <div role="button" className={s.BtnDurationModal} onClick={onOpenModal}>
          {startDate && endDate ? (
            <EditIcon />
          ) : (
            /*i18n*/ i18n._('Trainings.Configuration.set')
          )}
        </div>
      </Card>
    </div>
  );
}
