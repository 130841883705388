import React from 'react';

function Caution(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15.37}
      height={14.015}
      viewBox="0 0 15.37 14.015"
      {...props}
    >
      <g data-name={159469}>
        <g data-name="Group 223" fill="#363636" stroke="#000" strokeWidth={0.1}>
          <path
            data-name="Path 290"
            d="M7.684 9.828a.782.782 0 000 1.565.782.782 0 000-1.565z"
          />
          <path
            data-name="Path 291"
            d="M14.925 12.601a2.671 2.671 0 00.006-2.7l-4.9-8.486a2.7 2.7 0 00-4.687 0l-4.906 8.5a2.71 2.71 0 002.346 4.053h9.79a2.7 2.7 0 002.351-1.367zm-1.064-.613a1.479 1.479 0 01-1.292.748H2.781a1.458 1.458 0 01-1.277-.732 1.481 1.481 0 010-1.493l4.903-8.493a1.474 1.474 0 012.56 0l4.9 8.492a1.461 1.461 0 01-.006 1.477z"
          />
          <path
            data-name="Path 292"
            d="M7.491 4.338a.851.851 0 00-.6.854c.019.247.034.5.053.745l.16 2.807a.573.573 0 00.585.551.585.585 0 00.585-.57c0-.194 0-.372.019-.569.034-.6.072-1.208.106-1.812.019-.391.053-.782.072-1.173a.966.966 0 00-.072-.391.784.784 0 00-.908-.442z"
          />
        </g>
      </g>
    </svg>
  );
}

export default Caution;
