import * as React from 'react';
import { useForm } from 'react-hook-form';

import MainButton from '@common/MainButton/MainButton';
import Spinner from '@common/Spinner';

import useAuth from '@auth/useAuth';
import useAsync from '@common/useAsync';
import { getAxios } from '@common/utilities';

function login({ email, password }) {
  const axios = getAxios();

  return axios.post('/auth/classic/login', { email, password });
}

export default function Login() {
  const { handleSubmit, register } = useForm({ mode: 'onChange' });
  const { mutate, user } = useAuth({ fireOnMount: false, redirect: true });

  const loginAsync = useAsync();

  React.useEffect(() => {
    if (loginAsync.isSuccess) {
      mutate();
    }
  }, [loginAsync.isSuccess]);

  function onSubmit(data) {
    const { email, password } = data;

    loginAsync.run(login({ email, password }));
  }

  const { isIdle, isLoading, isError, error, isSuccess } = loginAsync;

  return (
    <div>
      <div>{isError ? error : null}</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="email" {...register('email', { required: true })} />
        <input type="password" {...register('password', { required: true })} />

        <MainButton type="submit">
          {isLoading || (isSuccess && !user) ? <Spinner /> : 'Accedi'}
        </MainButton>
      </form>
    </div>
  );
}
