import React from 'react';

function QuestionCircle(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26.935}
      height={26.935}
      viewBox="0 0 26.935 26.935"
      {...props}
    >
      <g data-name={906794}>
        <g data-name="Group 228">
          <g
            data-name="Group 227"
            fill="#626262"
            stroke="#fff"
            strokeWidth={0.5}
            transform="translate(.25 .25)"
          >
            <circle
              data-name="Ellipse 80"
              cx={1.291}
              cy={1.291}
              r={1.291}
              transform="translate(11.927 18.251)"
            />
            <path
              data-name="Path 299"
              d="M13.217 0a13.217 13.217 0 1013.218 13.217A13.21 13.21 0 0013.217 0zm0 24.37A11.152 11.152 0 1124.37 13.217 11.146 11.146 0 0113.217 24.37z"
            />
            <path
              data-name="Path 300"
              d="M13.217 6.634a4.135 4.135 0 00-4.13 4.13 1.033 1.033 0 002.065 0 2.065 2.065 0 112.065 2.065 1.033 1.033 0 00-1.033 1.033v2.582a1.033 1.033 0 002.065 0v-1.68a4.131 4.131 0 00-1.033-8.13z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default QuestionCircle;
