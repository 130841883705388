import * as React from 'react';
import { Button } from 'reakit';
import { Link, useHistory } from 'react-router-dom';
import { Trans } from '@lingui/react';
import merge from 'classnames';

import MainButton from '@common/MainButton';
import Card from '@common/Card';
import InfoIcon from '@common/Icons/InfoIcon';
import RadarChart from '../RadarChart';
import SlidersCard from '../SlidersCard';
import ClusterCard from '../ClusterCard';

import s from './StyleSectionDesktop.module.css';

export default function StyleSectionDesktop({
  recharts,
  clusterList,
  skills,
  openModal
}) {
  const history = useHistory();

  function goToTrainings() {
    history.push('/trainings');
  }

  const isLinkDisabled = clusterList.some(cluster => cluster.enabled === false);

  return (
    <div className={s.Container}>
      <div className={s.Chart}>
        <div className={s.RadarContainer}>
          <h1>
            <Trans id="Styles.your" />
          </h1>

          <div className={s.Radar}>
            <Button className={s.InfoIcon} onClick={openModal}>
              <InfoIcon />
            </Button>

            <div className={s.ChartContainer}>
              <RadarChart data={clusterList} recharts={recharts} />
            </div>
          </div>

          <div className={s.Prompt}>
            <div>
              <Trans id="Styles.results" />
            </div>
            <div>
              <MainButton onClick={goToTrainings}>
                <Trans id="Styles.train" />
              </MainButton>
            </div>
          </div>
        </div>
      </div>

      <div className={s.RightContainer}>
        <div className={s.Wrapper}>
          <div>
            <div className={s.Title}>
              <Trans id="Styles.overview" />
            </div>
            <div className={s.SubTitle}>
              <Trans id="Styles.cursors" />
            </div>
          </div>

          <div className={s.SliderContainer}>
            <SlidersCard clusters={clusterList} />
            <Card className={s.HelpPrompt}>
              <div className={s.Label}>
                <Trans id="Styles.help" />
              </div>

              {isLinkDisabled ? (
                <div>
                  <Trans id="Styles.cant">
                    Non puoi rifare il quiz perchè hai già iniziato il
                    posizionamento di approfondimento
                  </Trans>
                </div>
              ) : (
                <div>
                  <Trans id="Styles.answers" />
                </div>
              )}

              {isLinkDisabled ? (
                <div className={merge('main-btn-sm', s.Ghost)}>
                  <Trans id="Styles.quiz" />
                </div>
              ) : (
                <Link to="/styles/quiz" className="main-btn-sm">
                  <Trans id="Styles.quiz" />
                </Link>
              )}
            </Card>
          </div>

          <div>
            <div className={s.Title}>
              <Trans id="Styles.deepdive" />
            </div>
            <div className={s.SubTitle}>
              <Trans id="Styles.cluster" />
            </div>

            <ul className={s.Clusters}>
              {clusterList.map(cluster => (
                <li key={cluster.id}>
                  <ClusterCard cluster={cluster} skills={skills} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
