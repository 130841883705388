import * as React from 'react';

export default function Stack({
  children,
  className,
  horizontal = true,
  vertical = false,
  justifyContent,
  alignItems,
  width,
  height,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  margin,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  padding
}) {
  const style = {
    display: 'flex',
    flexDirection: horizontal && !vertical ? 'row' : 'column',
    justifyContent,
    alignItems,
    width,
    height,
    margin,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    padding
  };

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
}
