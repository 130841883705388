import React from 'react';
import { Button } from 'reakit';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { Virtuoso } from 'react-virtuoso';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Trans } from '@lingui/react';
import { i18n } from '@lingui/core';
import { nanoid } from 'nanoid';
import merge from 'classnames';

import config from '@common/config';
import useResource from '@common/useResource';
import { getAxios } from '@common/utilities';
import useMediaQuery from '@common/useMediaQuery';
import useNotifications from '@common/useNotifications';

import TopPanelMobile from '../TopPanelMobile';
import Card from '@common/Card';

import styles from './FeedbackChat.module.css';

export default function FeedbackChat({ feedbacks, type }) {
  const scrollRef = React.useRef(null);
  //USER ID ISN'T THE LOGGEDIN ONE

  const { userId, feedbackId } = useParams();
  const isMobile = useMediaQuery('(max-width: 600px)');

  const { allNotifications, setMultipleReads } = useNotifications({
    fireOnMount: false,
    refreshInterval: 0
  });

  const userToEvaluate = React.useMemo(() => {
    const object = feedbacks.find(item => item.user.id == userId);
    return object.user;
  }, [userId]);

  const chatData = React.useMemo(() => {
    const user = feedbacks.find(item => item.user.id == userId);

    return { user };
  }, [userId, feedbacks]);

  const user = chatData.user.user;

  const {
    data: chat,
    error: fetchErrorChat,
    mutate
  } = useResource(`/trainings/feedbacks/${feedbackId}/chat`, {
    refreshInterval: config.chatPollingInterval
  });

  React.useEffect(() => {
    //THIS IS TO MAKE SCROLL WORK INSIDE OF MODAL
    //FOR IOS AS WELL
    //MORE INFO HERE https://github.com/reakit/reakit/issues/469
    if (scrollRef.current) {
      disableBodyScroll(scrollRef.current);
    }

    return () => enableBodyScroll(scrollRef.current);
  }, []);

  React.useEffect(() => {
    //SET READ NOTIFICATION WHEN ENTERING THE CHAT
    if (allNotifications) {
      const unreadFeedbackChat = allNotifications.filter(
        not =>
          not.metadata.type === 'chat.message' &&
          not.read === false &&
          not.metadata.feedback == feedbackId
      );

      if (unreadFeedbackChat && unreadFeedbackChat.length > 0) {
        setMultipleReads(unreadFeedbackChat.map(not => not.id));
      }
    }
  }, [allNotifications]);

  function sendMessage(message) {
    const axios = getAxios();
    const tempId = nanoid();
    const now = new Date();
    const newMessage = { text: message, created_at: now.toISOString(), tempId };
    const newChat = {
      ...chat,
      messages: [...chat.messages, newMessage]
    };
    //OPTIMISTIC UPDATE
    mutate(newChat, false);

    axios
      .post(`/trainings/feedbacks/${feedbackId}/chat`, { text: message })
      .then(res => console.log(res))
      .catch(err => console.log(err));
  }

  const row = React.useMemo(
    () =>
      (index, { text, user: textUserId }) => {
        const isLast = index !== 0 && index === chat?.messages.length;

        const fromMe = textUserId !== userToEvaluate.id;
        return (
          <div>
            <Bubble
              key={index}
              position={fromMe ? 'RIGHT' : 'LEFT'}
              className={isLast ? styles.LastBubble : null}
            >
              <div className={styles.BubbleName}>
                {fromMe ? 'Tu' : userToEvaluate.name}
              </div>
              <div
                className={styles.BubbleText}
                dangerouslySetInnerHTML={{
                  __html: text?.replace(/\n/g, '<br />')
                }}
              />
            </Bubble>
          </div>
        );
      },
    [userId, chat?.messages]
  );

  const isChatDisabled = chat?.users.some(user => !Boolean(user.canReply));

  return (
    <>
      {isMobile && (
        <TopPanelMobile
          user={user}
          // info={/*i18n*/ i18n._('Feedback.Chat.training')}
        />
      )}

      <Container>
        <TrainingData chat={chat} />

        {chat ? (
          <Virtuoso
            style={{ flex: 1 }}
            initialTopMostItemIndex={chat.messages.length}
            followOutput="smooth"
            itemContent={row}
            data={[{}].concat(chat.messages)}
            scrollerRef={ref => {
              scrollRef.current = ref;
            }}
          />
        ) : null}
      </Container>

      {!isChatDisabled === false ? <ChatInput onSend={sendMessage} /> : null}
    </>
  );
}

function Container({ children }) {
  return <div className={styles.Container}>{children}</div>;
}

function TrainingData({ chat }) {
  return (
    <div
      className={styles.TrainingInfoContainer}
      style={{ '--cluster-color': chat?.cluster.color }}
    >
      <div className={styles.Subtitle}>
        <Trans id="FeedbackChat.Title.your_training" />
      </div>
      <Card className={styles.TrainingInfoCard}>
        <div className={styles.TrainingName}>{chat?.name}</div>
        <p>{chat?.goal}</p>
        <div className={styles.TrainingType}>
          {chat?.cluster.name} <span>|</span> {chat?.skill.name}
        </div>
      </Card>
    </div>
  );
}

function Bubble({ children, className, position }) {
  return (
    <div
      className={merge(
        className,
        styles.BubbleContainer,
        position === 'RIGHT' ? styles.Right : styles.Left
      )}
    >
      <div className={styles.Bubble}>{children}</div>
    </div>
  );
}

function ChatInput({ onSend }) {
  const { register, errors, handleSubmit, setValue } = useForm({
    mode: 'onBlur'
  });

  function handleTextareaEnter(event) {
    if (event.keyCode == 13 && !event.shiftKey) {
      handleSubmit(sendMessage)();
      setValue('message', '');
      event.preventDefault();
    }
  }

  function sendMessage({ message }) {
    onSend(message);
  }

  return (
    <form className={styles.ChatInput} onSubmit={handleSubmit(sendMessage)}>
      <textarea
        name="message"
        onKeyDown={handleTextareaEnter}
        onKeyPress={handleTextareaEnter}
        placeholder={/*i18n*/ i18n._('Feedback.Chat.message')}
        rows="1"
        {...register('message', { required: true })}
      />
      <Button type="submit" className="visually-hidden">
        <Trans id="Feedback.Chat.send" />
      </Button>
    </form>
  );
}
