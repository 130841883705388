import * as React from 'react';

export default function useQuestionnaire() {
  const [questionnaireState, setQuestionnaireState] = React.useState({
    answers: [],
    currentQuestion: 0
  });

  function onSubmit(newState) {
    setQuestionnaireState(newState);
  }

  function restart() {
    setQuestionnaireState({ answers: [], currentQuestion: 0 });
  }

  return { questionnaireState, restart, onSubmit };
}
