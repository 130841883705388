import React from 'react';

import Modal from '@common/Modal';
// import CloseIcon from '@common/Icons/CloseIcon';
import MainButton from '@common/MainButton';
import Spinner from '@common/Spinner';

import merge from 'classnames';
import s from './AlertModal.module.css';

export default function AlertModal(props) {
  const {
    openModal,
    closeModal,
    title,
    message,
    confirmButtonText,
    confirmButtonClass,
    cancelButtonText,
    cancelButtonClass,
    onConfirm,
    onCancel,
    isProcessing = false
  } = props;

  return (
    <Modal
      isOpen={openModal}
      // backdropClassName={s.AlertModalBackdrop}
      onClose={closeModal}
      hideOnClickOutside={false}
      className={s.AlertModal}
    >
      <div className={s.ModalContainer}>
        <div className={s.ModalHeader}>
          <div className={s.Title}>{title}</div>

          {/* <CloseIcon style={{ cursor: 'pointer' }} onClick={closeModal} /> */}
        </div>

        <div className={s.ModalBody}>
          <div>{message}</div>
        </div>
      </div>

      <div className={s.ButtonsWrapper}>
        <MainButton
          className={merge(s.Button, s.BtnConfirm, confirmButtonClass)}
          onClick={onConfirm}
        >
          {isProcessing ? <Spinner strokeColor="#fff" /> : confirmButtonText}
        </MainButton>

        <MainButton
          className={merge(s.Button, s.BtnCancel, cancelButtonClass)}
          onClick={onCancel}
        >
          {cancelButtonText}
        </MainButton>
      </div>
    </Modal>
  );
}
