import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ContentLoader from 'react-content-loader';
import { toast } from 'react-toastify';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/react';

import useResource from '@common/useResource';
import useAsync from '@common/useAsync';
import { getAxios, emailRegex } from '@common/utilities';

import Card from '@common/Card';
import MainButton from '@common/MainButton';
import Spinner from '@common/Spinner';
import ArrowRightIcon from '@common/Icons/ArrowRight';

import merge from 'classnames';
import s from './DetailsFeedback.module.css';

export default function DetailsFeedback({ subSectionTitleClass, trainingId }) {
  const history = useHistory();

  const {
    data: feedbacks,
    // error: feedbacksError,
    mutate: mutateFeedback
  } = useResource(`/trainings/${trainingId}/feedbacks`);

  const { run: runSaveFeedbackRequest, isLoading: isSavingFeedbackRequest } =
    useAsync();

  const {
    handleSubmit,
    register,
    formState: { isValid: isValidForm },
    reset: resetForm
  } = useForm({ mode: 'onChange' });

  function gotToChat(userId, feedBackId) {
    history.push(`/feedbacks/r/${userId}/feedback/${feedBackId}`);
  }

  function searchCoach(data) {
    const axios = getAxios();

    runSaveFeedbackRequest(
      axios
        .post('/trainings/feedbacks/coach/search', { email: data.email })
        .then(resp => {
          if (resp.status !== 200) throw new Error();

          const alreadySent = checkAlreadySent(resp.data);

          if (alreadySent) {
            toast.info(
              /*i18n*/ i18n._('DetailsFeedback.response.already_requested')
            );

            return;
          }

          saveRequestFeedback(data, resp.data);
        })
        .catch(err => {
          const errMsg = err.response.data.email[0];

          if (errMsg === 'email address not enabled') {
            toast.error(
              /*i18n*/ i18n._('DetailsFeedback.response.email_not_enabled')
            );
          } else if (errMsg === 'cannot send request to yourself') {
            toast.error(
              /*i18n*/ i18n._(
                'DetailsFeedback.response.self_feedback_not_permitted'
              )
            );
          } else {
            toast.error(
              /*i18n*/ i18n._('DetailsFeedback.response.something_else_failed')
            );
          }
        })
    );
  }

  function checkAlreadySent(userArr) {
    let rest;

    if (userArr.length > 0) {
      rest = feedbacks?.find(fb => fb.user.id === userArr[0]?.id);
    }

    return rest ? true : false;
  }

  function saveRequestFeedback(data, coaches) {
    const axios = getAxios();

    let coach = coaches?.[0] || null;
    let payload = { training: Number(trainingId), message: data.message };

    if (coach) {
      payload.type = 'user';
      payload.coach = coach.id;
    } else {
      payload.type = 'email';
      payload.email = data.email;
    }

    runSaveFeedbackRequest(
      axios
        .post('/trainings/feedbacks/coach', payload)
        .then(resp => {
          mutateFeedback();
          resetForm();
          toast.success(
            /*i18n*/ i18n._('DetailsFeedback.response.feedback_sent_success')
          );
        })
        .catch(() => {
          toast.error(
            /*i18n*/ i18n._('DetailsFeedback.response.feedback_sent_error')
          );
        })
    );
  }

  return (
    <>
      <div className={s.FeedbackWrapper}>
        <div className={subSectionTitleClass}>Feedback</div>

        {!feedbacks && (
          <Card className={s.UserCard}>
            <SkeletonUserCard />
          </Card>
        )}

        {feedbacks?.length < 1 ? (
          <div className={s.EmptyInfo}>
            <Trans id="DetailsFeedback.empty_feedback" />
          </div>
        ) : (
          <div className={s.FeedbackUserList}>
            {feedbacks?.map(fb => (
              <Card
                key={`${fb.feedback}-${fb.user.id}`}
                className={s.UserCard}
                role="button"
                onClick={() => gotToChat(fb.user.id, fb.feedback)}
              >
                <div className={s.UserName}>{fb.user.name}</div>

                <ArrowRightIcon />
              </Card>
            ))}
          </div>
        )}
      </div>

      <div className={s.RequestFeedbackWrapper}>
        <p>
          <Trans id="DetailsFeedback.add_evaluators" />
        </p>

        <div className={s.Title}>
          <Trans id="DetailsFeedback.request_feedback_title" />
        </div>

        <form className={s.RequestFeedbackForm}>
          <input
            type="text"
            name="name"
            id="trainingName"
            className={merge(s.Input, s.EmailInput)}
            placeholder="Email"
            {...register('email', { required: true, pattern: emailRegex })}
          />

          <hr className={s.Divider} />

          <textarea
            id="trainingGoal"
            name="goal"
            rows="4"
            className={s.TextArea}
            placeholder={
              /*i18n*/ `${i18n._(
                'DetailsFeedback.form.comment.placeholder'
              )}...`
            }
            {...register('message')}
          ></textarea>

          <MainButton
            className={s.BtnSubmit}
            disabled={!isValidForm}
            onClick={handleSubmit(searchCoach)}
          >
            {isSavingFeedbackRequest ? (
              <Spinner />
            ) : (
              <>
                <Trans id="DetailsFeedback.form.submit" />

                <ArrowRightIcon
                  style={{ marginLeft: '1rem' }}
                  className={!isValidForm ? s.DisabledIconBtnSubmit : ''}
                />
              </>
            )}
          </MainButton>
        </form>
      </div>
    </>
  );
}

const SkeletonUserCard = props => (
  <ContentLoader
    width={'100%'}
    height={25}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="100%" height="20" />
  </ContentLoader>
);
